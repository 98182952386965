import React from "react";
import { Stack, Label } from "office-ui-fabric-react";
import { DefaultButton, PrimaryButton } from "office-ui-fabric-react/lib/Button";
import { Dialog, DialogType, DialogFooter } from "office-ui-fabric-react/lib/Dialog";
import { GestionOportunidad, ResumenEconomic } from "../../controles/entidades/GestionOportunidad";
import { TextField } from "office-ui-fabric-react/lib/TextField";
import { IDatePickerStrings, DatePicker, DayOfWeek } from "office-ui-fabric-react/lib/DatePicker";
import { ChoiceGroup } from "office-ui-fabric-react/lib/ChoiceGroup";
import { ComboBox } from "office-ui-fabric-react";
import { Checkbox } from "office-ui-fabric-react/lib/Checkbox";
import { mergeStyleSets } from "office-ui-fabric-react/lib/Styling";
import { Slider } from "office-ui-fabric-react/lib/Slider";
import { Accordion } from "../../controles/Accordion/Accordion";
import { Glyphicon } from "react-bootstrap";
import "./Oportunidad.css";
import { MsalAuthProvider } from "react-aad-msal";
import history from "../../history";
import { fetchEngine } from "../../fetchData";
import axios from "axios";
import { basicReduxStore } from "../../reduxStore";
import { authProvider } from "../../authProvider";
import "../../custom.css";
import DragNDropFile from "../../controles/DragNDrop/DragNDropFile";
import OrdenCompra from "../../controles/OrdenCompra/OrdenCompra";
import Workspace from "../../controles/Workspace/Workspace";
import AutoComplete from "../../controles/AutoComplete/AutoComplete";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import BotonAyuda from "../BotonAyuda/BotonAyuda";
initializeIcons();

interface Props {
    provider: MsalAuthProvider;
}

interface Documentos {
    name: string;
    id: string;
    url: string;
}

const DayPickerStrings: IDatePickerStrings = {
    months: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
    shortMonths: ["En", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
    days: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
    shortDays: ["D", "L", "M", "M", "J", "V", "S"],
    goToToday: "Ir a hoy",
    prevMonthAriaLabel: "Ir al mes previo",
    nextMonthAriaLabel: "Ir al mes siguiente",
    prevYearAriaLabel: "Ir al año anterior",
    nextYearAriaLabel: "Ir al año siguiente",
    closeButtonAriaLabel: "Cerrar el selector de fechas",
};

const controlClass = mergeStyleSets({
    control: {
        margin: "0 0 15px 0",
        maxWidth: "300px",
    },
});

export class OportunidadProps {
    titulo?: string;
}

export class OportunidadState {
    op_info!: GestionOportunidad;
    sim_info!: ResumenEconomic;
    estadodelformulario?: boolean;
    titulo_state?: string;
    fechacierre?: Date;

    esAdmin?: boolean;
    esUsuario?: boolean;
    esGestion?: boolean;
    esValidador?: boolean;
    esContable?: boolean;

    opciones_linea?: any;
    opciones_grupo_cliente?: any;
    opciones_cliente?: any;
    opciones_cliente_aux?: any;
    opciones_competidores?: any;
    opciones_contrato_marco?: any;
    opciones_contrato_marco_aux?: any;
    opciones_estado?: any;
    opciones_estado_proy?: any;
    opciones_modulos?: any;
    opciones_monedas?: any;
    opciones_monedas_aux?: any;
    opciones_motivos?: any;
    opciones_oficina?: any;
    opciones_unidad_negocio?: any;
    opciones_pais?: any;
    opciones_elementos_servicio?: any;
    opciones_sublineas?: any;
    opciones_sublineas_aux?: any;
    opciones_valorizacion?: any;

    dialogoSalir?: boolean;
    dialogoGuardar?: boolean;
    dialogoGuardar2?: boolean;
    dialogoGuardarError?: boolean;

    //Validaciones
    dialogoValidar?: boolean;
    dialogoValidarSim?: boolean;
    dialogoValidarPreparando?: boolean;
    dialogoValidarPropuesta?: boolean;
    dialogoValidarGanada?: boolean;
    dialogoValidarExtendida?: boolean;

    numero_orden?: any;
    monto_orden?: any;

    spTenant: string = "";
    spRelativeSite: string = "";
    probabilidad?: any;
    collapsed?: boolean;
    color_fondo_titulo?: string;
    color_fondo_seccion?: string;
    isCollapsedAll?: string;
    estado?: string;
    unidad_negocio?: string;
    dialogoCopiar?: boolean;
    hasID?: boolean;
    opoID: number = 0;
    totalAdjuntosPropuesta: number = 0;
    moneda?: string;

    estado_prev?: number;
    estimada_inicio_mayor?: boolean;
    estimada_fin_menor?: boolean;
    validarCorreo?: boolean;
    dialogo_copia?: boolean;
    dialogo_proyecto?: boolean;
    dialogo_extencion?: boolean;
    id_copia?: number;
    id_proyecto?: number;
    opciones_copia?: number;
    contadorDescripcion: number = 0;
    contadorLeccion: number = 0;
    numero_extension: string = "";
    gestionProyectoDisabled?: boolean;
    cantOC?: number;
    clientID: string = "";

    hasSim: boolean = false;

    library: string = "";
    tokenAPI: string = "";
    tokenAPIGraph: string = "";
    tokenAPISharePoint: string = "";
}
export class Oportunidad extends React.Component<OportunidadProps, OportunidadState> {
    constructor(props: any) {
        super(props);

        let pathName = window.location.pathname;
        let params = pathName.split("/");
        let hasID = params.length > 2;

        this.state = {
            op_info: {
                NOMBRE: "",
                DESCRIPCION: "",
                ID: 0,
                ID_ESTADO: 1,
                ID_ESTADO_PROY: 0,
                ID_UNIDAD: 0,
                RESPONSABLE_LOGIN: "",
                RESPONSABLE_DISPLAY: "",
                ID_LINEA: 0,
                ID_SUBLINEA: 0,
                PROBABILIDAD: 0,
                FECHA_CREACION: undefined,
                FECHA_MODIFICACION: undefined,
                FECHA_APERTURA: undefined,
                FECHA_ENVIO_PROPUESTA: undefined,
                ID_GRUPO_CLIENTE: 0,
                ID_CLIENTE: 0,
                COMPRADORES: "",
                AREA_CLIENTE: "",
                ID_PAIS: 0,
                ID_OFICINA: 0,
                NOMBRE_CONTACTO: "",
                MONTO_ESTIMADO: 0,
                ID_CONTRATO_MARCO: 0,
                RENTABILIDAD_ESPERADA: 0,
                ID_VALORIZACION: 0,
                MONTO_ORDEN_COMPRA: 0,
                RESPONSABLE_PROYECTO_LOGIN: "",
                RESPONSABLE_PROYECTO_DISPLAY: "",
                LICITACION: false,
                REQUIERE_ACREDITACION: false,
                FECHA_ESTIMADA_INICIO: undefined,
                FECHA_ESTIMADA_FIN: undefined,
                ID_MONEDA: 0,
                LECCION_APRENDIDA: "",
                COMENTARIOS: "",
                ID_MOTIVOS: 0,
                PRINCIPALES_ELEMENTOS: [],
                MOTIVOS: [],
                COMPETIDORES: [],
                COLABORADORES: [],
                MAIL: "",
                TELEFONO: 0,
                FECHA_INICIO: undefined,
                FECHA_TERMINO: undefined,
                INGRESO_VENTA: 0,
                COSTO_VENTA: 0,
                RESULTADO: 0,
                RENTABILIDAD: 0,
                ID_ORIGINAL: 0,
                ID_EXTENSION: 0,
                EXTENSION: false,
                token: "",
            },

            sim_info: {
                INGRESO_VENTA: 0,
                COSTO_VENTA: 0,
                RESULTADO: 0,
                RENTABILIDAD: 0,
                INGRESO_VENTA_CONVERSION: 0,
                COSTO_VENTA_CONVERSION: 0,
                RESULTADO_CONVERSION: 0,
                RENTABILIDAD_CONVERSION: 0,
            },
            //Opciones Dropdown
            opciones_linea: [],
            opciones_competidores: [],
            opciones_contrato_marco: [],
            opciones_contrato_marco_aux: [],
            opciones_modulos: [],
            opciones_monedas: [],
            opciones_monedas_aux: [],
            opciones_motivos: [],
            opciones_oficina: [],
            opciones_pais: [],
            opciones_estado: [],
            opciones_estado_proy: [
                {
                    key: 0,
                    text: "Inicio",
                },
                {
                    key: 1,
                    text: "Planificación",
                },
                {
                    key: 2,
                    text: "Ejecución",
                },
                {
                    key: 3,
                    text: "Cierre de ejecución",
                },
                {
                    key: 4,
                    text: "Cierre administrativo",
                },
            ],
            opciones_grupo_cliente: [],
            opciones_cliente: [],
            opciones_cliente_aux: [],
            opciones_elementos_servicio: [],
            opciones_sublineas: [],
            opciones_sublineas_aux: [],
            opciones_unidad_negocio: [],
            opciones_valorizacion: [],
            spRelativeSite: "",
            //STATE
            collapsed: true,
            color_fondo_titulo: "",
            color_fondo_seccion: "",
            isCollapsedAll: "",
            estadodelformulario: false,
            dialogoCopiar: true,
            dialogoSalir: true,
            dialogoGuardar: true,
            dialogoGuardar2: true,
            dialogoGuardarError: true,

            //Validaciones
            dialogoValidar: true,
            dialogoValidarPreparando: true,
            dialogoValidarPropuesta: true,
            dialogoValidarGanada: true,
            dialogoValidarExtendida: true,
            estimada_inicio_mayor: true,
            estimada_fin_menor: true,
            dialogoValidarSim: true,

            moneda: "",
            numero_orden: 0,
            monto_orden: 0,
            hasID: hasID,
            opoID: hasID ? parseInt(params[2]) : 0,
            totalAdjuntosPropuesta: 0,
            spTenant: fetchEngine.spTenant,
            //FUTURA ENTIDAD
            probabilidad: 0,
            estado: "",
            fechacierre: undefined,
            unidad_negocio: "",
            estado_prev: 0,
            validarCorreo: true,
            id_copia: 0,
            id_proyecto: 0,
            dialogo_copia: true,
            dialogo_proyecto: true,
            dialogo_extencion: true,
            opciones_copia: 0,
            contadorDescripcion: 250,
            contadorLeccion: 250,
            numero_extension: "",
            gestionProyectoDisabled: false,
            cantOC: 0,
            clientID: process.env.REACT_APP_SCOPE_API ? process.env.REACT_APP_SCOPE_API : "",

            hasSim: false,
            esAdmin: false,
            esUsuario: false,
            esGestion: false,
            esValidador: false,
            esContable: false,

            library: process.env.REACT_APP_LIBRARY ? process.env.REACT_APP_LIBRARY : "",
            tokenAPI: "",
            tokenAPIGraph: "",
            tokenAPISharePoint: "",
        };

        this.procesar();
        this.textFieldOnChange = this.textFieldOnChange.bind(this);
        this._onFormatDate = this._onFormatDate.bind(this);
        this._onCheckChange = this._onCheckChange.bind(this);
        this.ChangeProbabilidad = this.ChangeProbabilidad.bind(this);
        this.redirigirSimulacion = this.redirigirSimulacion.bind(this);
        this.obtenerTotalPropuesta = this.obtenerTotalPropuesta.bind(this);
        this.callbackWorkspace = this.callbackWorkspace.bind(this);
        this.cambiarestadoformulario = this.cambiarestadoformulario.bind(this);
        this.userHandler = this.userHandler.bind(this);
        this.guardarOP = this.guardarOP.bind(this);

        this.validarCamposGanada = this.validarCamposGanada.bind(this);
        this.validarCamposSimular = this.validarCamposSimular.bind(this);
        this.validarCamposPropuesta = this.validarCamposPropuesta.bind(this);
        this.validarCamposPreparando = this.validarCamposPreparando.bind(this);
    }

    componentDidMount() {
        if (authProvider != undefined) {
            new Promise<any>(async (resolve, reject) => {
                const tokens = await fetchEngine.getStorageValue(authProvider);
                resolve(tokens);
            }).then((d) => {
                this.setState({ tokenAPI: d.tokenAPI, tokenAPIGraph: d.tokenAPIGraph, tokenAPISharePoint: d.tokenAPISharePoint }, () => {
                    this.verificarAdmin();
                    this.verificarUsuario();
                    this.verificarGestion();
                    this.verificarValidador();
                    this.verificarContable();
                    this.obtenerValores();
                });
            });
        }
    }

    //CONSULTAS PERFILAMIENTO
    public verificarAdmin = async () => {
        fetchEngine
            .getAPI(`/api/Perfiles/GetAdmin`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                this.setState({ esAdmin: result.ok });
            });
    };

    public verificarUsuario = async () => {
        fetchEngine
            .getAPI(`/api/Perfiles/GetUsuario`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                this.setState({ esUsuario: result.ok });
            });
    };

    public verificarGestion = async () => {
        fetchEngine
            .getAPI(`/api/Perfiles/GetGestion`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                this.setState({ esGestion: result.ok });
            });
    };

    public verificarValidador = async () => {
        fetchEngine
            .getAPI(`/api/Perfiles/GetValidador`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                this.setState({ esValidador: result.ok });
            });
    };

    public verificarContable = async () => {
        fetchEngine
            .getAPI(`/api/Perfiles/GetContable`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                this.setState({ esContable: result.ok });
            });
    };

    private _onFormatDate = (date: Date | undefined): string => {
        if (date != undefined) {
            return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
        } else {
            return "01-01-0001";
        }
    };

    public _onFormatDateTime(date: string) {
        date = date.split("T")[0];

        return date.split("-")[2] + "/" + date.split("-")[1] + "/" + date.split("-")[0];
    }

    public cargarHerencia() {
        if (this.state.op_info.ID_LINEA != undefined && this.state.op_info.ID_LINEA > 0) {
            let opciones_sublineas2: any[] = [];
            this.state.opciones_sublineas_aux.map((a: any) => {
                if (a.id_linea === this.state.op_info.ID_LINEA) {
                    opciones_sublineas2.push({
                        key: a.key,
                        text: a.text,
                        disabled: !a.activo,
                    });
                }
            });
            this.setState({ opciones_sublineas: opciones_sublineas2 });
        }

        if (this.state.op_info.ID_GRUPO_CLIENTE != undefined && this.state.op_info.ID_GRUPO_CLIENTE > 0) {
            let opciones_grupo_clientes: any[] = [];
            this.state.opciones_cliente_aux.map((a: any) => {
                if (a.id_grupo_cliente === this.state.op_info.ID_GRUPO_CLIENTE) {
                    opciones_grupo_clientes.push({
                        key: a.key,
                        text: a.text,
                        disabled: !a.activo,
                    });
                }
            });
            this.setState({ opciones_cliente: opciones_grupo_clientes });
        }

        if (this.state.op_info.ID_UNIDAD != undefined && this.state.op_info.ID_UNIDAD > 0) {
            let opciones_contratomarco: any[] = [];

            this.state.opciones_contrato_marco_aux.map((a: any) => {
                if (a.id_unidad === this.state.op_info.ID_UNIDAD) {
                    opciones_contratomarco.push({
                        key: a.key,
                        text: a.text,
                        id_moneda: a.id_moneda,
                        disabled: !a.activo,
                    });
                }
            });

            let opciones_moneda: any[] = [];
            let id_moneda: number = 0;
            let text_moneda: string = "";
            this.state.opciones_unidad_negocio.map((b: any) => {
                if (this.state.op_info.ID_UNIDAD == b.key) {
                    id_moneda = b.id_moneda;
                }
            });

            this.state.opciones_monedas_aux.map((a: any) => {
                if (a.key == id_moneda) {
                    text_moneda = a.text;
                }
            });

            this.setState({ opciones_contrato_marco: opciones_contratomarco, opciones_monedas: opciones_moneda, moneda: text_moneda });
        }
    }

    private async obtenerValores() {
        fetchEngine
            .getAPI("/api/DatosFormulario", {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                if (result.ok) {
                    const clt: any[] = [];
                    const cmp: any[] = [];
                    const ctm: any[] = [];
                    const est: any[] = [];
                    const lin: any[] = [];
                    const mdl: any[] = [];
                    const mnd: any[] = [];
                    const mtv: any[] = [];
                    const ofc: any[] = [];
                    const pais: any[] = [];
                    const pels: any[] = [];
                    const subl: any[] = [];
                    const unn: any[] = [];
                    const vlr: any[] = [];
                    const grp_clt: any[] = [];
                    if (result.data) {
                        result.data.estados.map((a: any) => {
                            est.push({
                                key: a.ID,
                                text: decodeURIComponent(a.TITULO),
                                id_modulo: a.ID_MODULO,
                            });
                        });
                        result.data.grupo_clientes.map((a: any) => {
                            grp_clt.push({
                                key: a.ID,
                                text: a.TITULO,
                                activo: a.ACTIVO,
                                disabled: !a.ACTIVO,
                            });
                        });
                        result.data.cliente.map((a: any) => {
                            clt.push({
                                key: a.ID,
                                text: decodeURIComponent(a.TITULO),
                                id_grupo_cliente: a.ID_GRUPO_CLIENTE,
                                activo: a.ACTIVO,
                                disabled: !a.ACTIVO,
                            });
                        });
                        result.data.competidores.map((a: any) => {
                            cmp.push({
                                key: a.ID,
                                text: decodeURIComponent(a.TITULO),
                                activo: a.ACTIVO,
                                disabled: !a.ACTIVO,
                            });
                        });
                        result.data.contratos_marco.map((a: any) => {
                            ctm.push({
                                key: a.ID,
                                text: a.TITULO,
                                activo: a.ACTIVO,
                                id_unidad: a.ID_UNIDAD_NEGOCIO,
                                hora: a.HORA_DIA,
                                id_moneda: a.ID_MONEDA,
                                disabled: !a.ACTIVO,
                            });
                        });
                        result.data.lineas.map((a: any) => {
                            lin.push({
                                key: a.ID,
                                text: decodeURIComponent(a.TITULO),
                                activo: a.ACTIVO,
                                disabled: !a.ACTIVO,
                            });
                        });
                        result.data.modulos.map((a: any) => {
                            mdl.push({
                                key: a.ID,
                                text: a.TITULO,
                                activo: a.ACTIVO,
                                disabled: !a.ACTIVO,
                            });
                        });
                        result.data.monedas.map((a: any) => {
                            mnd.push({
                                key: a.ID,
                                text: a.TITULO,
                                activo: a.ACTIVO,
                                disabled: !a.ACTIVO,
                            });
                        });
                        result.data.motivos.map((a: any) => {
                            mtv.push({
                                key: a.ID,
                                text: a.TITULO,
                                activo: a.ACTIVO,
                                disabled: !a.ACTIVO,
                            });
                        });
                        result.data.oficina.map((a: any) => {
                            ofc.push({
                                key: a.ID,
                                text: a.TITULO,
                                activo: a.ACTIVO,
                                disabled: !a.ACTIVO,
                            });
                        });
                        result.data.paises.map((a: any) => {
                            pais.push({
                                key: a.ID,
                                text: a.TITULO,
                                activo: a.ACTIVO,
                                disabled: !a.ACTIVO,
                            });
                        });
                        result.data.principales_elementos_servicio.map((a: any) => {
                            pels.push({
                                key: a.ID,
                                text: decodeURIComponent(a.TITULO),
                                activo: a.ACTIVO,
                                disabled: !a.ACTIVO,
                            });
                        });
                        result.data.sublineas.map((a: any) => {
                            subl.push({
                                key: a.ID,
                                text: a.TITULO,
                                activo: a.ACTIVO,
                                id_linea: a.ID_LINEA,
                                disabled: !a.ACTIVO,
                            });
                        });
                        result.data.unidades_negocios.map((a: any) => {
                            unn.push({
                                key: a.ID,
                                text: a.TITULO,
                                activo: a.ACTIVO,
                                id_moneda: a.ID_MONEDA,
                                disabled: !a.ACTIVO,
                            });
                        });
                        result.data.valorizacion.map((a: any) => {
                            vlr.push({
                                key: a.ID,
                                text: a.TITULO,
                                activo: a.ACTIVO,
                                disabled: !a.ACTIVO,
                            });
                        });

                        this.setState(
                            {
                                opciones_grupo_cliente: grp_clt,
                                opciones_cliente_aux: clt,
                                opciones_estado: est.filter((e) => e.id_modulo === 1),
                                opciones_competidores: cmp,
                                opciones_contrato_marco_aux: ctm,
                                opciones_linea: lin,
                                opciones_modulos: mdl,
                                opciones_monedas_aux: mnd,
                                opciones_motivos: mtv,
                                opciones_oficina: ofc,
                                opciones_pais: pais,
                                opciones_elementos_servicio: pels,
                                opciones_sublineas_aux: subl,
                                opciones_unidad_negocio: unn,
                                opciones_valorizacion: vlr,
                            },
                            () => {
                                this.obtenerOP();
                            }
                        );
                    }
                } else {
                    console.log(result.data);
                }
            });
    }

    public procesar() {
        let op = this.state.op_info;
    }

    public salirPT() {
        window.location.href = `/`;
    }

    public copiarOportunidad = async (ID: number) => {
        let userName: any;
        if (authProvider.getAccountInfo() != null) {
            userName = authProvider.getAccountInfo() ? authProvider.getAccountInfo() : {};
        }

        fetchEngine
            .putAPI(`/api/oportunidad/${ID}`, "", {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                let copyID = result.id;
                this.setState({ id_copia: copyID, dialogo_copia: false });
            });
    };
    public crearGestionProyecto = async (ID: number) => {
        let userName: any;
        let op = this.state.op_info;
        if (authProvider.getAccountInfo() != null) {
            userName = authProvider.getAccountInfo() ? authProvider.getAccountInfo() : {};
        }

        op.token = this.state.tokenAPISharePoint;

        fetchEngine
            .postAPI(`/api/GestionProyecto/CrearProyecto`, op, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                const opoExists = result.id === 1 ? true : false;
                let copyID = result.id;
                this.setState({ dialogoGuardar2: true, dialogoGuardar: true, id_proyecto: copyID, dialogo_proyecto: opoExists });
            });
    };

    public extenderOportunidad = async (ID: number) => {
        let userName: any;
        if (authProvider.getAccountInfo() != null) {
            userName = authProvider.getAccountInfo() ? authProvider.getAccountInfo() : {};
        }

        fetchEngine
            .postAPI(`/api/oportunidad/ExtenderOportunidad?id=${ID}`, "", {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                let copyID = result.id;
                this.setState({ id_copia: copyID, dialogo_extencion: false });
            });
    };

    private cambiarestadoformulario() {
        this.setState({ estadodelformulario: true });
    }

    public seleccionCopia(ev: any, opcion: any) {
        this.setState({ opciones_copia: parseInt(opcion.key) });
    }

    public textFieldOnChange(ev: any) {
        if (this.state.estadodelformulario === false) {
            this.setState({ estadodelformulario: true });
        }

        let key = ev.target.id;
        let value = ev.target.value;
        let campo = ev.target;
        let op = this.state.op_info;
        let val = 0;
        switch (key) {
            case "nombreoportunidad":
                if (value.length <= 255) {
                    op.NOMBRE = value;
                }
                break;
            case "descripcion":
                if (value.length <= 250) {
                    op.DESCRIPCION = value;
                }
                break;
            case "areacliente":
                op.AREA_CLIENTE = value;
                break;
            case "compradores":
                if (value.length <= 255) {
                    op.COMPRADORES = value;
                }
                break;
            case "nombrecontacto":
                op.NOMBRE_CONTACTO = value;
                break;
            case "mail":
                let regexMail = new RegExp(
                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
                op.MAIL = value;
                if (regexMail.test(value) === false) {
                    this.setState({ validarCorreo: false });
                } else {
                    this.setState({ validarCorreo: true });
                }
                if (value === "") {
                    this.setState({ validarCorreo: true });
                }
                break;
            case "telefono":
                val = value === "" ? 0 : parseInt(value);
                if (val <= 999999999) {
                    op.TELEFONO = val;
                }
                break;
            case "montoordencompra":
                val = value === "" ? 0 : parseInt(value);
                if (val <= 99999999999) {
                    this.setState({ monto_orden: value });
                }
                break;
            case "leccionaprendida":
                if (value.length <= 250) {
                    op.LECCION_APRENDIDA = value;
                }
                break;
            case "comentarios":
                op.COMENTARIOS = value;
                break;
            case "ordencompra":
                val = value === "" ? 0 : parseInt(value);
                if (val <= 99999999999) {
                    this.setState({ numero_orden: value });
                }
            case "ingresoventa":
                val = value === "" ? 0 : parseInt(value);
                op.INGRESO_VENTA = val;
                break;
            case "costoventa":
                val = value === "" ? 0 : parseInt(value);
                op.COSTO_VENTA = val;
                break;
            case "resultado":
                val = value === "" ? 0 : parseInt(value);
                op.RESULTADO = val;
                break;
            case "rentabilidad":
                val = value === "" ? 0 : parseFloat(value);
                op.RENTABILIDAD = val;
                break;
        }
        this.setState({ op_info: op });
        //}
    }

    public ChangeProbabilidad(value: any) {
        let op = this.state.op_info;
        op.PROBABILIDAD = value;
        this.setState({ op_info: op });
    }

    private _onCheckChange(ev?: any, isChecked?: boolean) {
        this.setState({ estadodelformulario: true });
        let id = ev.target.id;
        let op = this.state.op_info;
        switch (id) {
            case "licitacion":
                op.LICITACION = isChecked;
                break;
            case "acreditacion":
                op.REQUIERE_ACREDITACION = isChecked;
                break;
        }

        this.setState({ op_info: op });
    }

    private _onChangeUnidadNegocio = (event: any, item: any): void => {
        this.setState({ estadodelformulario: true });
        let op = this.state.op_info;
        op.ID_UNIDAD = item.key;

        let opciones_contratomarco: any[] = [];

        this.state.opciones_contrato_marco_aux.map((a: any) => {
            if (a.id_unidad === item.key) {
                opciones_contratomarco.push({
                    key: a.key,
                    text: a.text,
                    id_moneda: a.id_moneda,
                    disabled: !a.activo,
                });
            }
        });

        let opciones_moneda: any[] = [];

        this.state.opciones_monedas_aux.map((a: any) => {
            if (a.key === item.id_moneda) {
                opciones_moneda.push({
                    key: a.key,
                    text: a.text,
                    disabled: !a.activo,
                });
            }
        });

        this.setState({ op_info: op, opciones_contrato_marco: opciones_contratomarco, moneda: opciones_moneda[0].text });
    };

    private _onChangeEstado = (event: any, item: any): void => {
        let op = this.state.op_info;
        this.setState({ estadodelformulario: true });
        if (item.key === 2) {
            this.validarCamposPreparando(item.key);
        } else if (item.key === 3) {
            this.validarCamposPropuesta(item.key);
        } else if (item.key === 4) {
            this.obtenerOC(item.key);
        } else if (item.key === 28) {
            this.validarCamposExtendida(item.key);
        } else {
            op.ID_ESTADO = item.key;
            this.setState({ op_info: op });
        }
    };
    private _onChangeEstadoProy = (event: any, item: any): void => {
        this.setState({ estadodelformulario: true });
        let op = this.state.op_info;
        op.ID_ESTADO_PROY = item.key;
        this.setState({ op_info: op });
    };

    private _onChangeLinea = (event: any, item: any): void => {
        this.setState({ estadodelformulario: true });
        let op = this.state.op_info;
        op.ID_LINEA = item.key;

        let opciones_sublineas2: any[] = [];

        this.state.opciones_sublineas_aux.map((a: any) => {
            if (a.id_linea === item.key) {
                opciones_sublineas2.push({
                    key: a.key,
                    text: a.text,
                    disabled: !a.activo,
                });
            }
        });

        this.setState({ op_info: op, opciones_sublineas: opciones_sublineas2 });
    };

    private _onChangeSubLinea = (event: any, item: any): void => {
        this.setState({ estadodelformulario: true });
        let op = this.state.op_info;
        op.ID_SUBLINEA = item.key;
        this.setState({ op_info: op });
    };

    private _onChangeElementos = (event: any, item: any): void => {
        this.setState({ estadodelformulario: true });
        let op = this.state.op_info;

        let opciones: any[] = this.state.opciones_elementos_servicio;

        opciones.forEach((o) => {
            if (o.key === item.key) {
                o.selected = item.selected;
            }
        });

        this.setState({ opciones_elementos_servicio: [...opciones] });
    };

    private _onChangeGrupoClientes = (event: any, item: any): void => {
        this.setState({ estadodelformulario: true });
        let op = this.state.op_info;
        op.ID_GRUPO_CLIENTE = item.key;

        let opciones_grupo_clientes2: any[] = [];

        this.state.opciones_cliente_aux.map((a: any) => {
            if (a.id_grupo_cliente === item.key) {
                opciones_grupo_clientes2.push({
                    key: a.key,
                    text: a.text,
                    disabled: !a.activo,
                });
            }
        });

        this.setState({ op_info: op, opciones_cliente: opciones_grupo_clientes2 });
    };

    private _onChangeClientes = (event: any, item: any): void => {
        this.setState({ estadodelformulario: true });
        let op = this.state.op_info;
        op.ID_CLIENTE = item.key;
        this.setState({ op_info: op });
    };

    private _onChangePais = (event: any, item: any): void => {
        this.setState({ estadodelformulario: true });
        let op = this.state.op_info;
        op.ID_PAIS = item.key;
        this.setState({ op_info: op });
    };

    private _onChangeOficina = (event: any, item: any): void => {
        this.setState({ estadodelformulario: true });
        let op = this.state.op_info;
        op.ID_OFICINA = item.key;
        this.setState({ op_info: op });
    };

    private _onChangeCompetidores = (event: any, item: any): void => {
        this.setState({ estadodelformulario: true });
        let op = this.state.op_info;
        let opciones: any[] = this.state.opciones_competidores;

        opciones.forEach((o) => {
            if (o.key === item.key) {
                o.selected = item.selected;
            }
        });

        this.setState({ opciones_competidores: [...opciones] });
    };

    private _onChangeContrato = (event: any, item: any): void => {
        this.setState({ estadodelformulario: true });
        let op = this.state.op_info;
        op.ID_CONTRATO_MARCO = item.key;

        let opciones_moneda: any[] = [];

        this.state.opciones_monedas_aux.map((a: any) => {
            if (a.key === item.id_moneda) {
                opciones_moneda.push({
                    key: a.key,
                    text: a.text,
                    disabled: !a.activo,
                });
            }
        });

        this.setState({ op_info: op, opciones_monedas: opciones_moneda });
    };

    private _onChangeMotivos = (event: any, item: any): void => {
        this.setState({ estadodelformulario: true });
        let op = this.state.op_info;

        let opciones: any[] = this.state.opciones_motivos;

        opciones.forEach((o) => {
            if (o.key === item.key) {
                o.selected = item.selected;
            }
        });

        this.setState({ opciones_motivos: [...opciones] });
    };

    private _onSelectDateCreacion = (date: Date | null | undefined): void => {
        let op = this.state.op_info;
        this.setState({ estadodelformulario: true });
        if (date != null) {
            op.FECHA_CREACION = date;
        }
        this.setState({ op_info: op });
    };

    private _onSelectDateModificacion = (date: Date | null | undefined): void => {
        let op = this.state.op_info;
        this.setState({ estadodelformulario: true });
        if (date != null) {
            op.FECHA_MODIFICACION = date;
        }
        this.setState({ op_info: op });
    };

    private _onSelectDateEstimadaInicio = (date: Date | null | undefined): void => {
        let op = this.state.op_info;
        this.setState({ estadodelformulario: true });

        if (date != null) {
            if (op.FECHA_ESTIMADA_FIN != undefined) {
                if (date > op.FECHA_ESTIMADA_FIN) {
                    this.setState({ estimada_inicio_mayor: false });
                } else {
                    op.FECHA_ESTIMADA_INICIO = date;
                }
            } else {
                op.FECHA_ESTIMADA_INICIO = date;
            }
        }
        this.setState({ op_info: op });
    };

    private _onSelectDateEstimadaFin = (date: Date | null | undefined): void => {
        let op = this.state.op_info;
        this.setState({ estadodelformulario: true });

        if (date != null) {
            if (op.FECHA_ESTIMADA_INICIO != undefined) {
                if (date < op.FECHA_ESTIMADA_INICIO) {
                    this.setState({ estimada_fin_menor: false });
                } else {
                    op.FECHA_ESTIMADA_FIN = date;
                }
            } else {
                op.FECHA_ESTIMADA_FIN = date;
            }
        }

        this.setState({ op_info: op });
    };

    private _onSelectDateEnvioPropuesta = (date: Date | null | undefined): void => {
        let op = this.state.op_info;
        this.setState({ estadodelformulario: true });
        if (date != null) {
            op.FECHA_ENVIO_PROPUESTA = date;
        }
        this.setState({ op_info: op });
    };

    private _onSelectDateInicio = (date: Date | null | undefined): void => {
        let op = this.state.op_info;
        this.setState({ estadodelformulario: true });
        if (date != null) {
            if (op.FECHA_TERMINO != undefined) {
                if (date > op.FECHA_TERMINO) {
                    this.setState({ estimada_inicio_mayor: false });
                } else {
                    op.FECHA_INICIO = date;
                }
            } else {
                op.FECHA_INICIO = date;
            }
        }
        this.setState({ op_info: op });
    };

    private _onSelectDateFin = (date: Date | null | undefined): void => {
        let op = this.state.op_info;
        this.setState({ estadodelformulario: true });
        if (date != null) {
            if (op.FECHA_INICIO != undefined) {
                if (date < op.FECHA_INICIO) {
                    this.setState({ estimada_fin_menor: false });
                } else {
                    op.FECHA_TERMINO = date;
                }
            } else {
                op.FECHA_TERMINO = date;
            }
        }
        this.setState({ op_info: op });
    };

    public obtenerOC = async (key: number) => {
        fetchEngine
            .getAPI(`/api/ordencompra/${this.state.opoID}`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((resultado: any) => {
                if (resultado.ok) {
                    this.setState({ cantOC: resultado.data.length }, () => {
                        this.validarCamposGanada(key);
                    });
                } else {
                    console.log(resultado.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    //FUNCIONES PRINCIPALES BOTONERA

    public async obtenerOP() {
        if (this.state.hasID) {
            let sim = this.state.sim_info;
            fetchEngine
                .getAPI(`/api/oportunidad/${this.state.opoID}`, {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.state.tokenAPI}`,
                })
                .then((resultado: any) => {
                    if (resultado.ok) {
                        let oportunidad = resultado.data.oportunidad[0];
                        let simulacion = resultado.data.simulacion[0];
                        let elementos: any[] = resultado.data.elementos;
                        let motivos: any[] = resultado.data.motivos;
                        let competidores: any[] = resultado.data.competidores;
                        let colaboradores: any[] = resultado.data.colaboradores;
                        let id_extension: string = oportunidad.EXTENSION
                            ? oportunidad.ID_ORIGINAL + " - " + oportunidad.ID_EXTENSION
                            : oportunidad.ID;
                        if (oportunidad.ID_ESTADO === 4) {
                            this.setState({ gestionProyectoDisabled: true });
                        }
                        let el_actuales = this.state.opciones_elementos_servicio;

                        el_actuales.forEach((e: any) => {
                            let elemento_guardado = elementos.find((d: any) => d.ID_ELEMENTO === e.key);
                            e.selected = elemento_guardado != undefined ? elemento_guardado.ACTIVO : false;
                        });

                        let el_motivos = this.state.opciones_motivos;

                        el_motivos.forEach((e: any) => {
                            let elemento_guardado = motivos.find((d: any) => d.ID_ELEMENTO === e.key);
                            e.selected = elemento_guardado != undefined ? elemento_guardado.ACTIVO : false;
                        });

                        let el_competidores = this.state.opciones_competidores;

                        el_competidores.forEach((e: any) => {
                            let elemento_guardado = competidores.find((d: any) => d.ID_ELEMENTO === e.key);
                            e.selected = elemento_guardado != undefined ? elemento_guardado.ACTIVO : false;
                        });

                        oportunidad.COLABORADORES = colaboradores;
                        oportunidad.ORDEN_COMPRA = [];
                        oportunidad.MOTIVOS = el_motivos;
                        oportunidad.FECHA_APERTURA = oportunidad.FECHA_APERTURA != null ? new Date(oportunidad.FECHA_APERTURA) : new Date();
                        oportunidad.FECHA_INICIO = oportunidad.FECHA_INICIO != null ? new Date(oportunidad.FECHA_INICIO) : new Date();
                        oportunidad.FECHA_TERMINO = oportunidad.FECHA_TERMINO != null ? new Date(oportunidad.FECHA_TERMINO) : new Date();
                        oportunidad.FECHA_ENVIO_PROPUESTA =
                            oportunidad.FECHA_ENVIO_PROPUESTA != null ? new Date(oportunidad.FECHA_ENVIO_PROPUESTA) : new Date();
                        oportunidad.FECHA_ESTIMADA_INICIO =
                            oportunidad.FECHA_ESTIMADA_INICIO != null ? new Date(oportunidad.FECHA_ESTIMADA_INICIO) : new Date();
                        oportunidad.FECHA_ESTIMADA_FIN =
                            oportunidad.FECHA_ESTIMADA_FIN != null ? new Date(oportunidad.FECHA_ESTIMADA_FIN) : new Date();
                        oportunidad.FECHA_CREACION = oportunidad.FECHA_CREACION != null ? new Date(oportunidad.FECHA_CREACION) : new Date();
                        oportunidad.FECHA_MODIFICACION =
                            oportunidad.FECHA_MODIFICACION != null ? new Date(oportunidad.FECHA_MODIFICACION) : new Date();

                        if (simulacion != undefined) {
                            sim.INGRESO_VENTA = simulacion.INGRESO_VENTA_DCTO;
                            sim.COSTO_VENTA = simulacion.COSTO_VENTA;
                            sim.RESULTADO = simulacion.RESULTADO;
                            sim.RENTABILIDAD = simulacion.RENTABILIDAD;

                            sim.INGRESO_VENTA_CONVERSION = simulacion.INGRESO_VENTA_DCTO_CONVERSION;
                            sim.COSTO_VENTA_CONVERSION = simulacion.COSTO_VENTA_CONVERSION;
                            sim.RESULTADO_CONVERSION = simulacion.RESULTADO_CONVERSION;
                            sim.RENTABILIDAD_CONVERSION = simulacion.RENTABILIDAD_CONVERSION;

                            this.setState({ sim_info: sim });
                        }

                        this.setState(
                            {
                                op_info: oportunidad,
                                opciones_elementos_servicio: [...el_actuales],
                                opciones_motivos: [...el_motivos],
                                opciones_competidores: [...el_competidores],
                                numero_extension: id_extension,
                                hasSim: resultado.data.simulacion.length > 0 ? true : false,
                            },
                            () => {}
                        );

                        this.forceUpdate();
                        this.cargarHerencia();
                    } else {
                        console.log(resultado.data);
                    }
                    this.cargarHerencia();
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    public async guardarOP() {
        let op = this.state.op_info;
        let opID = this.state.opoID;
        let userName: any;
        let urlSitio = window.location.protocol + "//" + window.location.host;
        let colaboradores: any[] = [];

        op.ID = this.state.opoID;

        if (authProvider.getAccountInfo() != null) {
            userName = authProvider.getAccountInfo() ? authProvider.getAccountInfo() : {};
        }
        if (this.state.opoID === 0) {
            op.CREADO_POR = userName.account.userName;
        }
        op.MODIFICADO_POR = userName.account.userName;

        this.setState({ dialogoGuardar: false });
        const requestHeaders: Headers = new Headers();

        op.PRINCIPALES_ELEMENTOS = this.state.opciones_elementos_servicio.map((k: any) => {
            return { ID_ELEMENTO: k.key, ACTIVO: k.selected };
        });

        op.MOTIVOS = this.state.opciones_motivos.map((k: any) => {
            return { ID_ELEMENTO: k.key, ACTIVO: k.selected };
        });

        op.COMPETIDORES = this.state.opciones_competidores.map((k: any) => {
            return { ID_ELEMENTO: k.key, ACTIVO: k.selected };
        });

        op.token = this.state.tokenAPISharePoint;

        fetchEngine
            .postAPI("/api/oportunidad", op, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((resultado: any) => {
                if (resultado.ok) {
                    this.setState({ dialogoGuardar2: false, dialogoGuardar: true, opoID: resultado.id });

                    if (op.ID_ESTADO === 4) {
                        this.crearGestionProyecto(op.ID!);
                    }

                    //Notificación Oportunidad ganada
                    if (op.ID_ESTADO === 4) {
                        let notificacionOportunidadGanada = {
                            correoResponsable: op.RESPONSABLE_PROYECTO_LOGIN,
                            correoControlGestion: "generico",
                            nombreOportunidad: op.NOMBRE,
                            enlaceOportunidad: `${urlSitio}/oportunidad/${resultado.id}`,
                        };
                        fetchEngine
                            .postAPI(
                                fetchEngine.urlFlujoOportunidadGanada ? fetchEngine.urlFlujoOportunidadGanada : "",
                                JSON.stringify(notificacionOportunidadGanada),
                                {
                                    Accept: "application/json",
                                    "Content-Type": "application/json",
                                }
                            )
                            .then((t: any) => {
                                if (t.ok) {
                                    console.log(t.ok);
                                } else {
                                    console.log("err");
                                }
                            })
                            .catch((e) => {
                                console.log(e);
                            });
                    }

                    if (this.state.hasID) {
                        this.obtenerOP();
                    } else {
                        let repo = {};
                        //Notificación responsable proyecto
                        op.COLABORADORES.forEach((e) => {
                            colaboradores.push(e.MAIL);
                        });
                        if (resultado.id !== op.ID) {
                            let notificacionResponsable = {
                                correoResponsable: op.RESPONSABLE_LOGIN,
                                colaboradores: colaboradores,
                                nombreOportunidad: op.NOMBRE,
                                enlaceOportunidad: `${urlSitio}/oportunidad/${resultado.id}`,
                            };

                            fetchEngine
                                .postAPI(
                                    fetchEngine.urlFlujoOportunidadResponsable ? fetchEngine.urlFlujoOportunidadResponsable : "",
                                    JSON.stringify(notificacionResponsable),
                                    {
                                        Accept: "application/json",
                                        "Content-Type": "application/json",
                                    }
                                )
                                .then((t: any) => {
                                    if (t.ok) {
                                        console.log(t.ok);
                                    } else {
                                        console.log("err");
                                    }
                                })
                                .catch((e) => {
                                    console.log(e);
                                });
                        }

                        opID = resultado.id;
                        history.push(`/oportunidad/${resultado.id}`);
                        this.setState({ opoID: opID, hasID: true }, () => {
                            this.obtenerOP();
                        });
                    }
                } else {
                    this.setState({ dialogoGuardarError: false });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    public putAPI(url: string, data: any, headers?: any) {
        if (headers) {
            return axios.put(url, data, {
                headers: headers,
            });
        } else {
            return axios.put(url, data, {
                //headers: headers
            });
        }
    }

    public postAPI(url: string, data: any, headers?: any) {
        if (headers) {
            return axios.post(url, data, {
                headers: headers,
            });
        } else {
            return axios.post(url, data, {
                //headers: headers
            });
        }
    }

    public redirigirSimulacion() {
        window.location.href = `/simulador/0/${this.state.opoID}`;
    }

    public validarCamposSimular() {
        if (
            this.state.op_info.NOMBRE === undefined ||
            this.state.op_info.NOMBRE === "" ||
            this.state.op_info.ID_UNIDAD === 0 ||
            this.state.op_info.ID_CONTRATO_MARCO === 0 ||
            this.state.op_info.FECHA_INICIO === undefined ||
            this.state.op_info.FECHA_INICIO === null ||
            this.state.op_info.FECHA_TERMINO === undefined ||
            this.state.op_info.FECHA_TERMINO === null
        ) {
            this.setState({ dialogoValidar: false, dialogoValidarSim: false, dialogoValidarGanada: true });
        } else {
            this.guardarOP();
            this.setState({ dialogoValidar: true, dialogoValidarSim: true, dialogoValidarGanada: true });
            if (this.state.opoID != 0) {
                this.redirigirSimulacion();
            }
        }
    }

    public validarCamposPreparando(key: number) {
        let op = this.state.op_info;
        this.setState({ estado_prev: op.ID_ESTADO });
        if (
            this.state.op_info.NOMBRE === undefined ||
            this.state.op_info.NOMBRE === "" ||
            this.state.op_info.ID_UNIDAD === 0 ||
            this.state.op_info.RESPONSABLE_DISPLAY === undefined ||
            this.state.op_info.RESPONSABLE_DISPLAY === "" ||
            this.state.op_info.DESCRIPCION === undefined ||
            this.state.op_info.DESCRIPCION === "" ||
            this.state.op_info.ID_CLIENTE === 0 ||
            this.state.op_info.ID_PAIS === 0
        ) {
            op.ID_ESTADO = this.state.estado_prev;
            this.setState({
                op_info: op,
                dialogoValidar: false,
                dialogoValidarPreparando: false,
                dialogoValidarGanada: true,
                dialogoValidarExtendida: true,
            });
        } else {
            op.ID_ESTADO = key;
            this.setState({ op_info: op });
        }
    }

    public validarCamposPropuesta(key: number) {
        let op = this.state.op_info;
        if (
            this.state.op_info.NOMBRE === undefined ||
            this.state.op_info.NOMBRE === "" ||
            this.state.op_info.ID_UNIDAD === 0 ||
            this.state.op_info.RESPONSABLE_DISPLAY === undefined ||
            this.state.op_info.RESPONSABLE_DISPLAY === "" ||
            this.state.op_info.DESCRIPCION === undefined ||
            this.state.op_info.DESCRIPCION === "" ||
            this.state.op_info.ID_CLIENTE === 0 ||
            this.state.op_info.ID_PAIS === 0 ||
            //PRINCIPALES ELEMENTOS DEL SERVICIO
            this.state.op_info.ID_LINEA === 0 ||
            this.state.op_info.ID_SUBLINEA === 0 ||
            this.state.op_info.PROBABILIDAD === 0 ||
            this.state.op_info.ID_OFICINA === 0 ||
            // Tiene sim
            this.state.hasSim === false
        ) {
            this.setState({
                op_info: op,
                dialogoValidar: false,
                dialogoValidarPropuesta: false,
                dialogoValidarGanada: true,
                dialogoValidarExtendida: true,
            });
        } else {
            op.ID_ESTADO = key;
            this.setState({ op_info: op });
        }
    }

    public validarCamposGanada(key: number) {
        let op = this.state.op_info;
        if (
            this.state.op_info.NOMBRE === undefined ||
            this.state.op_info.NOMBRE === "" ||
            this.state.op_info.ID_UNIDAD === 0 ||
            this.state.op_info.ID_CONTRATO_MARCO === 0 ||
            this.state.op_info.RESPONSABLE_DISPLAY === undefined ||
            this.state.op_info.RESPONSABLE_DISPLAY === "" ||
            this.state.op_info.DESCRIPCION === undefined ||
            this.state.op_info.DESCRIPCION === "" ||
            this.state.op_info.ID_CLIENTE === 0 ||
            this.state.op_info.ID_PAIS === 0 ||
            this.state.op_info.ID_LINEA === 0 ||
            this.state.op_info.ID_SUBLINEA === 0 ||
            this.state.op_info.PROBABILIDAD === 0 ||
            this.state.op_info.ID_OFICINA === 0 ||
            this.state.op_info.NOMBRE_CONTACTO === undefined ||
            this.state.op_info.NOMBRE_CONTACTO === "" ||
            ((this.state.op_info.MAIL != undefined || this.state.op_info.MAIL != "") && this.state.validarCorreo === false) ||
            //Propuesta
            this.state.op_info.COLABORADORES.length === 0 ||
            //COMPETIDORES
            this.state.op_info.FECHA_ESTIMADA_INICIO === undefined ||
            this.state.op_info.FECHA_ESTIMADA_INICIO === null ||
            this.state.op_info.FECHA_ESTIMADA_FIN === undefined ||
            this.state.op_info.FECHA_ESTIMADA_FIN === null ||
            this.state.op_info.FECHA_ENVIO_PROPUESTA === undefined ||
            this.state.op_info.FECHA_ENVIO_PROPUESTA === null ||
            //Resultado
            this.state.op_info.FECHA_INICIO === undefined ||
            this.state.op_info.FECHA_INICIO === null ||
            this.state.op_info.FECHA_TERMINO === undefined ||
            this.state.op_info.FECHA_TERMINO === null ||
            this.state.cantOC === 0 ||
            this.state.op_info.MOTIVOS.find((e) => e.selected === true) === undefined ||
            this.state.hasSim === false
        ) {
            this.setState({ op_info: op, dialogoValidar: false, dialogoValidarGanada: false, dialogoValidarExtendida: true });
        } else {
            op.ID_ESTADO = key;
            this.setState({ op_info: op });
        }
    }

    public validarCamposExtendida(key: number) {
        let op = this.state.op_info;
        this.setState({ estado_prev: op.ID_ESTADO });
        if (this.state.totalAdjuntosPropuesta === undefined || this.state.totalAdjuntosPropuesta === 0) {
            op.ID_ESTADO = this.state.estado_prev;
            this.setState({ op_info: op, dialogoValidar: false, dialogoValidarGanada: true, dialogoValidarExtendida: false });
        } else {
            op.ID_ESTADO = key;
            this.setState({ op_info: op });
        }
    }

    public userHandler(tipo: string, value: any, display: string) {
        let opo = this.state.op_info;

        if (tipo === "responsable2") {
            opo.RESPONSABLE_PROYECTO_DISPLAY = value[0].name;
            opo.RESPONSABLE_PROYECTO_LOGIN = value[0].mail;

            this.setState({ op_info: opo });
        } else if (tipo === "responsable") {
            opo.RESPONSABLE_DISPLAY = value[0].name;
            opo.RESPONSABLE_LOGIN = value[0].mail;
        } else if (tipo === "colaboradores") {
            opo.COLABORADORES = value.map((p: any) => {
                return { MAIL: p.mail, DISPLAYNAME: p.name, ACTIVO: true };
            });
        }

        this.setState({ op_info: opo });
    }

    public contarDescripcion(ev: any) {
        let max = 250;
        let contadorTexto = this.state.contadorDescripcion;
        let key = ev.target.value;
        let longitud = key.length;

        if (longitud <= max) {
            contadorTexto = max - longitud;
        } else {
            //document.getElementById("mensaje").value = cadena.substr(0, max);
        }

        this.setState({ contadorDescripcion: contadorTexto });
    }

    public contadorLeccion(ev: any) {
        let max = 250;
        let contadorTexto = this.state.contadorLeccion;
        let key = ev.target.value;
        let longitud = key.length;

        if (longitud <= max) {
            contadorTexto = max - longitud;
        } else {
            //document.getElementById("mensaje").value = cadena.substr(0, max);
        }

        this.setState({ contadorLeccion: contadorTexto });
    }

    public formatNumber(valor: string) {
        let value = "";

        if (valor.includes(".")) {
            value = valor.replace(".", ",");
        } else {
            value = valor;
        }

        value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

        return value;
    }

    public callbackWorkspace(props: any) {
        let worskspace = props[0];
        this.setState({ spRelativeSite: worskspace.URL_RELATIVA });
    }

    public obtenerTotalPropuesta(value: number) {
        this.setState({ totalAdjuntosPropuesta: value });
    }

    public seccionDatosGenerales() {
        return (
            <div>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                            <TextField
                                required={true}
                                id={"nombreoportunidad"}
                                label="Nombre Oportunidad"
                                value={this.state.op_info.NOMBRE}
                                disabled={this.state.gestionProyectoDisabled}
                                onChange={(e) => {
                                    this.textFieldOnChange(e);
                                }}></TextField>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                            <ComboBox
                                label="Unidad de Negocio"
                                placeholder="Seleccionar..."
                                allowFreeform={true}
                                disabled={this.state.gestionProyectoDisabled}
                                autoComplete="on"
                                selectedKey={this.state.op_info.ID_UNIDAD}
                                //value={this.state.op_info.NOMBRE}
                                //defaultValue={this.state.jefefaena}
                                onChange={this._onChangeUnidadNegocio}
                                options={this.state.opciones_unidad_negocio}
                                required={true}></ComboBox>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg3">
                            <Label>
                                Responsable Oportunidad <span className="obligatorio">*</span>
                            </Label>

                            <AutoComplete
                                id="responsable"
                                callback={this.userHandler}
                                multi={false}
                                disabled={this.state.gestionProyectoDisabled != undefined ? this.state.gestionProyectoDisabled : false}
                                defaultValue={{
                                    name: this.state.op_info.RESPONSABLE_DISPLAY,
                                    mail: this.state.op_info.RESPONSABLE_LOGIN,
                                }}
                                tokenAPIGraph={this.state.tokenAPIGraph}
                            />
                        </div>
                    </div>

                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                            <TextField
                                id={"descripcion"}
                                label="Descripción"
                                disabled={this.state.gestionProyectoDisabled}
                                value={this.state.op_info.DESCRIPCION}
                                multiline
                                autoAdjustHeight
                                required={true}
                                onChange={(e) => {
                                    this.textFieldOnChange(e);
                                    this.contarDescripcion(e);
                                }}></TextField>
                            <label>Quedan {this.state.contadorDescripcion} carácteres.</label>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                            <ComboBox
                                label="Área de negocio"
                                allowFreeform={true}
                                disabled={this.state.gestionProyectoDisabled}
                                autoComplete="on"
                                placeholder="Seleccionar..."
                                selectedKey={this.state.op_info.ID_LINEA}
                                onChange={this._onChangeLinea}
                                options={this.state.opciones_linea}
                                required={true}></ComboBox>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                            <ComboBox
                                label="Línea"
                                allowFreeform={true}
                                disabled={this.state.gestionProyectoDisabled}
                                autoComplete="on"
                                placeholder="Seleccionar..."
                                selectedKey={this.state.op_info.ID_SUBLINEA}
                                onChange={this._onChangeSubLinea}
                                options={this.state.opciones_sublineas}
                                required={true}></ComboBox>
                        </div>
                    </div>

                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg5">
                            <ComboBox
                                label="Principales elementos del servicio"
                                disabled={this.state.gestionProyectoDisabled}
                                autoComplete="on"
                                placeholder="Seleccionar..."
                                multiSelect={true}
                                onChange={this._onChangeElementos}
                                options={this.state.opciones_elementos_servicio}></ComboBox>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                            <Slider
                                value={this.state.op_info.PROBABILIDAD}
                                disabled={this.state.gestionProyectoDisabled}
                                label="Probabilidad de la oportunidad *"
                                min={0}
                                max={100}
                                onChange={(value) => this.ChangeProbabilidad(value)}></Slider>
                            <h6>Probabilidad: {this.state.op_info.PROBABILIDAD}%</h6>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2">
                            <DatePicker
                                onChange={this.cambiarestadoformulario}
                                id="fechacierre"
                                className={controlClass.control}
                                firstDayOfWeek={DayOfWeek.Monday}
                                strings={DayPickerStrings}
                                allowTextInput={true}
                                placeholder="Fecha de apertura"
                                label="Fecha de apertura"
                                value={this.state.op_info.FECHA_CREACION}
                                ariaLabel="Seleccione una fecha"
                                formatDate={this._onFormatDate}
                                disabled={true}
                                onSelectDate={this._onSelectDateCreacion}
                            />
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2">
                            <DatePicker
                                onChange={this.cambiarestadoformulario}
                                id="fechacierre"
                                className={controlClass.control}
                                firstDayOfWeek={DayOfWeek.Monday}
                                strings={DayPickerStrings}
                                allowTextInput={true}
                                placeholder="Fecha de modificación"
                                label="Fecha de modificación"
                                value={this.state.op_info.FECHA_MODIFICACION}
                                ariaLabel="Seleccione una fecha"
                                formatDate={this._onFormatDate}
                                disabled={true}
                                onSelectDate={this._onSelectDateModificacion}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    public seccionDatosCliente() {
        return (
            <div>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                            <ComboBox
                                label="Grupo cliente"
                                allowFreeform={true}
                                disabled={this.state.gestionProyectoDisabled}
                                autoComplete="on"
                                placeholder="Seleccionar..."
                                selectedKey={this.state.op_info.ID_GRUPO_CLIENTE}
                                onChange={this._onChangeGrupoClientes}
                                options={this.state.opciones_grupo_cliente}
                                required={true}></ComboBox>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                            <ComboBox
                                label="Cliente"
                                allowFreeform={true}
                                disabled={this.state.gestionProyectoDisabled}
                                autoComplete="on"
                                placeholder="Seleccionar..."
                                selectedKey={this.state.op_info.ID_CLIENTE}
                                onChange={this._onChangeClientes}
                                options={this.state.opciones_cliente}
                                required={true}></ComboBox>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                            <ComboBox
                                label="País"
                                allowFreeform={true}
                                disabled={this.state.gestionProyectoDisabled}
                                autoComplete="on"
                                placeholder="Seleccionar..."
                                selectedKey={this.state.op_info.ID_PAIS}
                                onChange={this._onChangePais}
                                options={this.state.opciones_pais}
                                required={true}></ComboBox>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                            <ComboBox
                                label="Oficina"
                                allowFreeform={true}
                                disabled={this.state.gestionProyectoDisabled}
                                autoComplete="on"
                                placeholder="Seleccionar..."
                                selectedKey={this.state.op_info.ID_OFICINA}
                                onChange={this._onChangeOficina}
                                options={this.state.opciones_oficina}
                                required={true}></ComboBox>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                            <TextField
                                label="Área de cliente"
                                placeholder="Área de cliente"
                                id={"areacliente"}
                                disabled={this.state.gestionProyectoDisabled}
                                value={this.state.op_info.AREA_CLIENTE}
                                onChange={(e) => {
                                    this.textFieldOnChange(e);
                                }}
                                required={true}></TextField>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                            <TextField
                                label="Compradores"
                                value={this.state.op_info.COMPRADORES}
                                disabled={this.state.gestionProyectoDisabled}
                                id={"compradores"}
                                onChange={(e) => {
                                    this.textFieldOnChange(e);
                                }}
                                required={true}></TextField>
                        </div>

                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                            <TextField
                                required={true}
                                label="Nombre contacto"
                                value={this.state.op_info.NOMBRE_CONTACTO}
                                disabled={this.state.gestionProyectoDisabled}
                                id={"nombrecontacto"}
                                onChange={(e) => {
                                    this.textFieldOnChange(e);
                                }}></TextField>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                            <TextField
                                required={true}
                                label="Mail"
                                id={"mail"}
                                value={this.state.op_info.MAIL}
                                disabled={this.state.gestionProyectoDisabled}
                                errorMessage={this.state.validarCorreo === false ? "Formato de correo incorrecto" : ""}
                                onChange={(e) => {
                                    this.textFieldOnChange(e);
                                }}></TextField>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                            <TextField
                                label="Teléfono"
                                id={"telefono"}
                                placeholder={"9 XXX XXX XX"}
                                disabled={this.state.gestionProyectoDisabled}
                                value={this.state.op_info.TELEFONO === 0 ? "" : this.state.op_info.TELEFONO.toString()}
                                onChange={(e) => {
                                    this.textFieldOnChange(e);
                                }}
                                required={true}></TextField>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    public seccionPropuesta() {
        return (
            <div>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                            <label className="labelResponsable">
                                Colaboradores <span className="obligatorio">*</span>
                            </label>

                            <div className="mt10">
                                <AutoComplete
                                    key={"colaboradores"}
                                    id="colaboradores"
                                    callback={this.userHandler}
                                    multi={true}
                                    disabled={this.state.gestionProyectoDisabled != undefined ? this.state.gestionProyectoDisabled : false}
                                    defaultValue={this.state.op_info.COLABORADORES.map((u: any) => {
                                        return { name: u.DISPLAYNAME, mail: u.MAIL };
                                    })}
                                    tokenAPIGraph={this.state.tokenAPIGraph}
                                />
                            </div>
                        </div>

                        <div className="ms-Grid-col ms-sm12 ms-md3 ms-lg3">
                            <ComboBox
                                label="Competidores"
                                disabled={this.state.gestionProyectoDisabled}
                                autoComplete="on"
                                placeholder="Seleccionar..."
                                multiSelect={true}
                                selectedKey={this.state.op_info.COMPRADORES}
                                onChange={this._onChangeCompetidores}
                                options={this.state.opciones_competidores}></ComboBox>
                        </div>

                        <div className="ms-Grid-col ms-sm12 ms-md3 ms-lg3">
                            <ComboBox
                                label="Contrato Marco"
                                allowFreeform={true}
                                disabled={this.state.gestionProyectoDisabled}
                                autoComplete="on"
                                placeholder="Seleccionar..."
                                selectedKey={this.state.op_info.ID_CONTRATO_MARCO}
                                onChange={this._onChangeContrato}
                                options={this.state.opciones_contrato_marco}
                                required={true}></ComboBox>
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                            <label>Adjuntar propuesta / Documentos</label>
                            <div>
                                {this.state.spRelativeSite != "" ? (
                                    <DragNDropFile
                                        disabled={this.state.gestionProyectoDisabled != undefined ? this.state.gestionProyectoDisabled : false}
                                        id={this.state.opoID}
                                        spTenant={this.state.spTenant}
                                        librarySP_EN={this.state.library}
                                        librarySpanish={"Documentos"}
                                        carpeta={"Propuestas"}
                                        spUrl={this.state.spRelativeSite}
                                        emailUsuario="propuesta"
                                        esAdminProp={this.state.esAdmin !== undefined ? this.state.esAdmin : false}
                                        esGestionProp={this.state.esGestion !== undefined ? this.state.esGestion : false}
                                        esUsuarioProp={this.state.esUsuario !== undefined ? this.state.esUsuario : false}
                                        esValidadorProp={this.state.esValidador !== undefined ? this.state.esValidador : false}
                                        esContableProp={this.state.esContable !== undefined ? this.state.esContable : false}
                                        callbackPropuesta={this.obtenerTotalPropuesta}
                                    />
                                ) : (
                                    "Debe crear el workspace para adjuntar documentos"
                                )}
                            </div>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg2">
                            <DatePicker
                                id="fechafin"
                                className={controlClass.control}
                                firstDayOfWeek={DayOfWeek.Monday}
                                strings={DayPickerStrings}
                                placeholder="Fecha estimada cierre"
                                label="Fecha estimada cierre"
                                ariaLabel="Seleccione una fecha"
                                formatDate={this._onFormatDate}
                                value={this.state.op_info.FECHA_ESTIMADA_FIN}
                                disabled={this.state.gestionProyectoDisabled}
                                onSelectDate={this._onSelectDateEstimadaFin}
                            />
                        </div>

                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg2">
                            <DatePicker
                                id="fechaenviopropuesta"
                                className={controlClass.control}
                                firstDayOfWeek={DayOfWeek.Monday}
                                strings={DayPickerStrings}
                                placeholder="Fecha envío propuesta"
                                label="Fecha envío propuesta"
                                ariaLabel="Seleccione una fecha"
                                formatDate={this._onFormatDate}
                                value={this.state.op_info.FECHA_ENVIO_PROPUESTA}
                                disabled={this.state.gestionProyectoDisabled}
                                onSelectDate={this._onSelectDateEnvioPropuesta}
                                isRequired={true}
                            />
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6"></div>
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                            <TextField label="Moneda Unidad de Negocio" value={this.state.moneda} disabled={true}></TextField>
                        </div>
                        <div className="ms-Grid-col ms-sm2 ms-md6 ms-lg3">
                            <div className="checkbox">
                                <Checkbox
                                    id="licitacion"
                                    label="Licitación"
                                    checked={this.state.op_info.LICITACION}
                                    onChange={this._onCheckChange}
                                    disabled={this.state.gestionProyectoDisabled}></Checkbox>
                                <Checkbox
                                    id="acreditacion"
                                    label="Requiere acreditación"
                                    checked={this.state.op_info.REQUIERE_ACREDITACION}
                                    onChange={this._onCheckChange}
                                    disabled={this.state.gestionProyectoDisabled}></Checkbox>
                            </div>
                        </div>
                    </div>

                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm6 ms-md3 ms-lg3">
                            <TextField
                                id="ingresoventa"
                                label="Ingreso Venta"
                                value={this.formatNumber(this.state.sim_info.INGRESO_VENTA_CONVERSION.toString())}
                                disabled={true}
                                onChange={(e) => {
                                    this.textFieldOnChange(e);
                                }}></TextField>
                        </div>
                        <div className="ms-Grid-col ms-sm6 ms-md3 ms-lg3">
                            <TextField
                                id="costoventa"
                                label="Costo Venta"
                                value={this.formatNumber(this.state.sim_info.COSTO_VENTA_CONVERSION.toString())}
                                disabled={true}
                                onChange={(e) => {
                                    this.textFieldOnChange(e);
                                }}></TextField>
                        </div>
                        <div className="ms-Grid-col ms-sm6 ms-md3 ms-lg3">
                            <TextField
                                id="resultado"
                                label="Resultado"
                                value={this.formatNumber(this.state.sim_info.RESULTADO_CONVERSION.toString())}
                                disabled={true}
                                onChange={(e) => {
                                    this.textFieldOnChange(e);
                                }}></TextField>
                        </div>
                        <div className="ms-Grid-col ms-sm6 ms-md3 ms-lg3">
                            <TextField
                                id="rentabilidad"
                                label="Rentabilidad (%)"
                                value={this.formatNumber(this.state.sim_info.RENTABILIDAD.toString().replace(".", ","))}
                                disabled={true}
                                onChange={(e) => {
                                    this.textFieldOnChange(e);
                                }}></TextField>
                        </div>
                        <br />
                        <p>Estos datos son extraídos desde el Resumen Economic de la última simulación aprobada.</p>
                    </div>
                </div>
            </div>
        );
    }

    public seccionResultadosLicitacion() {
        return (
            <div>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                            {this.state.spRelativeSite != "" ? (
                                <OrdenCompra
                                    disabled={this.state.gestionProyectoDisabled != undefined ? this.state.gestionProyectoDisabled : false}
                                    opoID={this.state.opoID}
                                    relativeUrl={this.state.spRelativeSite}
                                    tenantName={this.state.spTenant}
                                    library={"Documentos"}
                                    carpeta={"OC"}
                                />
                            ) : (
                                "Debe crear el workspace para adjuntar documentos"
                            )}
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                            <div></div>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg3">
                            <label className="labelResponsable">
                                Responsable del proyecto <span className="obligatorio">*</span>
                            </label>
                            <div className="mt10">
                                <AutoComplete
                                    disabled={this.state.gestionProyectoDisabled != undefined ? this.state.gestionProyectoDisabled : false}
                                    id="responsable2"
                                    callback={this.userHandler}
                                    multi={false}
                                    defaultValue={{
                                        name: this.state.op_info.RESPONSABLE_PROYECTO_DISPLAY,
                                        mail: this.state.op_info.RESPONSABLE_PROYECTO_LOGIN,
                                    }}
                                    tokenAPIGraph={this.state.tokenAPIGraph}
                                />
                            </div>
                        </div>

                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg9">
                            <div></div>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg3 mt-2">
                            <DatePicker
                                id="fechainicio"
                                className={controlClass.control}
                                firstDayOfWeek={DayOfWeek.Monday}
                                strings={DayPickerStrings}
                                placeholder="Fecha estimada inicio proyecto"
                                label="Fecha estimada inicio proyecto"
                                ariaLabel="Seleccione una fecha"
                                formatDate={this._onFormatDate}
                                value={this.state.op_info.FECHA_INICIO}
                                disabled={this.state.gestionProyectoDisabled}
                                onSelectDate={this._onSelectDateInicio}
                                isRequired={true}
                            />
                        </div>

                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                            <div></div>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg3"></div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg3">
                            <DatePicker
                                id="fechafin"
                                className={controlClass.control}
                                firstDayOfWeek={DayOfWeek.Monday}
                                strings={DayPickerStrings}
                                placeholder="Fecha estimada fin proyecto"
                                label="Fecha estimada fin proyecto"
                                ariaLabel="Seleccione una fecha"
                                formatDate={this._onFormatDate}
                                value={this.state.op_info.FECHA_TERMINO}
                                disabled={this.state.gestionProyectoDisabled}
                                onSelectDate={this._onSelectDateFin}
                                isRequired={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    public seccionLeccionesOportunidad() {
        return (
            <div>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg9">
                            <TextField
                                label="Lección aprendida de la oportunidad (perdida o ganada)"
                                value={this.state.op_info.LECCION_APRENDIDA}
                                id={"leccionaprendida"}
                                multiline
                                autoAdjustHeight
                                disabled={this.state.gestionProyectoDisabled}
                                onChange={(e) => {
                                    this.textFieldOnChange(e);
                                    this.contadorLeccion(e);
                                }}></TextField>
                            <label>Quedan {this.state.contadorLeccion} carácteres.</label>
                        </div>

                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg3">
                            <ComboBox
                                label="Motivos"
                                disabled={this.state.gestionProyectoDisabled}
                                autoComplete="on"
                                placeholder="Seleccionar..."
                                multiSelect={true}
                                onChange={this._onChangeMotivos}
                                options={this.state.opciones_motivos}
                                required={true}></ComboBox>
                        </div>

                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg9">
                            <TextField
                                label="Comentarios"
                                value={this.state.op_info.COMENTARIOS}
                                id={"comentarios"}
                                multiline
                                autoAdjustHeight
                                disabled={this.state.gestionProyectoDisabled}
                                onChange={(e) => {
                                    this.textFieldOnChange(e);
                                }}></TextField>
                        </div>
                    </div>
                    <br />
                </div>
            </div>
        );
    }

    render() {
        if (this.state.esAdmin || this.state.esGestion || this.state.esValidador) {
            return (
                <React.Fragment>
                    <BotonAyuda token={this.state.tokenAPI} />
                    <div className="fuente">
                        <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm6 ms-md5 ms-lg5">
                                    {
                                        <div className="iconoRuta">
                                            <Glyphicon glyph="glyphicon glyphicon-play" /> <span id="txtRuta">Inicio</span>
                                            &nbsp;
                                            <Glyphicon glyph="glyphicon glyphicon-play" /> <span id="txtRuta">Gestión de Oportunidad</span>
                                        </div>
                                    }
                                    {
                                        <div className="titulosimulador">
                                            <Glyphicon glyph="pushpin" /> Gestión de Oportunidad
                                            {/* <img className="izquierda" src={punteroTitulo} />Gestión de Oportunidad */}
                                        </div>
                                    }
                                </div>
                                <div className="ms-Grid-col ms-sm6 ms-md7 ms-lg7 d-flex justify-content-end">
                                    <div className="fijado">
                                        <DefaultButton
                                            text="Salir"
                                            onClick={() => {
                                                this.setState({ dialogoSalir: false });
                                            }}></DefaultButton>
                                        <PrimaryButton
                                            disabled={this.state.gestionProyectoDisabled}
                                            text="Guardar"
                                            onClick={this.guardarOP}></PrimaryButton>
                                        {this.state.op_info.EXTENSION === false ? (
                                            <PrimaryButton
                                                text="Copiar Oportunidad"
                                                onClick={() => {
                                                    this.setState({ dialogoCopiar: false });
                                                }}></PrimaryButton>
                                        ) : (
                                            ""
                                        )}
                                        {
                                            <PrimaryButton
                                                text="Simular presupuesto"
                                                onClick={() => {
                                                    this.validarCamposSimular();
                                                }}></PrimaryButton>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="pagina">
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm6 ms-md5 ms-lg5">
                                        {this.state.opoID && this.state.opoID > 0 ? (
                                            <Workspace
                                                callback={this.callbackWorkspace}
                                                nombre={this.state.op_info.NOMBRE ? this.state.op_info.NOMBRE : ""}
                                                id={this.state.opoID}
                                                colaboradores={[
                                                    ...this.state.op_info.COLABORADORES,
                                                    {
                                                        ID: 0,
                                                        MAIL: this.state.op_info.RESPONSABLE_LOGIN,
                                                        DISPLAYNAME: this.state.op_info.RESPONSABLE_DISPLAY,
                                                        ACTIVO: true,
                                                    },
                                                    {
                                                        ID: 0,
                                                        MAIL: this.state.op_info.RESPONSABLE_PROYECTO_LOGIN,
                                                        DISPLAYNAME: this.state.op_info.RESPONSABLE_PROYECTO_DISPLAY,
                                                        ACTIVO: true,
                                                    },
                                                ]}
                                            />
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div className="ms-Grid-col ms-sm6 ms-md1 ms-lg3"></div>

                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg2">
                                        <TextField
                                            label="N° Oportunidad"
                                            value={this.state.numero_extension}
                                            id={"oportunidad"}
                                            disabled={true}></TextField>
                                    </div>
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg2">
                                        <ComboBox
                                            label="Estado"
                                            allowFreeform={true}
                                            disabled={this.state.gestionProyectoDisabled}
                                            autoComplete="on"
                                            placeholder="Seleccionar..."
                                            selectedKey={this.state.op_info.ID_ESTADO}
                                            onChange={this._onChangeEstado}
                                            options={this.state.opciones_estado}></ComboBox>
                                    </div>
                                </div>
                            </div>

                            <div className="acordeon">
                                <Accordion
                                    title="Datos Generales de la Oportunidad"
                                    defaultCollapsed={this.state.collapsed}
                                    inicio={true}
                                    expandAll={this.state.collapsed}
                                    className="fondoTitAcordion"
                                    key={1}
                                    classFondo="fondoSeccion">
                                    <div className="itemContent">{this.seccionDatosGenerales()}</div>
                                </Accordion>
                            </div>

                            <div className="acordeon">
                                <Accordion
                                    title="Datos del Cliente"
                                    defaultCollapsed={this.state.collapsed}
                                    inicio={true}
                                    expandAll={this.state.collapsed}
                                    className="fondoTitAcordion"
                                    key={1}
                                    classFondo="fondoSeccion">
                                    <div className="itemContent">{this.seccionDatosCliente()}</div>
                                </Accordion>
                            </div>

                            <div className="acordeon">
                                <Accordion
                                    title="Propuesta"
                                    defaultCollapsed={this.state.collapsed}
                                    inicio={true}
                                    expandAll={this.state.collapsed}
                                    className="fondoTitAcordion"
                                    key={1}
                                    classFondo="fondoSeccion">
                                    <div className="itemContent">{this.seccionPropuesta()}</div>
                                </Accordion>
                            </div>

                            <div className="acordeon">
                                <Accordion
                                    title="Resultados de la Licitación / Cotización"
                                    defaultCollapsed={this.state.collapsed}
                                    inicio={true}
                                    expandAll={this.state.collapsed}
                                    className="fondoTitAcordion"
                                    key={1}
                                    classFondo="fondoSeccion">
                                    <div className="itemContent">{this.seccionResultadosLicitacion()}</div>
                                </Accordion>
                            </div>

                            <div className="acordeon">
                                <Accordion
                                    title="Lecciones de la Oportunidad"
                                    defaultCollapsed={this.state.collapsed}
                                    inicio={true}
                                    expandAll={this.state.collapsed}
                                    className="fondoTitAcordion"
                                    key={1}
                                    classFondo="fondoSeccion">
                                    <div className="itemContent">{this.seccionLeccionesOportunidad()}</div>
                                </Accordion>
                            </div>
                        </div>
                        {this.dialogoCopiar()}
                        {this.dialogoCrearGestionProyecto()}
                        {this.dialogoSalir()}
                        {this.dialogoGuardar()}
                        {this.dialogoGuardar2()}
                        {this.dialogoValidaciones()}
                        {this.dialogoEstInicioMayor()}
                        {this.dialogoEstFinMenor()}
                        {this.dialogoCopiado()}
                        {this.dialogoExtendida()}
                    </div>
                </React.Fragment>
            );
        } else if (this.state.esUsuario) {
            return (
                <React.Fragment>
                    <div className="mt-4 card sinAcceso text-primary">
                        <div className="card-header">Atención</div>
                        <div className="card-body">
                            <h6>
                                <Glyphicon glyph="info-sign" className="mr-1" /> Usted no posee los permisos para visualizar este formulario, si desea
                                obtener los permisos, contactese con el administrador del sistema.
                            </h6>
                        </div>
                    </div>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <div className="mt-4 card sinAcceso text-primary"></div>
                </React.Fragment>
            );
        }
    }

    //DIALOGOS
    public dialogoCopiar() {
        return (
            <Dialog
                hidden={this.state.dialogoCopiar}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                }}
                minWidth={300}
                maxWidth={500}
                modalProps={{
                    isBlocking: true,
                }}>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="tituloCopia">
                            <h2>Copiar Oportunidad</h2>
                        </div>
                        <div className="copiaoportunidad">
                            <ChoiceGroup
                                id="tipoPersona"
                                disabled={false}
                                className="defaultChoiceGroup"
                                defaultSelectedKey={"0"}
                                options={[
                                    { key: "1", text: "Nueva oportunidad" },
                                    { key: "2", text: "Extensión de oportunidad" },
                                ]}
                                onChange={(e, i) => this.seleccionCopia(e, i)}
                                required={false}
                            />
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12"></div>
                    </div>
                </div>
                <DialogFooter>
                    <DefaultButton
                        text="Cerrar"
                        onClick={() => {
                            this.setState({ dialogoCopiar: true });
                        }}>
                        {" "}
                    </DefaultButton>
                    {this.state.opciones_copia === 1 ? (
                        <PrimaryButton
                            text="Copiar"
                            id="aceptarbtn"
                            onClick={(e) => {
                                this.setState({ dialogoCopiar: true });
                                this.copiarOportunidad(this.state.op_info.ID!);
                            }}></PrimaryButton>
                    ) : this.state.opciones_copia === 2 ? (
                        <PrimaryButton
                            text="Extender"
                            id="aceptarbtn"
                            onClick={(e) => {
                                this.setState({ dialogoCopiar: true });
                                this.extenderOportunidad(this.state.op_info.ID!);
                            }}></PrimaryButton>
                    ) : (
                        ""
                    )}
                </DialogFooter>
            </Dialog>
        );
    }

    public dialogoValidaciones() {
        return (
            <Dialog
                hidden={this.state.dialogoValidar}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                }}
                minWidth={300}
                maxWidth={1000}
                modalProps={{
                    isBlocking: true,
                }}>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="tituloCopia">
                            <h2>Datos obligatorios</h2>
                        </div>
                        {this.state.hasSim === false &&
                        (this.state.dialogoValidarGanada === false || this.state.dialogoValidarPropuesta === false) ? (
                            <div className="textosValidar">
                                <label>Debe poseer al menos una simulación aprobada</label>
                            </div>
                        ) : (
                            ""
                        )}
                        {(this.state.op_info.NOMBRE === undefined || this.state.op_info.NOMBRE === "") &&
                        (this.state.dialogoValidarGanada === false ||
                            this.state.dialogoValidarPreparando === false ||
                            this.state.dialogoValidarPropuesta === false ||
                            this.state.dialogoValidarSim === false) ? (
                            <div className="textosValidar">
                                <label>Debe ingresar un Nombre de Oportunidad</label>
                            </div>
                        ) : (
                            ""
                        )}
                        {(this.state.op_info.ID_UNIDAD === undefined || this.state.op_info.ID_UNIDAD === 0) &&
                        (this.state.dialogoValidarGanada === false ||
                            this.state.dialogoValidarPreparando === false ||
                            this.state.dialogoValidarPropuesta === false ||
                            this.state.dialogoValidarSim === false) ? (
                            <div className="textosValidar">
                                <label>Debe seleccionar Unidad de Negocio</label>
                            </div>
                        ) : (
                            ""
                        )}
                        {(this.state.op_info.RESPONSABLE_DISPLAY === undefined || this.state.op_info.RESPONSABLE_DISPLAY === "") &&
                        (this.state.dialogoValidarGanada === false ||
                            this.state.dialogoValidarPropuesta === false ||
                            this.state.dialogoValidarPreparando === false) ? (
                            <div className="textosValidar">
                                <label>Debe seleccionar Responsable Oportunidad</label>
                            </div>
                        ) : (
                            ""
                        )}
                        {(this.state.op_info.DESCRIPCION === undefined || this.state.op_info.DESCRIPCION === "") &&
                        (this.state.dialogoValidarGanada === false ||
                            this.state.dialogoValidarPreparando === false ||
                            this.state.dialogoValidarPropuesta === false) ? (
                            <div className="textosValidar">
                                <label>Debe ingresar una Descripción</label>
                            </div>
                        ) : (
                            ""
                        )}
                        {(this.state.op_info.ID_LINEA === undefined || this.state.op_info.ID_LINEA === 0) &&
                        (this.state.dialogoValidarGanada === false || this.state.dialogoValidarPropuesta === false) ? (
                            <div className="textosValidar">
                                <label>Debe seleccionar Área de negocio</label>
                            </div>
                        ) : (
                            ""
                        )}
                        {(this.state.op_info.ID_SUBLINEA === undefined || this.state.op_info.ID_SUBLINEA === 0) &&
                        (this.state.dialogoValidarGanada === false || this.state.dialogoValidarPropuesta === false) ? (
                            <div className="textosValidar">
                                <label>Debe seleccionar Línea</label>
                            </div>
                        ) : (
                            ""
                        )}
                        {(this.state.op_info.ID_CONTRATO_MARCO === undefined || this.state.op_info.ID_CONTRATO_MARCO) === 0 &&
                        (this.state.dialogoValidarGanada === false || this.state.dialogoValidarSim === false) ? (
                            <div className="textosValidar">
                                <label>Debe seleccionar Contrato Marco</label>
                            </div>
                        ) : (
                            ""
                        )}
                        {(this.state.op_info.FECHA_INICIO === undefined || this.state.op_info.FECHA_INICIO === null) &&
                        (this.state.dialogoValidarGanada === false || this.state.dialogoValidarSim === false) ? (
                            <div className="textosValidar">
                                <label>Debe seleccionar una Fecha de Inicio</label>
                            </div>
                        ) : (
                            ""
                        )}
                        {(this.state.op_info.FECHA_TERMINO === undefined || this.state.op_info.FECHA_TERMINO === null) &&
                        (this.state.dialogoValidarGanada === false || this.state.dialogoValidarSim === false) ? (
                            <div className="textosValidar">
                                <label>Debe seleccionar una Fecha de Término</label>
                            </div>
                        ) : (
                            ""
                        )}
                        {(this.state.op_info.PROBABILIDAD === undefined || this.state.op_info.PROBABILIDAD === 0) &&
                        (this.state.dialogoValidarGanada === false || this.state.dialogoValidarPropuesta === false) ? (
                            <div className="textosValidar">
                                <label>Debe seleccionar la Probabilidad</label>
                            </div>
                        ) : (
                            ""
                        )}
                        {/* PRINCIPALES ELEMENTOS DEL SERVICIO */}
                        {(this.state.op_info.ID_CLIENTE === undefined || this.state.op_info.ID_CLIENTE === 0) &&
                        (this.state.dialogoValidarGanada === false ||
                            this.state.dialogoValidarPreparando === false ||
                            this.state.dialogoValidarPropuesta === false) ? (
                            <div className="textosValidar">
                                <label>Debe seleccionar un Cliente</label>
                            </div>
                        ) : (
                            ""
                        )}
                        {(this.state.op_info.ID_PAIS === undefined || this.state.op_info.ID_PAIS === 0) &&
                        (this.state.dialogoValidarGanada === false ||
                            this.state.dialogoValidarPreparando === false ||
                            this.state.dialogoValidarPropuesta === false) ? (
                            <div className="textosValidar">
                                <label>Debe seleccionar un País</label>
                            </div>
                        ) : (
                            ""
                        )}
                        {(this.state.op_info.ID_OFICINA === undefined || this.state.op_info.ID_OFICINA === 0) &&
                        (this.state.dialogoValidarGanada === false || this.state.dialogoValidarPropuesta === false) ? (
                            <div className="textosValidar">
                                <label>Debe seleccionar Oficina</label>
                            </div>
                        ) : (
                            ""
                        )}
                        {(this.state.op_info.NOMBRE_CONTACTO === undefined || this.state.op_info.NOMBRE_CONTACTO === "") &&
                        this.state.dialogoValidarGanada === false ? (
                            <div className="textosValidar">
                                <label>Debe ingresar un Nombre de Contacto</label>
                            </div>
                        ) : (
                            ""
                        )}
                        {(this.state.op_info.MAIL === undefined || this.state.op_info.MAIL === "") && 
                        this.state.dialogoValidarGanada === false ? (
                            <div className="textosValidar">
                                <label>Debe ingresar un Mail</label>
                            </div>
                        ) : (
                            ""
                        )}
                        {this.state.op_info.AREA_CLIENTE === undefined ||
                        this.state.op_info.AREA_CLIENTE.length === 0 ||
                        this.state.op_info.AREA_CLIENTE === null ? (
                            <div className="textosValidar">
                                <label>Debe ingresar un Área de cliente</label>
                            </div>
                        ) : (
                            ""
                        )}
                        {this.state.op_info.COMPRADORES === undefined ||
                        this.state.op_info.COMPRADORES.length === 0 ||
                        this.state.op_info.COMPRADORES === null ? (
                            <div className="textosValidar">
                                <label>Debe ingresar un Comprador</label>
                            </div>
                        ) : (
                            ""
                        )}
                        {this.state.op_info.TELEFONO === undefined ||
                        this.state.op_info.TELEFONO <= 0 ||
                        this.state.op_info.TELEFONO === null ? (
                            <div className="textosValidar">
                                <label>Debe ingresar un Teléfono</label>
                            </div>
                        ) : (
                            ""
                        )}
                        {(this.state.totalAdjuntosPropuesta === undefined ||
                            this.state.totalAdjuntosPropuesta === 0 ||
                            this.state.totalAdjuntosPropuesta === null) &&
                        this.state.dialogoValidarExtendida === false ? (
                            <div className="textosValidar">
                                <label>Debe ingresar al menos una Propuesta</label>
                            </div>
                        ) : (
                            ""
                        )}
                        {(this.state.cantOC === undefined || this.state.cantOC === 0 || this.state.cantOC === null) &&
                        this.state.dialogoValidarGanada === false ? (
                            <div className="textosValidar">
                                <label>Debe ingresar al menos una Orden de Compra</label>
                            </div>
                        ) : (
                            ""
                        )}
                        {this.state.op_info.COLABORADORES.length === 0 && this.state.dialogoValidarGanada === false ? (
                            <div className="textosValidar">
                                <label>Debe ingresar al menos un colaborador</label>
                            </div>
                        ) : (
                            ""
                        )}
                        {(this.state.op_info.MAIL != undefined || this.state.op_info.MAIL != "") &&
                        this.state.validarCorreo === false &&
                        this.state.dialogoValidarGanada === false ? (
                            <div className="textosValidar">
                                <label>Formato de mail incorrecto</label>
                            </div>
                        ) : (
                            ""
                        )}

                        {(this.state.op_info.FECHA_ESTIMADA_INICIO === undefined || this.state.op_info.FECHA_ESTIMADA_INICIO === null) &&
                        this.state.dialogoValidarGanada === false ? (
                            <div className="textosValidar">
                                <label>Debe seleccionar una Fecha Estimada de Inicio</label>
                            </div>
                        ) : (
                            ""
                        )}
                        {(this.state.op_info.FECHA_ESTIMADA_FIN === undefined || this.state.op_info.FECHA_ESTIMADA_FIN === null) &&
                        this.state.dialogoValidarGanada === false ? (
                            <div className="textosValidar">
                                <label>Debe seleccionar una Fecha Estimada de Fin</label>
                            </div>
                        ) : (
                            ""
                        )}
                        {(this.state.op_info.FECHA_ENVIO_PROPUESTA === undefined || this.state.op_info.FECHA_ENVIO_PROPUESTA === null) &&
                        this.state.dialogoValidarGanada === false ? (
                            <div className="textosValidar">
                                <label>Debe seleccionar una Fecha de Envío de Propuesta</label>
                            </div>
                        ) : (
                            ""
                        )}
                        {this.state.op_info.MOTIVOS.find((e) => e.selected === true) === undefined && this.state.dialogoValidarGanada === false ? (
                            <div className="textosValidar">
                                <label>Debe seleccionar al menos un Motivo</label>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
                <DialogFooter>
                    <PrimaryButton
                        text="Cerrar"
                        onClick={() => {
                            this.setState({
                                dialogoValidar: true,
                                dialogoValidarSim: true,
                                dialogoValidarGanada: true,
                                dialogoValidarPreparando: true,
                                dialogoValidarPropuesta: true,
                            });
                        }}>
                        {" "}
                    </PrimaryButton>
                </DialogFooter>
            </Dialog>
        );
    }

    public dialogoSalir() {
        return (
            <Dialog
                hidden={this.state.dialogoSalir}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Salir",
                    subText: "¿Está seguro que desea salir? Los cambios efectuados no serán guardados",
                }}
                modalProps={{
                    styles: { main: { maxWidth: 450 } },
                }}>
                <DialogFooter>
                    <PrimaryButton
                        text="Salir"
                        onClick={() => {
                            this.setState({ dialogoSalir: true });
                            this.salirPT();
                        }}></PrimaryButton>
                    <PrimaryButton text="Guardar" onClick={this.guardarOP}></PrimaryButton>
                    <DefaultButton
                        text="Cancelar"
                        onClick={() => {
                            this.setState({ dialogoSalir: true });
                        }}></DefaultButton>
                </DialogFooter>
            </Dialog>
        );
    }

    public dialogoEstInicioMayor() {
        return (
            <Dialog
                hidden={this.state.estimada_inicio_mayor}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Fecha incorrecta",
                    subText: "La fecha de inicio no puede ser superior a la de término",
                }}
                modalProps={{
                    styles: { main: { maxWidth: 450 } },
                }}>
                <DialogFooter>
                    <PrimaryButton
                        text="Aceptar"
                        onClick={() => {
                            this.setState({ estimada_inicio_mayor: true });
                        }}></PrimaryButton>
                </DialogFooter>
            </Dialog>
        );
    }

    public dialogoEstFinMenor() {
        return (
            <Dialog
                hidden={this.state.estimada_fin_menor}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Fecha incorrecta",
                    subText: "La fecha de fin no puede ser inferior a la de inicio",
                }}
                modalProps={{
                    styles: { main: { maxWidth: 450 } },
                }}>
                <DialogFooter>
                    <PrimaryButton
                        text="Aceptar"
                        onClick={() => {
                            this.setState({ estimada_fin_menor: true });
                        }}></PrimaryButton>
                </DialogFooter>
            </Dialog>
        );
    }

    public dialogoCopiado() {
        return (
            <Dialog
                hidden={this.state.dialogo_copia}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Oportunidad copiada con exito",
                    subText: "¿Quiere dirigirse a la nueva oportunidad?",
                }}
                modalProps={{
                    styles: { main: { maxWidth: 450 } },
                }}>
                <DialogFooter>
                    <PrimaryButton
                        text="Si"
                        onClick={() => {
                            window.location.href = `/oportunidad/${this.state.id_copia}`;
                            this.setState({ dialogo_copia: true });
                        }}></PrimaryButton>
                    <DefaultButton
                        text="No"
                        onClick={() => {
                            this.setState({ dialogo_copia: true });
                        }}></DefaultButton>
                </DialogFooter>
            </Dialog>
        );
    }
    public dialogoCrearGestionProyecto() {
        return (
            <Dialog
                hidden={this.state.dialogo_proyecto}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Proyecto creado con éxito",
                    subText: "¿Quiere dirigirse al nuevo Proyecto?",
                }}
                modalProps={{
                    styles: { main: { maxWidth: 450 } },
                }}>
                <DialogFooter>
                    <PrimaryButton
                        text="Si"
                        onClick={() => {
                            window.location.href = `/gestionproyecto/${this.state.id_proyecto}`;
                            this.setState({ dialogoGuardar2: false, dialogoGuardar: false, dialogo_proyecto: true });
                        }}></PrimaryButton>
                    <DefaultButton
                        text="No"
                        onClick={() => {
                            this.setState({ dialogo_proyecto: true });
                        }}></DefaultButton>
                </DialogFooter>
            </Dialog>
        );
    }

    public dialogoExtendida() {
        return (
            <Dialog
                hidden={this.state.dialogo_extencion}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Oportunidad extendida con éxito",
                    subText: "¿Quiere dirigirse a la extensión creada?",
                }}
                modalProps={{
                    styles: { main: { maxWidth: 450 } },
                }}>
                <DialogFooter>
                    <PrimaryButton
                        text="Si"
                        onClick={() => {
                            window.location.href = `/oportunidad/${this.state.id_copia}`;
                            this.setState({ dialogo_extencion: true });
                        }}></PrimaryButton>
                    <DefaultButton
                        text="No"
                        onClick={() => {
                            this.setState({ dialogo_extencion: true });
                        }}></DefaultButton>
                </DialogFooter>
            </Dialog>
        );
    }

    public dialogoGuardar() {
        return (
            <Dialog
                hidden={this.state.dialogoGuardar}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Guardando",
                    subText: "Espere mientra se guardan los cambios..",
                }}
                modalProps={{
                    isBlocking: true,
                    styles: { main: { maxWidth: 450 } },
                }}></Dialog>
        );
    }

    public dialogoGuardar2() {
        return (
            <Dialog
                hidden={this.state.dialogoGuardar2}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Oportunidad guardada",
                    subText: "Oportunidad guardada con éxito",
                }}
                modalProps={{
                    styles: { main: { maxWidth: 450 } },
                }}>
                <DialogFooter>
                    <PrimaryButton
                        text="Cerrar"
                        onClick={() => {
                            this.setState({ dialogoGuardar2: true });
                        }}></PrimaryButton>
                </DialogFooter>
            </Dialog>
        );
    }

    public dialogoGuardarError() {
        return (
            <Dialog
                hidden={this.state.dialogoGuardarError}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Error al guardar",
                    subText: "Error al guardar la oportunidad",
                }}
                modalProps={{
                    styles: { main: { maxWidth: 450 } },
                }}>
                <DialogFooter>
                    <PrimaryButton
                        text="Cerrar"
                        onClick={() => {
                            this.setState({ dialogoGuardarError: true });
                        }}></PrimaryButton>
                </DialogFooter>
            </Dialog>
        );
    }
}

export default Oportunidad;
