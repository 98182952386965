import React, { useState } from "react";
import { Label } from "office-ui-fabric-react";
import { DefaultButton, PrimaryButton } from "office-ui-fabric-react/lib/Button";
import { Dialog, DialogType, DialogFooter } from "office-ui-fabric-react/lib/Dialog";
import { GestionProyectoEntity } from "../../controles/entidades/GestionProyecto";
import { TextField } from "office-ui-fabric-react/lib/TextField";
import { IDatePickerStrings, DatePicker, DayOfWeek } from "office-ui-fabric-react/lib/DatePicker";
import { ChoiceGroup } from "office-ui-fabric-react/lib/ChoiceGroup";
import { ComboBox } from "office-ui-fabric-react";
import { Checkbox } from "office-ui-fabric-react/lib/Checkbox";
import { mergeStyleSets } from "office-ui-fabric-react/lib/Styling";
import { Slider } from "office-ui-fabric-react/lib/Slider";
import { Accordion } from "../../controles/Accordion/Accordion";
import { Glyphicon } from "react-bootstrap";
import "./GestionProyecto.css";
import history from "../../history";
import { fetchEngine } from "../../fetchData";
import axios from "axios";
import { authProvider } from "../../authProvider";
import "../../custom.css";
import DragNDropFile from "../../controles/DragNDrop/DragNDropFile";
import OrdenCompra from "../../controles/OrdenCompra/OrdenCompra";
import Workspace from "../../controles/Workspace/Workspace";
import AutoComplete from "../../controles/AutoComplete/AutoComplete";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import { Planificado } from "../../controles/entidades/Planificacion";
initializeIcons();

const DayPickerStrings: IDatePickerStrings = {
    months: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
    shortMonths: ["En", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
    days: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
    shortDays: ["D", "L", "M", "M", "J", "V", "S"],
    goToToday: "Ir a hoy",
    prevMonthAriaLabel: "Ir al mes previo",
    nextMonthAriaLabel: "Ir al mes siguiente",
    prevYearAriaLabel: "Ir al año anterior",
    nextYearAriaLabel: "Ir al año siguiente",
    closeButtonAriaLabel: "Cerrar el selector de fechas",
};

const controlClass = mergeStyleSets({
    control: {
        margin: "0 0 15px 0",
        maxWidth: "300px",
    },
});

export class GestionProyectoProps {
    titulo?: string;
}

export class GestionProyectoState {
    op_info!: GestionProyectoEntity;
    estadodelformulario?: boolean;
    titulo_state?: string;
    fechacierre?: Date;

    opciones_linea?: any;
    opciones_grupo_cliente?: any;
    opciones_cliente?: any;
    opciones_cliente_aux?: any;
    opciones_oportunidad?: any;
    opciones_proyectos?: any;
    opciones_competidores?: any;
    opciones_contrato_marco?: any;
    opciones_contrato_marco_aux?: any;
    opciones_estado?: any;
    opciones_estado_proy?: any;
    opciones_modulos?: any;
    opciones_monedas?: any;
    opciones_monedas_aux?: any;
    opciones_motivos?: any;
    opciones_oficina?: any;
    opciones_unidad_negocio?: any;
    opciones_pais?: any;
    opciones_elementos_servicio?: any;
    opciones_sublineas?: any;
    opciones_sublineas_aux?: any;
    opciones_valorizacion?: any;

    dialogoSalir?: boolean;
    dialogoGuardar?: boolean;
    dialogoGuardar2?: boolean;
    dialogoGuardarError?: boolean;

    //Validaciones
    dialogoValidar?: boolean;
    dialogoValidarSim?: boolean;
    dialogoValidarPreparando?: boolean;
    dialogoValidarPropuesta?: boolean;
    dialogoValidarGanada?: boolean;

    numero_orden?: any;
    monto_orden?: any;

    spTenant: string = "";
    spRelativeSite: string = "";
    probabilidad?: any;
    collapsed?: boolean;
    color_fondo_titulo?: string;
    color_fondo_seccion?: string;
    isCollapsedAll?: string;
    estado?: string;
    unidad_negocio?: string;
    dialogoCopiar?: boolean;
    hasID?: boolean;
    opoID: number = 0;
    opoID2: number = 0;
    adjuntos?: any[];
    moneda?: string;

    esAdmin?: boolean;
    esUsuario?: boolean;
    esGestion?: boolean;
    esValidador?: boolean;
    esContable?: boolean;

    estado_prev?: number;
    estimada_inicio_mayor?: boolean;
    estimada_fin_menor?: boolean;
    validarCorreo?: boolean;
    dialogo_copia?: boolean;
    dialogo_extencion?: boolean;
    id_copia?: number;
    opciones_copia?: number;

    contadorDescripcion: number = 0;
    contadorLeccion: number = 0;
    contadorComentarios: number = 0;
    contadorOportunidadNegocio: number = 0;
    contadorObjetivo: number = 0;
    contadorEntregables: number = 0;
    contadorAlcance: number = 0;
    contadorSolucion: number = 0;
    contadorBeneficios: number = 0;

    numero_extension: string = "";
    disabledInicial?: boolean;
    esNewProyect?: boolean;
    copiada?: boolean;
    planificacion: boolean = false;
    id_proyecto: number = 0;

    plan_info!: Planificado;
    clientID: string = "";

    library: string = "";
    tokenAPI: string = "";
    tokenAPIGraph: string = "";
    tokenAPISharePoint: string = "";
    tokenAPIGraphSites: string = "";
}
export class GestionProyecto extends React.Component<GestionProyectoProps, GestionProyectoState> {
    constructor(props: any) {
        super(props);

        let pathName = window.location.pathname;
        let params = pathName.split("/");
        let hasID = params.length > 2;

        this.state = {
            op_info: {
                NOMBRE: "",
                DESCRIPCION: "",
                ID: 0,
                ID_ESTADO: 1,
                ID_ESTADO_PROY: 0,
                ID_UNIDAD: 0,
                RESPONSABLE_LOGIN: "",
                RESPONSABLE_DISPLAY: "",
                JEFE_PROYECTO_DISPLAY: "",
                JEFE_PROYECTO_LOGIN: "",
                RESPONSABLE_GASTOS_DISPLAY: "",
                RESPONSABLE_GASTOS_LOGIN: "",
                RESPONSABLE_HORAS_DISPLAY: "",
                RESPONSABLE_HORAS_LOGIN: "",
                ASISTENTE_DISPLAY: "",
                ASISTENTE_LOGIN: "",
                ID_LINEA: 0,
                ID_SUBLINEA: 0,
                PROBABILIDAD: 0,
                FECHA_APERTURA: undefined,
                FECHA_ENVIO_PROPUESTA: undefined,
                ID_GRUPO_CLIENTE: 0,
                ID_CLIENTE: 0,
                COMPRADORES: "",
                AREA_CLIENTE: "",
                ID_PAIS: 0,
                ID_OFICINA: 0,
                NOMBRE_CONTACTO: "",
                MONTO_ESTIMADO: 0,
                ID_CONTRATO_MARCO: 0,
                RENTABILIDAD_ESPERADA: 0,
                ID_VALORIZACION: 0,
                MONTO_ORDEN_COMPRA: 0,
                RESPONSABLE_PROYECTO_LOGIN: "",
                RESPONSABLE_PROYECTO_DISPLAY: "",
                LICITACION: false,
                REQUIERE_ACREDITACION: false,
                FECHA_ESTIMADA_INICIO: undefined,
                FECHA_ESTIMADA_FIN: undefined,
                ID_MONEDA: 0,
                LECCION_APRENDIDA: "",
                COMENTARIOS: "",
                ID_MOTIVOS: 0,
                PRINCIPALES_ELEMENTOS: [],
                MOTIVOS: [],
                COMPETIDORES: [],
                COLABORADORES: [],
                MAIL: "",
                TELEFONO: 0,
                FECHA_INICIO: undefined,
                FECHA_TERMINO: undefined,
                INGRESO_VENTA: 0,
                COSTO_VENTA: 0,
                RESULTADO: 0,
                RENTABILIDAD: 0,
                ID_ORIGINAL: 0,
                ID_EXTENSION: 0,
                EXTENSION: false,
                OPORTUNIDAD_NEGOCIO: "",
                OBJETIVO_PROYECTO: "",
                ENTREGABLES: "",
                ALCANCE: "",
                SOLUCION: "",
                BENEFICIOS: "",
                token: "",
            },
            //Opciones Dropdown
            opciones_linea: [],
            opciones_competidores: [],
            opciones_contrato_marco: [],
            opciones_contrato_marco_aux: [],
            opciones_modulos: [],
            opciones_monedas: [],
            opciones_monedas_aux: [],
            opciones_motivos: [],
            opciones_oficina: [],
            opciones_pais: [],
            opciones_estado: [],
            opciones_estado_proy: [],
            opciones_grupo_cliente: [],
            opciones_cliente: [],
            opciones_cliente_aux: [],
            opciones_oportunidad: [],
            opciones_proyectos: [],
            opciones_elementos_servicio: [],
            opciones_sublineas: [],
            opciones_sublineas_aux: [],
            opciones_unidad_negocio: [],
            opciones_valorizacion: [],
            spRelativeSite: "",
            //STATE
            collapsed: true,
            color_fondo_titulo: "",
            color_fondo_seccion: "",
            isCollapsedAll: "",
            estadodelformulario: false,
            dialogoCopiar: true,
            dialogoSalir: true,
            dialogoGuardar: true,
            dialogoGuardar2: true,
            dialogoGuardarError: true,

            //Validaciones
            dialogoValidar: true,
            dialogoValidarPreparando: true,
            dialogoValidarPropuesta: true,
            dialogoValidarGanada: true,
            estimada_inicio_mayor: true,
            estimada_fin_menor: true,
            dialogoValidarSim: true,

            moneda: "",
            numero_orden: 0,
            monto_orden: 0,
            hasID: false,
            opoID: hasID ? parseInt(params[2]) : 0,
            opoID2: 0,
            adjuntos: [],
            spTenant: fetchEngine.spTenant,
            //FUTURA ENTIDAD
            probabilidad: 0,
            estado: "",
            fechacierre: undefined,
            unidad_negocio: "",
            estado_prev: 0,
            validarCorreo: true,
            id_copia: 0,
            dialogo_copia: true,
            dialogo_extencion: true,
            opciones_copia: 0,
            contadorDescripcion: 250,
            contadorLeccion: 250,
            contadorComentarios: 250,
            contadorOportunidadNegocio: 250,
            contadorObjetivo: 250,
            contadorEntregables: 250,
            contadorAlcance: 250,
            contadorSolucion: 250,
            contadorBeneficios: 250,
            numero_extension: "",
            disabledInicial: true,
            esNewProyect: false,
            copiada: false,
            planificacion: false,
            id_proyecto: 0,
            esAdmin: false,
            esUsuario: false,
            esGestion: false,
            esValidador: false,
            esContable: false,

            plan_info: {
                ID: 0,
                ID_PROYECTO: 0,
                ID_CLIENTE: 0,
                TIPO_MODULO: "Planificado",
                FECHA_INICIO: new Date(Date.now()),
                FECHA_TERMINO: new Date(Date.now()),
                CREADO_POR: "",
                MODIFICADO_POR: "",
                INGRESOS_DIRECTOS: [],
                ID_OPORTUNIDAD: 0,
                ID_ESTADO: 0,
                ID_UNIDAD: 0,
                INGRESO_VENTA_VENTA: 0,
                COSTO_VENTA_VENTA: 0,
                RESULTADO_VENTA: 0,
                RENTABILIDAD_VENTA: 0,
                INGRESO_VENTA_PLAN: 0,
                COSTO_VENTA_PLAN: 0,
                RESULTADO_PLAN: 0,
                RENTABILIDAD_PLAN: 0,
                INGRESO_VENTA_FORECAST: 0,
                COSTO_VENTA_FORECAST: 0,
                RESULTADO_FORECAST: 0,
                RENTABILIDAD_FORECAST: 0,
                MONEDA_CTTO: "",
            },

            clientID: process.env.REACT_APP_SCOPE_API ? process.env.REACT_APP_SCOPE_API : "",

            library: process.env.REACT_APP_LIBRARY ? process.env.REACT_APP_LIBRARY : "",
            tokenAPI: "",
            tokenAPIGraph: "",
            tokenAPISharePoint: "",
            tokenAPIGraphSites: "",
        };

        this.procesar();
        this.textFieldOnChange = this.textFieldOnChange.bind(this);
        this._onFormatDate = this._onFormatDate.bind(this);
        this._onCheckChange = this._onCheckChange.bind(this);
        this.ChangeProbabilidad = this.ChangeProbabilidad.bind(this);
        this.redirigirSimulacion = this.redirigirSimulacion.bind(this);
        this.redirigirPlanificacion = this.redirigirPlanificacion.bind(this);
        this.callbackWorkspace = this.callbackWorkspace.bind(this);
        this.cambiarestadoformulario = this.cambiarestadoformulario.bind(this);
        this.userHandler = this.userHandler.bind(this);
        this.guardarOP = this.guardarOP.bind(this);
        this.manejoOC = this.manejoOC.bind(this);
        this.validarCamposPlan = this.validarCamposPlan.bind(this);
    }

    componentDidMount() {
        if (authProvider != undefined) {
            new Promise<any>(async (resolve, reject) => {
                const tokens = await fetchEngine.getStorageValue(authProvider);
                resolve(tokens);
            }).then((d) => {
                this.setState(
                    {
                        tokenAPI: d.tokenAPI,
                        tokenAPIGraph: d.tokenAPIGraph,
                        tokenAPISharePoint: d.tokenAPISharePoint,
                        tokenAPIGraphSites: d.tokenAPIGraphSites,
                    },
                    () => {
                        this.verificarAdmin();
                        this.verificarUsuario();
                        this.verificarGestion();
                        this.verificarValidador();
                        this.verificarContable();
                        this.obtenerProyectos();
                        this.obtenerValores();
                    }
                );
            });
        }
    }

    //CONSULTAS PERFILAMIENTO
    public verificarAdmin = async () => {
        fetchEngine
            .getAPI(`/api/Perfiles/GetAdmin`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                this.setState({ esAdmin: result.ok });
            });
    };

    public verificarUsuario = async () => {
        fetchEngine
            .getAPI(`/api/Perfiles/GetUsuario`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                this.setState({ esUsuario: result.ok });
            });
    };

    public verificarGestion = async () => {
        fetchEngine
            .getAPI(`/api/Perfiles/GetGestion`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                this.setState({ esGestion: result.ok });
            });
    };

    public verificarValidador = async () => {
        fetchEngine
            .getAPI(`/api/Perfiles/GetValidador`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                this.setState({ esValidador: result.ok });
            });
    };

    public verificarContable = async () => {
        fetchEngine
            .getAPI(`/api/Perfiles/GetContable`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                this.setState({ esContable: result.ok });
            });
    };

    private _onFormatDate = (date: Date | undefined): string => {
        if (date != undefined) {
            return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
        } else {
            return "01-01-0001";
        }
    };

    public cargarHerencia() {
        if (this.state.op_info.ID_LINEA != undefined && this.state.op_info.ID_LINEA > 0) {
            let opciones_sublineas2: any[] = [];
            this.state.opciones_sublineas_aux.map((a: any) => {
                if (a.id_linea === this.state.op_info.ID_LINEA) {
                    opciones_sublineas2.push({
                        key: a.key,
                        text: a.text,
                        activo: a.activo,
                        disabled: !a.activo,
                    });
                }
            });
            this.setState({ opciones_sublineas: opciones_sublineas2 });
        }

        if (this.state.op_info.ID_GRUPO_CLIENTE != undefined && this.state.op_info.ID_GRUPO_CLIENTE > 0) {
            let opciones_grupo_clientes: any[] = [];
            this.state.opciones_cliente_aux.map((a: any) => {
                if (a.id_grupo_cliente === this.state.op_info.ID_GRUPO_CLIENTE) {
                    opciones_grupo_clientes.push({
                        key: a.key,
                        text: a.text,
                        activo: a.activo,
                        disabled: !a.activo,
                    });
                }
            });
            this.setState({ opciones_cliente: opciones_grupo_clientes });
        }

        if (this.state.op_info.ID_UNIDAD != undefined && this.state.op_info.ID_UNIDAD > 0) {
            let opciones_contratomarco: any[] = [];

            this.state.opciones_contrato_marco_aux.map((a: any) => {
                if (a.id_unidad === this.state.op_info.ID_UNIDAD) {
                    opciones_contratomarco.push({
                        key: a.key,
                        text: a.text,
                        id_moneda: a.id_moneda,
                        activo: a.activo,
                        disabled: !a.activo,
                    });
                }
            });

            let opciones_moneda: any[] = [];
            opciones_contratomarco.map((b: any) => {
                this.state.opciones_monedas_aux.map((a: any) => {
                    if (a.key === b.id_moneda) {
                        opciones_moneda.push({
                            key: a.key,
                            text: a.text,
                            activo: a.activo,
                            disabled: !a.activo,
                        });
                    }
                });
            });
            this.setState({
                opciones_contrato_marco: opciones_contratomarco,
                opciones_monedas: opciones_moneda,
                moneda: opciones_moneda[0].text,
            });
        }
    }

    public async obtenerProyectos() {
        let userName: any;
        if (authProvider.getAccountInfo() != null) {
            userName = authProvider.getAccountInfo() ? authProvider.getAccountInfo() : {};
        }

        fetchEngine
            .getAPI(`/api/GestionProyecto/GetByMeProyectos/${userName.account.userName}`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                const list: any[] = [];
                if (result.data) {
                    {
                        result.data.listado.map((a: any) => {
                            list.push({
                                key: a.ID,
                                text: decodeURIComponent(a.NOMBRE),
                            });
                        });
                    }
                }
                this.setState({
                    opciones_proyectos: list,
                });
            });
    }

    private async obtenerValores() {
        fetchEngine
            .getAPI("/api/DatosFormulario", {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                if (result.ok) {
                    const clt: any[] = [];
                    const cmp: any[] = [];
                    const ctm: any[] = [];
                    const est: any[] = [];
                    const lin: any[] = [];
                    const mdl: any[] = [];
                    const mnd: any[] = [];
                    const mtv: any[] = [];
                    const ofc: any[] = [];
                    const pais: any[] = [];
                    const pels: any[] = [];
                    const subl: any[] = [];
                    const unn: any[] = [];
                    const vlr: any[] = [];
                    const grp_clt: any[] = [];
                    if (result.data) {
                        result.data.estados.map((a: any) => {
                            est.push({
                                key: a.ID,
                                text: decodeURIComponent(a.TITULO),
                                id_modulo: a.ID_MODULO,
                                estado: a.ACTIVO,
                            });
                        });
                        result.data.grupo_clientes.map((a: any) => {
                            grp_clt.push({
                                key: a.ID,
                                text: a.TITULO,
                                activo: a.ACTIVO,
                                disabled: !a.ACTIVO,
                            });
                        });
                        result.data.cliente.map((a: any) => {
                            clt.push({
                                key: a.ID,
                                text: decodeURIComponent(a.TITULO),
                                activo: a.ACTIVO,
                                id_grupo_cliente: a.ID_GRUPO_CLIENTE,
                                disabled: !a.ACTIVO,
                            });
                        });
                        result.data.competidores.map((a: any) => {
                            cmp.push({
                                key: a.ID,
                                activo: a.ACTIVO,
                                text: decodeURIComponent(a.TITULO),
                                disabled: !a.ACTIVO,
                            });
                        });
                        result.data.contratos_marco.map((a: any) => {
                            ctm.push({
                                key: a.ID,
                                text: a.TITULO,
                                id_unidad: a.ID_UNIDAD_NEGOCIO,
                                hora: a.HORA_DIA,
                                activo: a.ACTIVO,
                                id_moneda: a.ID_MONEDA,
                                disabled: !a.ACTIVO,
                            });
                        });
                        result.data.lineas.map((a: any) => {
                            lin.push({
                                key: a.ID,
                                activo: a.ACTIVO,
                                text: decodeURIComponent(a.TITULO),
                                disabled: !a.ACTIVO,
                            });
                        });
                        result.data.modulos.map((a: any) => {
                            mdl.push({
                                key: a.ID,
                                text: a.TITULO,
                                activo: a.ACTIVO,
                                disabled: !a.ACTIVO,
                            });
                        });
                        result.data.monedas.map((a: any) => {
                            mnd.push({
                                key: a.ID,
                                text: a.TITULO,
                                activo: a.ACTIVO,
                                disabled: !a.ACTIVO,
                            });
                        });
                        result.data.motivos.map((a: any) => {
                            mtv.push({
                                key: a.ID,
                                text: a.TITULO,
                                activo: a.ACTIVO,
                                disabled: !a.ACTIVO,
                            });
                        });
                        result.data.oficina.map((a: any) => {
                            ofc.push({
                                key: a.ID,
                                text: a.TITULO,
                                activo: a.ACTIVO,
                                disabled: !a.ACTIVO,
                            });
                        });
                        result.data.paises.map((a: any) => {
                            pais.push({
                                key: a.ID,
                                text: a.TITULO,
                                activo: a.ACTIVO,
                                disabled: !a.ACTIVO,
                            });
                        });
                        result.data.principales_elementos_servicio.map((a: any) => {
                            pels.push({
                                key: a.ID,
                                text: decodeURIComponent(a.TITULO),
                                activo: a.ACTIVO,
                                disabled: !a.ACTIVO,
                            });
                        });
                        result.data.sublineas.map((a: any) => {
                            subl.push({
                                key: a.ID,
                                text: a.TITULO,
                                activo: a.ACTIVO,
                                id_linea: a.ID_LINEA,
                                disabled: !a.ACTIVO,
                            });
                        });
                        result.data.unidades_negocios.map((a: any) => {
                            unn.push({
                                key: a.ID,
                                text: a.TITULO,
                                activo: a.ACTIVO,
                                disabled: !a.ACTIVO,
                            });
                        });
                        result.data.valorizacion.map((a: any) => {
                            vlr.push({
                                key: a.ID,
                                text: a.TITULO,
                                activo: a.ACTIVO,
                                disabled: !a.ACTIVO,
                            });
                        });

                        this.setState(
                            {
                                opciones_grupo_cliente: grp_clt,
                                opciones_cliente_aux: clt,
                                opciones_estado_proy: est.filter((e) => e.id_modulo === 4 && e.estado === true),
                                opciones_competidores: cmp,
                                opciones_contrato_marco_aux: ctm,
                                opciones_linea: lin,
                                opciones_modulos: mdl,
                                opciones_monedas_aux: mnd,
                                opciones_motivos: mtv,
                                opciones_oficina: ofc,
                                opciones_pais: pais,
                                opciones_elementos_servicio: pels,
                                opciones_sublineas_aux: subl,
                                opciones_unidad_negocio: unn,
                                opciones_valorizacion: vlr,
                            },
                            () => {
                                this.obtenerOP();
                            }
                        );
                    }
                } else {
                    console.log(result.data);
                }
            });
    }

    public procesar() {
        let op = this.state.op_info;
    }

    public salirPT() {
        window.location.href = `/`;
    }

    public copiarOportunidad = async (ID: number) => {
        let userName: any;
        let op = this.state.op_info;
        if (authProvider.getAccountInfo() != null) {
            userName = authProvider.getAccountInfo() ? authProvider.getAccountInfo() : {};
        }

        fetchEngine
            .putAPI(`/api/oportunidad/${ID}`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                let copyID = result.id;
                this.setState({ id_copia: copyID, dialogo_copia: false });
            });
    };

    public extenderOportunidad = async (ID: number) => {
        let userName: any;
        let op = this.state.op_info;
        if (authProvider.getAccountInfo() != null) {
            userName = authProvider.getAccountInfo() ? authProvider.getAccountInfo() : {};
        }

        fetchEngine
            .postAPI(`/api/oportunidad/ExtenderOportunidad?id=${ID}`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                let copyID = result.id;
                this.setState({ id_copia: copyID, dialogo_extencion: false });
            });
    };

    private cambiarestadoformulario() {
        this.setState({ estadodelformulario: true });
    }

    public seleccionCopia(ev: any, opcion: any) {
        this.setState({ opciones_copia: parseInt(opcion.key) });
    }

    public textFieldOnChange(ev: any) {
        if (this.state.estadodelformulario === false) {
            this.setState({ estadodelformulario: true });
        }

        let key = ev.target.id;
        let value = ev.target.value;
        let campo = ev.target;
        let op = this.state.op_info;
        let val = 0;
        switch (key) {
            case "nombreoportunidad":
                if (value.length <= 255) {
                    op.NOMBRE = value;
                }
                break;
            case "descripcion":
                if (value.length <= 250) {
                    op.DESCRIPCION = value;
                }
                break;
            case "areacliente":
                op.AREA_CLIENTE = value;
                break;
            case "compradores":
                if (value.length <= 255) {
                    op.COMPRADORES = value;
                }
                break;
            case "nombrecontacto":
                op.NOMBRE_CONTACTO = value;
                break;
            case "mail":
                let regexMail = new RegExp(
                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
                op.MAIL = value;
                if (regexMail.test(value) === false) {
                    this.setState({ validarCorreo: false });
                } else {
                    this.setState({ validarCorreo: true });
                }
                if (value === "") {
                    this.setState({ validarCorreo: true });
                }
                break;
            case "telefono":
                val = value === "" ? 0 : parseInt(value);
                if (val <= 9999999999) {
                    op.TELEFONO = val;
                }
                break;
            case "montoordencompra":
                val = value === "" ? 0 : parseInt(value);
                if (val <= 99999999999) {
                    this.setState({ monto_orden: value });
                }
                break;
            case "leccionaprendida":
                if (value.length <= 250) {
                    op.LECCION_APRENDIDA = value;
                }
                break;

            case "oportunidadnegocio":
                op.OPORTUNIDAD_NEGOCIO = value;
                break;
            case "objetivoproyecto":
                op.OBJETIVO_PROYECTO = value;
                break;
            case "entregables":
                op.ENTREGABLES = value;
                break;
            case "alcance":
                op.ALCANCE = value;
                break;
            case "solucion":
                op.SOLUCION = value;
                break;
            case "beneficios":
                op.BENEFICIOS = value;
                break;

            case "comentarios":
                op.COMENTARIOS = value;
                break;
            case "ordencompra":
                val = value === "" ? 0 : parseInt(value);
                if (val <= 99999999999) {
                    this.setState({ numero_orden: value });
                }
            case "ingresoventa":
                val = value === "" ? 0 : parseInt(value);
                op.INGRESO_VENTA = val;
                break;
            case "costoventa":
                val = value === "" ? 0 : parseInt(value);
                op.COSTO_VENTA = val;
                break;
            case "resultado":
                val = value === "" ? 0 : parseInt(value);
                op.RESULTADO = val;
                break;
            case "rentabilidad":
                val = value === "" ? 0 : parseFloat(value);
                op.RENTABILIDAD = val;
                break;
        }
        this.setState({ op_info: op });
    }

    public ChangeProbabilidad(value: any) {
        let op = this.state.op_info;
        op.PROBABILIDAD = value;
        this.setState({ op_info: op });
    }

    private _onCheckChange(ev?: any, isChecked?: boolean) {
        this.setState({ estadodelformulario: true });
        let id = ev.target.id;
        let op = this.state.op_info;
        switch (id) {
            case "licitacion":
                op.LICITACION = isChecked;
                break;
            case "acreditacion":
                op.REQUIERE_ACREDITACION = isChecked;
                break;
        }

        this.setState({ op_info: op });
    }

    private _onChangeUnidadNegocio = (event: any, item: any): void => {
        this.setState({ estadodelformulario: true });
        let op = this.state.op_info;
        op.ID_UNIDAD = item.key;

        let opciones_contratomarco: any[] = [];

        this.state.opciones_contrato_marco_aux.map((a: any) => {
            if (a.id_unidad === item.key) {
                opciones_contratomarco.push({
                    key: a.key,
                    text: a.text,
                    id_moneda: a.id_moneda,
                    disabled: !a.activo,
                });
            }
        });

        this.setState({
            op_info: op,
            opciones_contrato_marco: opciones_contratomarco,
        });
    };

    private _onChangeEstadoProy = (event: any, item: any): void => {
        this.setState({ estadodelformulario: true });
        let op = this.state.op_info;
        op.ID_ESTADO = item.key;
        this.setState({ op_info: op });
    };
    private _onChangeProyecto = (event: any, item: any): void => {
        this.setState({ estadodelformulario: true });
        let op = this.state.op_info;
        op.ID_OPORTUNIDAD = item.key;

        this.setState(
            {
                op_info: op,
                hasID: true,
                opoID2: item.key,
                esNewProyect: true,
                id_proyecto: item.key,
            },
            () => {
                this.obtenerOP();
            }
        );
    };

    private _onChangeLinea = (event: any, item: any): void => {
        this.setState({ estadodelformulario: true });
        let op = this.state.op_info;
        op.ID_LINEA = item.key;

        let opciones_sublineas2: any[] = [];

        this.state.opciones_sublineas_aux.map((a: any) => {
            if (a.id_linea === item.key) {
                opciones_sublineas2.push({
                    key: a.key,
                    text: a.text,
                    disabled: !a.activo,
                });
            }
        });

        this.setState({ op_info: op, opciones_sublineas: opciones_sublineas2 });
    };

    private _onChangeSubLinea = (event: any, item: any): void => {
        this.setState({ estadodelformulario: true });
        let op = this.state.op_info;
        op.ID_SUBLINEA = item.key;
        this.setState({ op_info: op });
    };

    private _onChangeElementos = (event: any, item: any): void => {
        this.setState({ estadodelformulario: true });
        let op = this.state.op_info;

        let opciones: any[] = this.state.opciones_elementos_servicio;

        opciones.forEach((o) => {
            if (o.key === item.key) {
                o.selected = item.selected;
            }
        });

        this.setState({ opciones_elementos_servicio: [...opciones] });
    };

    private _onChangeGrupoClientes = (event: any, item: any): void => {
        this.setState({ estadodelformulario: true });
        let op = this.state.op_info;
        op.ID_GRUPO_CLIENTE = item.key;
        let opciones_grupo_clientes2: any[] = [];

        this.state.opciones_cliente_aux.map((a: any) => {
            if (a.id_grupo_cliente === item.key) {
                opciones_grupo_clientes2.push({
                    key: a.key,
                    text: a.text,
                    disabled: !a.activo,
                });
            }
        });

        this.setState({
            op_info: op,
            opciones_cliente: opciones_grupo_clientes2,
        });
    };

    private _onChangeClientes = (event: any, item: any): void => {
        this.setState({ estadodelformulario: true });
        let op = this.state.op_info;
        op.ID_CLIENTE = item.key;
        this.setState({ op_info: op });
    };

    private _onChangePais = (event: any, item: any): void => {
        this.setState({ estadodelformulario: true });
        let op = this.state.op_info;
        op.ID_PAIS = item.key;
        this.setState({ op_info: op });
    };

    private _onChangeOficina = (event: any, item: any): void => {
        this.setState({ estadodelformulario: true });
        let op = this.state.op_info;
        op.ID_OFICINA = item.key;
        this.setState({ op_info: op });
    };

    private _onChangeCompetidores = (event: any, item: any): void => {
        this.setState({ estadodelformulario: true });
        let op = this.state.op_info;
        let opciones: any[] = this.state.opciones_competidores;

        opciones.forEach((o) => {
            if (o.key === item.key) {
                o.selected = item.selected;
            }
        });

        this.setState({ opciones_competidores: [...opciones] });
    };

    private _onChangeContrato = (event: any, item: any): void => {
        this.setState({ estadodelformulario: true });
        let op = this.state.op_info;
        op.ID_CONTRATO_MARCO = item.key;

        let opciones_moneda: any[] = [];

        this.state.opciones_monedas_aux.map((a: any) => {
            if (a.key === item.id_moneda) {
                opciones_moneda.push({
                    key: a.key,
                    text: a.text,
                    disabled: !a.activo,
                });
            }
        });

        this.setState({
            op_info: op,
            opciones_monedas: opciones_moneda,
            moneda: opciones_moneda[0].text,
        });
    };

    private _onChangeMotivos = (event: any, item: any): void => {
        this.setState({ estadodelformulario: true });
        let op = this.state.op_info;

        let opciones: any[] = this.state.opciones_motivos;

        opciones.forEach((o) => {
            if (o.key === item.key) {
                o.selected = item.selected;
            }
        });

        this.setState({ opciones_motivos: [...opciones] });
    };

    private _onSelectDateCierre = (date: Date | null | undefined): void => {
        let op = this.state.op_info;
        this.setState({ estadodelformulario: true });
        if (date != null) {
            op.FECHA_APERTURA = date;
        }
        this.setState({ op_info: op });
    };

    private _onSelectDateEstimadaInicio = (date: Date | null | undefined): void => {
        let op = this.state.op_info;
        this.setState({ estadodelformulario: true });

        if (date != null) {
            if (op.FECHA_ESTIMADA_FIN != undefined) {
                if (date > op.FECHA_ESTIMADA_FIN) {
                    this.setState({ estimada_inicio_mayor: false });
                } else {
                    op.FECHA_ESTIMADA_INICIO = date;
                }
            } else {
                op.FECHA_ESTIMADA_INICIO = date;
            }
        }
        this.setState({ op_info: op });
    };

    private _onSelectDateEstimadaFin = (date: Date | null | undefined): void => {
        let op = this.state.op_info;
        this.setState({ estadodelformulario: true });

        if (date != null) {
            if (op.FECHA_ESTIMADA_INICIO != undefined) {
                if (date < op.FECHA_ESTIMADA_INICIO) {
                    this.setState({ estimada_fin_menor: false });
                } else {
                    op.FECHA_ESTIMADA_FIN = date;
                }
            } else {
                op.FECHA_ESTIMADA_FIN = date;
            }
        }

        this.setState({ op_info: op });
    };

    private _onSelectDateEnvioPropuesta = (date: Date | null | undefined): void => {
        let op = this.state.op_info;
        this.setState({ estadodelformulario: true });
        if (date != null) {
            op.FECHA_ENVIO_PROPUESTA = date;
        }
        this.setState({ op_info: op });
    };

    private _onSelectDateInicio = (date: Date | null | undefined): void => {
        let op = this.state.op_info;
        this.setState({ estadodelformulario: true });
        if (date != null) {
            if (op.FECHA_TERMINO != undefined) {
                if (date > op.FECHA_TERMINO) {
                    this.setState({ estimada_inicio_mayor: false });
                } else {
                    op.FECHA_INICIO = date;
                }
            } else {
                op.FECHA_INICIO = date;
            }
        }
        this.setState({ op_info: op });
    };

    private _onSelectDateFin = (date: Date | null | undefined): void => {
        let op = this.state.op_info;
        this.setState({ estadodelformulario: true });
        if (date != null) {
            if (op.FECHA_INICIO != undefined) {
                if (date < op.FECHA_INICIO) {
                    this.setState({ estimada_fin_menor: false });
                } else {
                    op.FECHA_TERMINO = date;
                }
            } else {
                op.FECHA_TERMINO = date;
            }
        }
        this.setState({ op_info: op });
    };

    //FUNCIONES PRINCIPALES BOTONERA
    public async obtenerOP() {
        if (this.state.hasID) {
            fetchEngine
                .getAPI(`/api/GestionProyecto/${this.state.op_info.ID_OPORTUNIDAD}`, {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.state.tokenAPI}`,
                })
                .then((resultado: any) => {
                    if (resultado.ok) {
                        this.setState({ disabledInicial: false });
                        let oportunidad = resultado.data.oportunidad[0];
                        let elementos: any[] = resultado.data.elementos;
                        let motivos: any[] = resultado.data.motivos;
                        let competidores: any[] = resultado.data.competidores;
                        let colaboradores: any[] = resultado.data.colaboradores;
                        let extension = oportunidad.ID_EXTENSION === 0 ? "" : " - " + oportunidad.ID_EXTENSION;
                        let id_extension: string = oportunidad.ID_EXTENSION === 0 ? oportunidad.ID_OPORTUNIDAD : oportunidad.ID_ORIGINAL + extension;

                        let max = 250;
                        let contadorTexto = 0;
                        let longitud = 0;

                        if (oportunidad.DESCRIPCION != undefined && oportunidad.DESCRIPCION != "" && oportunidad.DESCRIPCION != null) {
                            max = 250;
                            contadorTexto = 0;
                            longitud = oportunidad.DESCRIPCION.length;
                            if (longitud <= max) {
                                contadorTexto = max - longitud;
                            }
                            this.setState({
                                contadorDescripcion: contadorTexto,
                            });
                        } else {
                            oportunidad.DESCRIPCION = "";
                        }
                        if (
                            oportunidad.LECCION_APRENDIDA != undefined &&
                            oportunidad.LECCION_APRENDIDA != "" &&
                            oportunidad.LECCION_APRENDIDA != null
                        ) {
                            max = 250;
                            contadorTexto = 0;
                            longitud = oportunidad.LECCION_APRENDIDA.length;
                            if (longitud <= max) {
                                contadorTexto = max - longitud;
                            }
                            this.setState({ contadorLeccion: contadorTexto });
                        } else {
                            oportunidad.LECCION_APRENDIDA = "";
                        }
                        if (oportunidad.COMENTARIOS != undefined && oportunidad.COMENTARIOS != "" && oportunidad.COMENTARIOS != null) {
                            max = 250;
                            contadorTexto = 0;
                            longitud = oportunidad.COMENTARIOS.length;
                            if (longitud <= max) {
                                contadorTexto = max - longitud;
                            }
                            this.setState({
                                contadorComentarios: contadorTexto,
                            });
                        } else {
                            oportunidad.COMENTARIOS = "";
                        }

                        if (
                            oportunidad.OPORTUNIDAD_NEGOCIO != undefined &&
                            oportunidad.OPORTUNIDAD_NEGOCIO != "" &&
                            oportunidad.OPORTUNIDAD_NEGOCIO != null
                        ) {
                            max = 250;
                            contadorTexto = 0;
                            longitud = oportunidad.OPORTUNIDAD_NEGOCIO.length;
                            if (longitud <= max) {
                                contadorTexto = max - longitud;
                            }
                            this.setState({
                                contadorOportunidadNegocio: contadorTexto,
                            });
                        } else {
                            oportunidad.OPORTUNIDAD_NEGOCIO = "";
                        }

                        if (
                            oportunidad.OBJETIVO_PROYECTO != undefined &&
                            oportunidad.OBJETIVO_PROYECTO != "" &&
                            oportunidad.OBJETIVO_PROYECTO != null
                        ) {
                            max = 250;
                            contadorTexto = 0;
                            longitud = oportunidad.OBJETIVO_PROYECTO.length;
                            if (longitud <= max) {
                                contadorTexto = max - longitud;
                            }
                            this.setState({ contadorObjetivo: contadorTexto });
                        } else {
                            oportunidad.OBJETIVO_PROYECTO = "";
                        }

                        if (oportunidad.ENTREGABLES != undefined && oportunidad.ENTREGABLES != "" && oportunidad.ENTREGABLES != null) {
                            max = 250;
                            contadorTexto = 0;
                            longitud = oportunidad.ENTREGABLES.length;
                            if (longitud <= max) {
                                contadorTexto = max - longitud;
                            }
                            this.setState({
                                contadorEntregables: contadorTexto,
                            });
                        } else {
                            oportunidad.ENTREGABLES = "";
                        }

                        if (oportunidad.ALCANCE != undefined && oportunidad.ALCANCE != "" && oportunidad.ALCANCE != null) {
                            max = 250;
                            contadorTexto = 0;
                            longitud = oportunidad.ALCANCE.length;
                            if (longitud <= max) {
                                contadorTexto = max - longitud;
                            }
                            this.setState({ contadorAlcance: contadorTexto });
                        } else {
                            oportunidad.ALCANCE = "";
                        }

                        if (oportunidad.SOLUCION != undefined && oportunidad.SOLUCION != "" && oportunidad.SOLUCION != null) {
                            max = 250;
                            contadorTexto = 0;
                            longitud = oportunidad.SOLUCION.length;
                            if (longitud <= max) {
                                contadorTexto = max - longitud;
                            }
                            this.setState({ contadorSolucion: contadorTexto });
                        } else {
                            oportunidad.SOLUCION = "";
                        }

                        if (oportunidad.BENEFICIOS != undefined && oportunidad.BENEFICIOS != "" && oportunidad.BENEFICIOS != null) {
                            max = 250;
                            contadorTexto = 0;
                            longitud = oportunidad.BENEFICIOS.length;
                            if (longitud <= max) {
                                contadorTexto = max - longitud;
                            }
                            this.setState({
                                contadorBeneficios: contadorTexto,
                            });
                        } else {
                            oportunidad.BENEFICIOS = "";
                        }
                        let el_actuales = this.state.opciones_elementos_servicio;

                        el_actuales.forEach((e: any) => {
                            let elemento_guardado = elementos.find((d: any) => d.ID_ELEMENTO === e.key);
                            e.selected = elemento_guardado != undefined ? elemento_guardado.ACTIVO : false;
                        });

                        let el_motivos = this.state.opciones_motivos;

                        el_motivos.forEach((e: any) => {
                            let elemento_guardado = motivos.find((d: any) => d.ID_ELEMENTO === e.key);
                            e.selected = elemento_guardado != undefined ? elemento_guardado.ACTIVO : false;
                        });

                        let el_competidores = this.state.opciones_competidores;

                        el_competidores.forEach((e: any) => {
                            let elemento_guardado = competidores.find((d: any) => d.ID_ELEMENTO === e.key);
                            e.selected = elemento_guardado != undefined ? elemento_guardado.ACTIVO : false;
                        });

                        oportunidad.COLABORADORES = colaboradores;

                        oportunidad.ORDEN_COMPRA = [];
                        oportunidad.MOTIVOS = el_motivos;
                        oportunidad.FECHA_APERTURA = oportunidad.FECHA_APERTURA != null ? new Date(oportunidad.FECHA_APERTURA) : new Date();
                        oportunidad.FECHA_INICIO = oportunidad.FECHA_INICIO != null ? new Date(oportunidad.FECHA_INICIO) : new Date();
                        oportunidad.FECHA_TERMINO = oportunidad.FECHA_TERMINO != null ? new Date(oportunidad.FECHA_TERMINO) : new Date();
                        oportunidad.FECHA_ENVIO_PROPUESTA =
                            oportunidad.FECHA_ENVIO_PROPUESTA != null ? new Date(oportunidad.FECHA_ENVIO_PROPUESTA) : new Date();
                        oportunidad.FECHA_ESTIMADA_INICIO =
                            oportunidad.FECHA_ESTIMADA_INICIO != null ? new Date(oportunidad.FECHA_ESTIMADA_INICIO) : new Date();
                        oportunidad.FECHA_ESTIMADA_FIN =
                            oportunidad.FECHA_ESTIMADA_FIN != null ? new Date(oportunidad.FECHA_ESTIMADA_FIN) : new Date();
                        oportunidad.FECHA_CREACION = oportunidad.FECHA_CREACION != null ? new Date(oportunidad.FECHA_CREACION) : new Date();
                        oportunidad.FECHA_MODIFICACION =
                            oportunidad.FECHA_MODIFICACION != null ? new Date(oportunidad.FECHA_MODIFICACION) : new Date();

                        this.setState(
                            {
                                op_info: oportunidad,
                                opciones_elementos_servicio: [...el_actuales],
                                opciones_motivos: [...el_motivos],
                                opciones_competidores: [...el_competidores],
                                numero_extension: id_extension,
                                copiada: true,
                                opoID: resultado.data.oportunidad[0].ID,
                            },
                            () => {}
                        );

                        this.forceUpdate();
                    } else {
                        console.log(resultado.data);
                    }
                    this.cargarHerencia();
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            fetchEngine
                .getAPI(`/api/GestionProyecto/${this.state.opoID}`, {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.state.tokenAPI}`,
                })
                .then((resultado: any) => {
                    if (resultado.ok) {
                        this.setState({ disabledInicial: false });
                        let oportunidad = resultado.data.oportunidad[0];
                        let elementos: any[] = resultado.data.elementos;
                        let motivos: any[] = resultado.data.motivos;
                        let competidores: any[] = resultado.data.competidores;
                        let colaboradores: any[] = resultado.data.colaboradores;
                        let extension = oportunidad.ID_EXTENSION === 0 ? "" : " - " + oportunidad.ID_EXTENSION;
                        let id_extension: string = oportunidad.ID_EXTENSION === 0 ? oportunidad.ID_OPORTUNIDAD : oportunidad.ID_ORIGINAL + extension;

                        let max = 250;
                        let contadorTexto = 0;
                        let longitud = 0;

                        if (oportunidad.DESCRIPCION != undefined && oportunidad.DESCRIPCION != "" && oportunidad.DESCRIPCION != null) {
                            max = 250;
                            contadorTexto = 0;
                            longitud = oportunidad.DESCRIPCION.length;
                            if (longitud <= max) {
                                contadorTexto = max - longitud;
                            }
                            this.setState({
                                contadorDescripcion: contadorTexto,
                            });
                        } else {
                            oportunidad.DESCRIPCION = "";
                        }
                        if (
                            oportunidad.LECCION_APRENDIDA != undefined &&
                            oportunidad.LECCION_APRENDIDA != "" &&
                            oportunidad.LECCION_APRENDIDA != null
                        ) {
                            max = 250;
                            contadorTexto = 0;
                            longitud = oportunidad.LECCION_APRENDIDA.length;
                            if (longitud <= max) {
                                contadorTexto = max - longitud;
                            }
                            this.setState({ contadorLeccion: contadorTexto });
                        } else {
                            oportunidad.LECCION_APRENDIDA = "";
                        }
                        if (oportunidad.COMENTARIOS != undefined && oportunidad.COMENTARIOS != "" && oportunidad.COMENTARIOS != null) {
                            max = 250;
                            contadorTexto = 0;
                            longitud = oportunidad.COMENTARIOS.length;
                            if (longitud <= max) {
                                contadorTexto = max - longitud;
                            }
                            this.setState({
                                contadorComentarios: contadorTexto,
                            });
                        } else {
                            oportunidad.COMENTARIOS = "";
                        }

                        if (
                            oportunidad.OPORTUNIDAD_NEGOCIO != undefined &&
                            oportunidad.OPORTUNIDAD_NEGOCIO != "" &&
                            oportunidad.OPORTUNIDAD_NEGOCIO != null
                        ) {
                            max = 250;
                            contadorTexto = 0;
                            longitud = oportunidad.OPORTUNIDAD_NEGOCIO.length;
                            if (longitud <= max) {
                                contadorTexto = max - longitud;
                            }
                            this.setState({
                                contadorOportunidadNegocio: contadorTexto,
                            });
                        } else {
                            oportunidad.OPORTUNIDAD_NEGOCIO = "";
                        }

                        if (
                            oportunidad.OBJETIVO_PROYECTO != undefined &&
                            oportunidad.OBJETIVO_PROYECTO != "" &&
                            oportunidad.OBJETIVO_PROYECTO != null
                        ) {
                            max = 250;
                            contadorTexto = 0;
                            longitud = oportunidad.OBJETIVO_PROYECTO.length;
                            if (longitud <= max) {
                                contadorTexto = max - longitud;
                            }
                            this.setState({ contadorObjetivo: contadorTexto });
                        } else {
                            oportunidad.OBJETIVO_PROYECTO = "";
                        }

                        if (oportunidad.ENTREGABLES != undefined && oportunidad.ENTREGABLES != "" && oportunidad.ENTREGABLES != null) {
                            max = 250;
                            contadorTexto = 0;
                            longitud = oportunidad.ENTREGABLES.length;
                            if (longitud <= max) {
                                contadorTexto = max - longitud;
                            }
                            this.setState({
                                contadorEntregables: contadorTexto,
                            });
                        } else {
                            oportunidad.ENTREGABLES = "";
                        }

                        if (oportunidad.ALCANCE != undefined && oportunidad.ALCANCE != "" && oportunidad.ALCANCE != null) {
                            max = 250;
                            contadorTexto = 0;
                            longitud = oportunidad.ALCANCE.length;
                            if (longitud <= max) {
                                contadorTexto = max - longitud;
                            }
                            this.setState({ contadorAlcance: contadorTexto });
                        } else {
                            oportunidad.ALCANCE = "";
                        }

                        if (oportunidad.SOLUCION != undefined && oportunidad.SOLUCION != "" && oportunidad.SOLUCION != null) {
                            max = 250;
                            contadorTexto = 0;
                            longitud = oportunidad.SOLUCION.length;
                            if (longitud <= max) {
                                contadorTexto = max - longitud;
                            }
                            this.setState({ contadorSolucion: contadorTexto });
                        } else {
                            oportunidad.SOLUCION = "";
                        }

                        if (oportunidad.BENEFICIOS != undefined && oportunidad.BENEFICIOS != "" && oportunidad.BENEFICIOS != null) {
                            max = 250;
                            contadorTexto = 0;
                            longitud = oportunidad.BENEFICIOS.length;
                            if (longitud <= max) {
                                contadorTexto = max - longitud;
                            }
                            this.setState({
                                contadorBeneficios: contadorTexto,
                            });
                        } else {
                            oportunidad.BENEFICIOS = "";
                        }
                        let el_actuales = this.state.opciones_elementos_servicio;

                        el_actuales.forEach((e: any) => {
                            let elemento_guardado = elementos.find((d: any) => d.ID_ELEMENTO === e.key);
                            e.selected = elemento_guardado != undefined ? elemento_guardado.ACTIVO : false;
                        });

                        let el_motivos = this.state.opciones_motivos;

                        el_motivos.forEach((e: any) => {
                            let elemento_guardado = motivos.find((d: any) => d.ID_ELEMENTO === e.key);
                            e.selected = elemento_guardado != undefined ? elemento_guardado.ACTIVO : false;
                        });

                        let el_competidores = this.state.opciones_competidores;

                        el_competidores.forEach((e: any) => {
                            let elemento_guardado = competidores.find((d: any) => d.ID_ELEMENTO === e.key);
                            e.selected = elemento_guardado != undefined ? elemento_guardado.ACTIVO : false;
                        });

                        oportunidad.COLABORADORES = colaboradores;

                        oportunidad.ORDEN_COMPRA = [];
                        oportunidad.MOTIVOS = el_motivos;
                        oportunidad.FECHA_APERTURA = oportunidad.FECHA_APERTURA != null ? new Date(oportunidad.FECHA_APERTURA) : new Date();
                        oportunidad.FECHA_INICIO = oportunidad.FECHA_INICIO != null ? new Date(oportunidad.FECHA_INICIO) : new Date();
                        oportunidad.FECHA_TERMINO = oportunidad.FECHA_TERMINO != null ? new Date(oportunidad.FECHA_TERMINO) : new Date();
                        oportunidad.FECHA_ENVIO_PROPUESTA =
                            oportunidad.FECHA_ENVIO_PROPUESTA != null ? new Date(oportunidad.FECHA_ENVIO_PROPUESTA) : new Date();
                        oportunidad.FECHA_ESTIMADA_INICIO =
                            oportunidad.FECHA_ESTIMADA_INICIO != null ? new Date(oportunidad.FECHA_ESTIMADA_INICIO) : new Date();
                        oportunidad.FECHA_ESTIMADA_FIN =
                            oportunidad.FECHA_ESTIMADA_FIN != null ? new Date(oportunidad.FECHA_ESTIMADA_FIN) : new Date();

                        this.setState(
                            {
                                op_info: oportunidad,
                                opciones_elementos_servicio: [...el_actuales],
                                opciones_motivos: [...el_motivos],
                                opciones_competidores: [...el_competidores],
                                numero_extension: id_extension,
                                copiada: true,
                                id_proyecto: resultado.data.oportunidad[0].ID.toString(),
                                opoID: resultado.data.oportunidad[0].ID,
                            },
                            () => {}
                        );

                        this.forceUpdate();
                    } else {
                        console.log(resultado.data);
                    }
                    this.cargarHerencia();
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    public async guardarOP() {
        let op = this.state.op_info;
        let opID = this.state.opoID;
        let userName: any;
        let urlSitio = window.location.protocol + "//" + window.location.host;
        let colaboradores: any;

        op.ID = this.state.opoID;
        if (authProvider.getAccountInfo() != null) {
            userName = authProvider.getAccountInfo() ? authProvider.getAccountInfo() : {};
        }
        if (this.state.opoID === 0) {
            op.CREADO_POR = userName.account.userName;
        }
        op.MODIFICADO_POR = userName.account.userName;

        this.setState({ dialogoGuardar: false, dialogoValidar: true });
        const requestHeaders: Headers = new Headers();

        op.PRINCIPALES_ELEMENTOS = this.state.opciones_elementos_servicio.map((k: any) => {
            return { ID_ELEMENTO: k.key, ACTIVO: k.selected };
        });

        op.MOTIVOS = this.state.opciones_motivos.map((k: any) => {
            return {
                ID_ELEMENTO: k.key,
                ACTIVO: k.selected,
                selected: k.selected,
            };
        });

        op.COMPETIDORES = this.state.opciones_competidores.map((k: any) => {
            return { ID_ELEMENTO: k.key, ACTIVO: k.selected };
        });

        op.token = this.state.tokenAPISharePoint;

        fetchEngine
            .postAPI("/api/GestionProyecto", op, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((resultado: any) => {
                if (resultado.ok) {
                    this.setState({
                        dialogoGuardar2: false,
                        dialogoGuardar: true,
                        opoID: resultado.id,
                    });

                    if (this.state.hasID) {
                        this.obtenerOP();
                    } else {
                        let repo = {};
                        opID = resultado.id;
                        this.setState({ opoID: opID });
                        history.push(`/GestionProyecto/${resultado.id}`);
                    }
                } else {
                    this.setState({ dialogoGuardarError: false });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    public redirigirPlanificacion() {
        window.location.href = `/planificacionproyecto/${this.state.opoID}`;
    }

    public redirigirSimulacion() {
        window.location.href = `/simulador/0/${this.state.opoID}`;
    }

    public validarCamposPlan() {
        let validacionMotivo: any = [];
        this.state.op_info.MOTIVOS.map((a: any) => {
            if (a.selected === true) {
                validacionMotivo.push({
                    key: a.ID_ELEMENTO,
                });
            }
        });
        if (
            this.state.op_info.NOMBRE === undefined ||
            this.state.op_info.NOMBRE === "" ||
            this.state.op_info.NOMBRE === null ||
            this.state.op_info.ID_UNIDAD === undefined ||
            this.state.op_info.ID_UNIDAD === 0 ||
            this.state.op_info.ID_UNIDAD === null ||
            this.state.op_info.ID_CONTRATO_MARCO === 0 ||
            this.state.op_info.ID_CONTRATO_MARCO === null ||
            this.state.op_info.RESPONSABLE_PROYECTO_DISPLAY === undefined ||
            this.state.op_info.RESPONSABLE_PROYECTO_DISPLAY === "" ||
            this.state.op_info.RESPONSABLE_PROYECTO_DISPLAY === null ||
            this.state.op_info.DESCRIPCION === undefined ||
            this.state.op_info.DESCRIPCION === "" ||
            this.state.op_info.DESCRIPCION === null ||
            this.state.op_info.FECHA_APERTURA === undefined ||
            this.state.op_info.FECHA_APERTURA === null ||
            this.state.op_info.ID_LINEA === undefined ||
            this.state.op_info.ID_LINEA === 0 ||
            this.state.op_info.ID_LINEA === null ||
            this.state.op_info.ID_SUBLINEA === undefined ||
            this.state.op_info.ID_SUBLINEA === 0 ||
            this.state.op_info.ID_SUBLINEA === null ||
            this.state.op_info.ID_CONTRATO_MARCO === undefined ||
            this.state.op_info.ID_CONTRATO_MARCO === 0 ||
            this.state.op_info.ID_CONTRATO_MARCO === null ||
            this.state.op_info.FECHA_INICIO === undefined ||
            this.state.op_info.FECHA_INICIO === null ||
            this.state.op_info.FECHA_TERMINO === undefined ||
            this.state.op_info.FECHA_TERMINO === null ||
            this.state.op_info.PROBABILIDAD === undefined ||
            this.state.op_info.PROBABILIDAD === 0 ||
            this.state.op_info.PROBABILIDAD === null ||
            this.state.op_info.ID_CLIENTE === undefined ||
            this.state.op_info.ID_CLIENTE === 0 ||
            this.state.op_info.ID_CLIENTE === null ||
            this.state.op_info.ID_PAIS === undefined ||
            this.state.op_info.ID_PAIS === 0 ||
            this.state.op_info.ID_PAIS === null ||
            this.state.op_info.ID_OFICINA === undefined ||
            this.state.op_info.ID_OFICINA === 0 ||
            this.state.op_info.ID_OFICINA === null ||
            this.state.op_info.NOMBRE_CONTACTO === undefined ||
            this.state.op_info.NOMBRE_CONTACTO === "" ||
            this.state.op_info.NOMBRE_CONTACTO === null ||
            this.state.op_info.COLABORADORES.length === 0 ||
            ((this.state.op_info.MAIL != undefined || this.state.op_info.MAIL != "" || this.state.op_info.MAIL != null) &&
                this.state.validarCorreo === false) ||
            this.state.op_info.FECHA_ESTIMADA_INICIO === undefined ||
            this.state.op_info.FECHA_ESTIMADA_INICIO === null ||
            this.state.op_info.FECHA_ESTIMADA_FIN === undefined ||
            this.state.op_info.FECHA_ESTIMADA_FIN === null ||
            this.state.op_info.FECHA_ENVIO_PROPUESTA === undefined ||
            this.state.op_info.FECHA_ENVIO_PROPUESTA === null ||
            validacionMotivo.length === 0 ||
            this.state.op_info.JEFE_PROYECTO_DISPLAY === undefined ||
            this.state.op_info.JEFE_PROYECTO_DISPLAY === "" ||
            this.state.op_info.JEFE_PROYECTO_DISPLAY === null ||
            this.state.op_info.RESPONSABLE_GASTOS_DISPLAY === undefined ||
            this.state.op_info.RESPONSABLE_GASTOS_DISPLAY === "" ||
            this.state.op_info.RESPONSABLE_GASTOS_DISPLAY === null ||
            this.state.op_info.RESPONSABLE_HORAS_DISPLAY === undefined ||
            this.state.op_info.RESPONSABLE_HORAS_DISPLAY === "" ||
            this.state.op_info.RESPONSABLE_HORAS_DISPLAY === null ||
            this.state.op_info.OPORTUNIDAD_NEGOCIO === undefined ||
            this.state.op_info.OPORTUNIDAD_NEGOCIO === "" ||
            this.state.op_info.OPORTUNIDAD_NEGOCIO === null ||
            this.state.op_info.OBJETIVO_PROYECTO === undefined ||
            this.state.op_info.OBJETIVO_PROYECTO === "" ||
            this.state.op_info.OBJETIVO_PROYECTO === null ||
            this.state.op_info.ENTREGABLES === undefined ||
            this.state.op_info.ENTREGABLES === "" ||
            this.state.op_info.ENTREGABLES === null
        ) {
            this.setState({ dialogoValidar: false });
        } else {
            this.setState({ dialogoValidar: true });
            this.guardarOP();
            this.redirigirPlanificacion();
        }
    }

    public userHandler(tipo: string, value: any, display: string) {
        let opo = this.state.op_info;

        if (tipo === "responsable") {
            opo.RESPONSABLE_PROYECTO_DISPLAY = value[0].name;
            opo.RESPONSABLE_PROYECTO_LOGIN = value[0].mail;

            this.setState({ op_info: opo });
        } else if (tipo === "responsable2") {
            opo.RESPONSABLE_DISPLAY = value[0].name;
            opo.RESPONSABLE_LOGIN = value[0].mail;
        } else if (tipo === "colaboradores") {
            opo.COLABORADORES = value.map((p: any) => {
                return { MAIL: p.mail, DISPLAYNAME: p.name, ACTIVO: true };
            });
        } else if (tipo === "jefeproyecto") {
            opo.JEFE_PROYECTO_DISPLAY = value[0].name;
            opo.JEFE_PROYECTO_LOGIN = value[0].mail;
        } else if (tipo === "respapruebagastos") {
            opo.RESPONSABLE_GASTOS_DISPLAY = value[0].name;
            opo.RESPONSABLE_GASTOS_LOGIN = value[0].mail;
        } else if (tipo === "respapruebahoras") {
            opo.RESPONSABLE_HORAS_DISPLAY = value[0].name;
            opo.RESPONSABLE_HORAS_LOGIN = value[0].mail;
        } else if (tipo === "asistente") {
            opo.ASISTENTE_DISPLAY = value[0].name;
            opo.ASISTENTE_LOGIN = value[0].mail;
        }

        this.setState({ op_info: opo });
    }

    public formatNumber(valor: string) {
        let value = "";

        if (valor.includes(".")) {
            value = valor.replace(".", ",");
        } else {
            value = valor;
        }

        value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

        return value;
    }

    public contar(ev: any) {
        let max = 250;
        let contadorTexto = 0;
        let key = ev.target.value;
        let longitud = key.length;
        let keyInput = ev.target.id;

        switch (keyInput) {
            case "descripcion":
                contadorTexto = this.state.contadorDescripcion;
                if (longitud <= max) {
                    contadorTexto = max - longitud;
                }
                this.setState({ contadorDescripcion: contadorTexto });
                break;
            case "leccionaprendida":
                contadorTexto = this.state.contadorDescripcion;
                if (longitud <= max) {
                    contadorTexto = max - longitud;
                }
                this.setState({ contadorLeccion: contadorTexto });
                break;
            case "comentarios":
                contadorTexto = this.state.contadorComentarios;
                if (longitud <= max) {
                    contadorTexto = max - longitud;
                }
                this.setState({ contadorComentarios: contadorTexto });
                break;
            case "oportunidadnegocio":
                contadorTexto = this.state.contadorOportunidadNegocio;
                if (longitud <= max) {
                    contadorTexto = max - longitud;
                }
                this.setState({ contadorOportunidadNegocio: contadorTexto });
                break;
            case "objetivoproyecto":
                contadorTexto = this.state.contadorObjetivo;
                if (longitud <= max) {
                    contadorTexto = max - longitud;
                }
                this.setState({ contadorObjetivo: contadorTexto });
                break;
            case "entregables":
                contadorTexto = this.state.contadorEntregables;
                if (longitud <= max) {
                    contadorTexto = max - longitud;
                }
                this.setState({ contadorEntregables: contadorTexto });
                break;
            case "alcance":
                contadorTexto = this.state.contadorAlcance;
                if (longitud <= max) {
                    contadorTexto = max - longitud;
                }
                this.setState({ contadorAlcance: contadorTexto });
                break;
            case "solucion":
                contadorTexto = this.state.contadorSolucion;
                if (longitud <= max) {
                    contadorTexto = max - longitud;
                }
                this.setState({ contadorSolucion: contadorTexto });
                break;
            case "beneficios":
                contadorTexto = this.state.contadorBeneficios;
                if (longitud <= max) {
                    contadorTexto = max - longitud;
                }
                this.setState({ contadorBeneficios: contadorTexto });
                break;
        }
    }

    public seccionDatosGenerales() {
        return (
            <div>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                            <TextField
                                id={"nombreoportunidad"}
                                label="Nombre Oportunidad"
                                required={true}
                                disabled={this.state.disabledInicial}
                                value={this.state.op_info.NOMBRE}
                                onChange={(e) => {
                                    this.textFieldOnChange(e);
                                }}></TextField>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                            <ComboBox
                                label="Unidad de Negocio"
                                placeholder="Seleccionar..."
                                allowFreeform={true}
                                disabled={this.state.disabledInicial}
                                autoComplete="on"
                                selectedKey={this.state.op_info.ID_UNIDAD}
                                onChange={this._onChangeUnidadNegocio}
                                options={this.state.opciones_unidad_negocio}
                                required={true}></ComboBox>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg3">
                            <Label required={true}>Responsable Oportunidad</Label>
                            <AutoComplete
                                disabled={this.state.disabledInicial!}
                                id="responsable"
                                callback={this.userHandler}
                                multi={false}
                                defaultValue={{
                                    name: this.state.op_info.RESPONSABLE_DISPLAY,
                                    mail: this.state.op_info.RESPONSABLE_LOGIN,
                                }}
                                tokenAPIGraph={this.state.tokenAPIGraph}
                            />
                        </div>
                    </div>

                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                            <TextField
                                disabled={this.state.disabledInicial}
                                id={"descripcion"}
                                label="Descripción"
                                value={this.state.op_info.DESCRIPCION}
                                multiline
                                autoAdjustHeight
                                required={true}
                                onChange={(e) => {
                                    this.textFieldOnChange(e);
                                    this.contar(e);
                                }}></TextField>
                            <label>Quedan {this.state.contadorDescripcion} carácteres.</label>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                            <ComboBox
                                label="Área de negocio"
                                allowFreeform={true}
                                disabled={this.state.disabledInicial}
                                autoComplete="on"
                                placeholder="Seleccionar..."
                                selectedKey={this.state.op_info.ID_LINEA}
                                onChange={this._onChangeLinea}
                                options={this.state.opciones_linea}
                                required={true}></ComboBox>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                            <ComboBox
                                label="Línea"
                                allowFreeform={true}
                                disabled={this.state.disabledInicial}
                                autoComplete="on"
                                placeholder="Seleccionar..."
                                selectedKey={this.state.op_info.ID_SUBLINEA}
                                onChange={this._onChangeSubLinea}
                                options={this.state.opciones_sublineas}
                                required={true}></ComboBox>
                        </div>
                    </div>

                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                            <ComboBox
                                label="Principales elementos del servicio"
                                disabled={this.state.disabledInicial}
                                autoComplete="on"
                                placeholder="Seleccionar..."
                                multiSelect={true}
                                onChange={this._onChangeElementos}
                                options={this.state.opciones_elementos_servicio}></ComboBox>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                            <Slider
                                disabled={this.state.disabledInicial}
                                value={this.state.op_info.PROBABILIDAD}
                                label="Probabilidad de la oportunidad *"
                                min={0}
                                max={100}
                                onChange={(value) => this.ChangeProbabilidad(value)}></Slider>
                            <h6>Probabilidad: {this.state.op_info.PROBABILIDAD}%</h6>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg3">
                            <DatePicker
                                onChange={this.cambiarestadoformulario}
                                id="fechacierre"
                                className={controlClass.control}
                                firstDayOfWeek={DayOfWeek.Monday}
                                strings={DayPickerStrings}
                                allowTextInput={true}
                                placeholder="Fecha de apertura"
                                label="Fecha de apertura"
                                value={this.state.op_info.FECHA_APERTURA}
                                ariaLabel="Seleccione una fecha"
                                formatDate={this._onFormatDate}
                                disabled={this.state.disabledInicial}
                                onSelectDate={this._onSelectDateCierre}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    public seccionDatosCliente() {
        return (
            <div>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                            <ComboBox
                                label="Grupo cliente"
                                required={true}
                                allowFreeform={true}
                                disabled={this.state.disabledInicial}
                                autoComplete="on"
                                placeholder="Seleccionar..."
                                selectedKey={this.state.op_info.ID_GRUPO_CLIENTE}
                                onChange={this._onChangeGrupoClientes}
                                options={this.state.opciones_grupo_cliente}></ComboBox>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                            <ComboBox
                                label="Cliente"
                                required={true}
                                allowFreeform={true}
                                disabled={this.state.disabledInicial}
                                autoComplete="on"
                                placeholder="Seleccionar..."
                                selectedKey={this.state.op_info.ID_CLIENTE}
                                onChange={this._onChangeClientes}
                                options={this.state.opciones_cliente}></ComboBox>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                            <ComboBox
                                label="País"
                                required={true}
                                allowFreeform={true}
                                disabled={this.state.disabledInicial}
                                autoComplete="on"
                                placeholder="Seleccionar..."
                                selectedKey={this.state.op_info.ID_PAIS}
                                onChange={this._onChangePais}
                                options={this.state.opciones_pais}></ComboBox>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                            <ComboBox
                                label="Oficina"
                                required={true}
                                allowFreeform={true}
                                disabled={this.state.disabledInicial}
                                autoComplete="on"
                                placeholder="Seleccionar..."
                                selectedKey={this.state.op_info.ID_OFICINA}
                                onChange={this._onChangeOficina}
                                options={this.state.opciones_oficina}></ComboBox>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                            <TextField
                                label="Área de cliente"
                                placeholder="Área de cliente"
                                id={"areacliente"}
                                disabled={this.state.disabledInicial}
                                value={this.state.op_info.AREA_CLIENTE}
                                onChange={(e) => {
                                    this.textFieldOnChange(e);
                                }}
                                required={true}></TextField>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                            <TextField
                                disabled={this.state.disabledInicial}
                                label="Compradores"
                                value={this.state.op_info.COMPRADORES}
                                id={"compradores"}
                                onChange={(e) => {
                                    this.textFieldOnChange(e);
                                }}
                                required={true}></TextField>
                        </div>

                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                            <TextField
                                disabled={this.state.disabledInicial}
                                label="Nombre contacto"
                                required={true}
                                value={this.state.op_info.NOMBRE_CONTACTO}
                                id={"nombrecontacto"}
                                onChange={(e) => {
                                    this.textFieldOnChange(e);
                                }}></TextField>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                            <TextField
                                disabled={this.state.disabledInicial}
                                label="Mail"
                                id={"mail"}
                                required={true}
                                value={this.state.op_info.MAIL}
                                errorMessage={this.state.validarCorreo === false ? "Formato de correo incorrecto" : ""}
                                onChange={(e) => {
                                    this.textFieldOnChange(e);
                                }}></TextField>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                            <TextField
                                disabled={this.state.disabledInicial}
                                label="Teléfono"
                                id={"telefono"}
                                placeholder={"9 XXX XXX XX"}
                                value={this.state.op_info.TELEFONO === 0 ? "" : this.state.op_info.TELEFONO}
                                onChange={(e) => {
                                    this.textFieldOnChange(e);
                                }}
                                required={true}></TextField>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    async manejoOC(e: any) {
        let archivos = this.state.adjuntos;

        if (archivos) {
            archivos.push(e[0]);
            let graphEndPoint = fetchEngine.graphEndPoint;
            let tenant = fetchEngine.tenant;
            let pathToSite = fetchEngine.pathToSite;

            var headers = new Headers();
            headers.append("Content-Type", "application/json");
            headers.append("Authorization", "Bearer " + this.state.tokenAPIGraphSites);
            axios
                .get(`${graphEndPoint}/sites/${tenant}.sharepoint.com:${pathToSite}:/drives`, {
                    headers: headers,
                })
                .then((r) => {
                    console.log(r);
                });
            this.setState({ adjuntos: archivos });
        }
    }

    public seccionPropuesta() {
        return (
            <div>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                            <label className="labelResponsable">
                                Colaboradores
                                <span className="obligatorio">*</span>
                            </label>

                            <div className="mt10">
                                <AutoComplete
                                    disabled={this.state.disabledInicial!}
                                    key={"colaboradores"}
                                    id="colaboradores"
                                    callback={this.userHandler}
                                    multi={true}
                                    defaultValue={this.state.op_info.COLABORADORES.map((u: any) => {
                                        return {
                                            name: u.DISPLAYNAME,
                                            mail: u.MAIL,
                                        };
                                    })}
                                    tokenAPIGraph={this.state.tokenAPIGraph}
                                />
                            </div>
                        </div>

                        <div className="ms-Grid-col ms-sm12 ms-md3 ms-lg3">
                            <ComboBox
                                label="Competidores"
                                disabled={this.state.disabledInicial}
                                autoComplete="on"
                                placeholder="Seleccionar..."
                                multiSelect={true}
                                selectedKey={this.state.op_info.COMPRADORES}
                                onChange={this._onChangeCompetidores}
                                options={this.state.opciones_competidores}></ComboBox>
                        </div>

                        <div className="ms-Grid-col ms-sm12 ms-md3 ms-lg3">
                            <ComboBox
                                label="Contrato Marco"
                                required={true}
                                allowFreeform={true}
                                disabled={this.state.disabledInicial}
                                autoComplete="on"
                                placeholder="Seleccionar..."
                                selectedKey={this.state.op_info.ID_CONTRATO_MARCO}
                                onChange={this._onChangeContrato}
                                options={this.state.opciones_contrato_marco}></ComboBox>
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                            <label>Adjuntar propuesta / Documentos</label>
                            <div>
                                {this.state.spRelativeSite != "" ? (
                                    <DragNDropFile
                                        disabled={this.state.disabledInicial!}
                                        id={this.state.op_info.ID_OPORTUNIDAD!}
                                        spTenant={this.state.spTenant}
                                        librarySP_EN={this.state.library}
                                        librarySpanish={"Documentos"}
                                        carpeta={"Propuestas"}
                                        spUrl={this.state.spRelativeSite}
                                        emailUsuario="propuesta"
                                        esAdminProp={this.state.esAdmin !== undefined ? this.state.esAdmin : false}
                                        esGestionProp={this.state.esGestion !== undefined ? this.state.esGestion : false}
                                        esUsuarioProp={this.state.esUsuario !== undefined ? this.state.esUsuario : false}
                                        esValidadorProp={this.state.esValidador !== undefined ? this.state.esValidador : false}
                                        esContableProp={this.state.esContable !== undefined ? this.state.esContable : false}
                                    />
                                ) : (
                                    "Debe crear el workspace para adjuntar documentos"
                                )}
                            </div>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg2">
                            <DatePicker
                                id="fechafin"
                                className={controlClass.control}
                                firstDayOfWeek={DayOfWeek.Monday}
                                strings={DayPickerStrings}
                                placeholder="Fecha estimada cierre"
                                label="Fecha estimada cierre"
                                ariaLabel="Seleccione una fecha"
                                formatDate={this._onFormatDate}
                                value={this.state.op_info.FECHA_ESTIMADA_FIN}
                                disabled={this.state.disabledInicial}
                                onSelectDate={this._onSelectDateEstimadaFin}
                            />
                        </div>

                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg2">
                            <DatePicker
                                id="fechaenviopropuesta"
                                className={controlClass.control}
                                firstDayOfWeek={DayOfWeek.Monday}
                                strings={DayPickerStrings}
                                placeholder="Fecha envío propuesta"
                                label="Fecha envío propuesta"
                                isRequired={true}
                                ariaLabel="Seleccione una fecha"
                                formatDate={this._onFormatDate}
                                value={this.state.op_info.FECHA_ENVIO_PROPUESTA}
                                disabled={this.state.disabledInicial}
                                onSelectDate={this._onSelectDateEnvioPropuesta}
                            />
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6"></div>
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                            <TextField label="Moneda" value={this.state.moneda} disabled={true}></TextField>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                            <div className="checkbox">
                                <Checkbox
                                    id="licitacion"
                                    label="Licitación"
                                    disabled={this.state.disabledInicial}
                                    checked={this.state.op_info.LICITACION}
                                    onChange={this._onCheckChange}></Checkbox>
                                <Checkbox
                                    id="acreditacion"
                                    label="Requiere acreditación"
                                    disabled={this.state.disabledInicial}
                                    checked={this.state.op_info.REQUIERE_ACREDITACION}
                                    onChange={this._onCheckChange}></Checkbox>
                            </div>
                        </div>
                    </div>

                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm6 ms-md3 ms-lg3">
                            <TextField
                                id="ingresoventa"
                                label="Ingreso Venta"
                                value={this.formatNumber(this.state.op_info.INGRESO_VENTA.toString())}
                                disabled={true}
                                onChange={(e) => {
                                    this.textFieldOnChange(e);
                                }}></TextField>
                        </div>
                        <div className="ms-Grid-col ms-sm6 ms-md3 ms-lg3">
                            <TextField
                                id="costoventa"
                                label="Costo Venta"
                                value={this.formatNumber(this.state.op_info.COSTO_VENTA.toString())}
                                disabled={true}
                                onChange={(e) => {
                                    this.textFieldOnChange(e);
                                }}></TextField>
                        </div>
                        <div className="ms-Grid-col ms-sm6 ms-md3 ms-lg3">
                            <TextField
                                id="resultado"
                                label="Resultado"
                                value={this.formatNumber(this.state.op_info.RESULTADO.toString())}
                                disabled={true}
                                onChange={(e) => {
                                    this.textFieldOnChange(e);
                                }}></TextField>
                        </div>
                        <div className="ms-Grid-col ms-sm6 ms-md3 ms-lg3">
                            <TextField
                                id="rentabilidad"
                                label="Rentabilidad (%)"
                                value={this.formatNumber(this.state.op_info.RENTABILIDAD.toString().replace(".", ","))}
                                disabled={true}
                                onChange={(e) => {
                                    this.textFieldOnChange(e);
                                }}></TextField>
                        </div>
                        <br />
                        <p>Estos datos son extraídos desde el Resumen Economic de la última simulación aprobada.</p>
                    </div>
                </div>
            </div>
        );
    }

    public seccionResultadosLicitacion() {
        return (
            <div>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                            {this.state.spRelativeSite != "" ? (
                                <OrdenCompra
                                    disabled={this.state.disabledInicial!}
                                    opoID={this.state.op_info.ID_OPORTUNIDAD!}
                                    relativeUrl={this.state.spRelativeSite}
                                    tenantName={this.state.spTenant}
                                    library={"Documentos"}
                                    carpeta={"OC"}
                                />
                            ) : (
                                "Debe crear el workspace para adjuntar documentos"
                            )}
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                            <div></div>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg3">
                            <label className="labelResponsable">
                                Responsable del proyecto <span className="obligatorio">*</span>
                            </label>
                            <div className="mt10">
                                <AutoComplete
                                    disabled={this.state.disabledInicial!}
                                    id="responsable"
                                    callback={this.userHandler}
                                    multi={false}
                                    defaultValue={{
                                        name: this.state.op_info.RESPONSABLE_PROYECTO_DISPLAY,
                                        mail: this.state.op_info.RESPONSABLE_PROYECTO_LOGIN,
                                    }}
                                    tokenAPIGraph={this.state.tokenAPIGraph}
                                />
                            </div>
                        </div>

                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg9">
                            <div></div>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg3 mt-2">
                            <DatePicker
                                id="fechainicio"
                                className={controlClass.control}
                                firstDayOfWeek={DayOfWeek.Monday}
                                strings={DayPickerStrings}
                                placeholder="Fecha estimada inicio"
                                label="Fecha estimada inicio"
                                isRequired={true}
                                ariaLabel="Seleccione una fecha"
                                formatDate={this._onFormatDate}
                                value={this.state.op_info.FECHA_INICIO}
                                disabled={this.state.disabledInicial}
                                onSelectDate={this._onSelectDateInicio}
                            />
                        </div>

                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                            <div></div>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg3"></div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg3">
                            <DatePicker
                                id="fechafin"
                                className={controlClass.control}
                                firstDayOfWeek={DayOfWeek.Monday}
                                strings={DayPickerStrings}
                                placeholder="Fecha estimada fin"
                                label="Fecha estimada fin"
                                isRequired={true}
                                ariaLabel="Seleccione una fecha"
                                formatDate={this._onFormatDate}
                                value={this.state.op_info.FECHA_TERMINO}
                                disabled={this.state.disabledInicial}
                                onSelectDate={this._onSelectDateFin}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    callbackWorkspace(props: any) {
        let worskspace = props[0];
        this.setState({ spRelativeSite: worskspace.URL_RELATIVA });
    }

    public seccionLeccionesOportunidad() {
        return (
            <div>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg9">
                            <TextField
                                label="Lección aprendida de la oportunidad (perdida o ganada)"
                                value={this.state.op_info.LECCION_APRENDIDA}
                                id={"leccionaprendida"}
                                multiline
                                autoAdjustHeight
                                disabled={this.state.disabledInicial}
                                onChange={(e) => {
                                    this.textFieldOnChange(e);
                                    this.contar(e);
                                }}></TextField>
                            <label>Quedan {this.state.contadorLeccion} carácteres.</label>
                        </div>

                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg3">
                            <ComboBox
                                label="Motivos"
                                disabled={this.state.disabledInicial}
                                autoComplete="on"
                                placeholder="Seleccionar..."
                                multiSelect={true}
                                required={true}
                                onChange={this._onChangeMotivos}
                                options={this.state.opciones_motivos}></ComboBox>
                        </div>

                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg9">
                            <TextField
                                label="Comentarios (opcional)"
                                value={this.state.op_info.COMENTARIOS}
                                id={"comentarios"}
                                multiline
                                autoAdjustHeight
                                disabled={this.state.disabledInicial}
                                onChange={(e) => {
                                    this.textFieldOnChange(e);
                                    this.contar(e);
                                }}></TextField>
                            <label>Quedan {this.state.contadorComentarios} carácteres.</label>
                        </div>
                    </div>
                    <br />
                </div>
            </div>
        );
    }

    public seccionAdminProyecto() {
        return (
            <div>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                            <label className="labelResponsable">
                                Jefe de proyecto
                                <span className="obligatorio">*</span>
                            </label>
                            <div className="mt10">
                                <AutoComplete
                                    disabled={this.state.disabledInicial!}
                                    id="jefeproyecto"
                                    callback={this.userHandler}
                                    multi={false}
                                    defaultValue={{
                                        name: this.state.op_info.JEFE_PROYECTO_DISPLAY,
                                        mail: this.state.op_info.JEFE_PROYECTO_LOGIN,
                                    }}
                                    tokenAPIGraph={this.state.tokenAPIGraph}
                                />
                            </div>
                        </div>

                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                            <label className="labelResponsable">
                                Responsable aprueba gastos
                                <span className="obligatorio">*</span>
                            </label>
                            <div className="mt10">
                                <AutoComplete
                                    disabled={this.state.disabledInicial!}
                                    id="respapruebagastos"
                                    callback={this.userHandler}
                                    multi={false}
                                    defaultValue={{
                                        name: this.state.op_info.RESPONSABLE_GASTOS_DISPLAY,
                                        mail: this.state.op_info.RESPONSABLE_GASTOS_LOGIN,
                                    }}
                                    tokenAPIGraph={this.state.tokenAPIGraph}
                                />
                            </div>
                        </div>

                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                            <label className="labelResponsable">
                                Responsable aprueba horas
                                <span className="obligatorio">*</span>
                            </label>
                            <div className="mt10">
                                <AutoComplete
                                    disabled={this.state.disabledInicial!}
                                    id="respapruebahoras"
                                    callback={this.userHandler}
                                    multi={false}
                                    defaultValue={{
                                        name: this.state.op_info.RESPONSABLE_HORAS_DISPLAY,
                                        mail: this.state.op_info.RESPONSABLE_HORAS_LOGIN,
                                    }}
                                    tokenAPIGraph={this.state.tokenAPIGraph}
                                />
                            </div>
                        </div>

                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                            <label className="labelResponsable">Asistente proyecto</label>
                            <div className="mt10">
                                <AutoComplete
                                    disabled={this.state.disabledInicial!}
                                    id="asistente"
                                    callback={this.userHandler}
                                    multi={false}
                                    defaultValue={{
                                        name: this.state.op_info.ASISTENTE_DISPLAY,
                                        mail: this.state.op_info.ASISTENTE_LOGIN,
                                    }}
                                    tokenAPIGraph={this.state.tokenAPIGraph}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    public seccionCualificaciones() {
        return (
            <div>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm0 ms-md6 ms-lg11"></div>
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg1"></div>
                    </div>

                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                            <TextField
                                disabled={this.state.disabledInicial}
                                label="Oportunidad del negocio"
                                required={true}
                                value={this.state.op_info.OPORTUNIDAD_NEGOCIO}
                                id={"oportunidadnegocio"}
                                multiline
                                autoAdjustHeight
                                onChange={(e) => {
                                    this.textFieldOnChange(e);
                                    this.contar(e);
                                }}></TextField>
                            <label>Quedan {this.state.contadorOportunidadNegocio} carácteres.</label>
                        </div>

                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                            <TextField
                                disabled={this.state.disabledInicial}
                                label="Objetivo del proyecto"
                                required={true}
                                value={this.state.op_info.OBJETIVO_PROYECTO}
                                id={"objetivoproyecto"}
                                multiline
                                autoAdjustHeight
                                onChange={(e) => {
                                    this.textFieldOnChange(e);
                                    this.contar(e);
                                }}></TextField>
                            <label>Quedan {this.state.contadorObjetivo} carácteres.</label>
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                            <TextField
                                disabled={this.state.disabledInicial}
                                label="Entregables"
                                required={true}
                                value={this.state.op_info.ENTREGABLES}
                                id={"entregables"}
                                multiline
                                autoAdjustHeight
                                onChange={(e) => {
                                    this.textFieldOnChange(e);
                                    this.contar(e);
                                }}></TextField>
                            <label>Quedan {this.state.contadorEntregables} carácteres.</label>
                        </div>

                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                            <TextField
                                disabled={this.state.disabledInicial}
                                label="Alcance"
                                value={this.state.op_info.ALCANCE}
                                id={"alcance"}
                                multiline
                                autoAdjustHeight
                                onChange={(e) => {
                                    this.textFieldOnChange(e);
                                    this.contar(e);
                                }}></TextField>
                            <label>Quedan {this.state.contadorAlcance} carácteres.</label>
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                            <TextField
                                disabled={this.state.disabledInicial}
                                label="Solución propuesta"
                                value={this.state.op_info.SOLUCION}
                                id={"solucion"}
                                multiline
                                autoAdjustHeight
                                onChange={(e) => {
                                    this.textFieldOnChange(e);
                                    this.contar(e);
                                }}></TextField>
                            <label>Quedan {this.state.contadorSolucion} carácteres.</label>
                        </div>

                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                            <TextField
                                disabled={this.state.disabledInicial}
                                label="Beneficios obtenidos"
                                value={this.state.op_info.BENEFICIOS}
                                id={"beneficios"}
                                multiline
                                autoAdjustHeight
                                onChange={(e) => {
                                    this.textFieldOnChange(e);
                                    this.contar(e);
                                }}></TextField>
                            <label>Quedan {this.state.contadorBeneficios} carácteres.</label>
                        </div>
                    </div>
                    <br />
                </div>
            </div>
        );
    }

    render() {
        if (this.state.esAdmin || this.state.esGestion || this.state.esValidador) {
            return (
                <React.Fragment>
                    <div className="fuente">
                        <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm6 ms-md5 ms-lg5">
                                    <div className="iconoRuta">
                                        <Glyphicon glyph="glyphicon glyphicon-play" /> <span id="txtRuta">Inicio</span>
                                        &nbsp;
                                        <Glyphicon glyph="glyphicon glyphicon-play" /> <span id="txtRuta">Gestión de Proyecto</span>
                                    </div>
                                    <div className="titulosimulador">
                                        <Glyphicon glyph="ok" /> Gestión de Proyecto
                                    </div>
                                </div>
                                <div className="ms-Grid-col ms-sm6 ms-md7 ms-lg7 d-flex justify-content-end">
                                    <div className="fijado">
                                        <DefaultButton
                                            text="Salir"
                                            onClick={() => {
                                                this.setState({
                                                    dialogoSalir: false,
                                                });
                                            }}></DefaultButton>
                                        <PrimaryButton text="Guardar" disabled={this.state.disabledInicial} onClick={this.guardarOP}></PrimaryButton>
                                        <PrimaryButton
                                            text="Plan"
                                            onClick={() => {
                                                this.setState({
                                                    planificacion: true,
                                                });
                                                this.validarCamposPlan();
                                            }}></PrimaryButton>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="pagina">
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                        {this.state.opoID && this.state.opoID > 0 ? (
                                            <Workspace
                                                callback={this.callbackWorkspace}
                                                nombre={this.state.op_info.NOMBRE ? this.state.op_info.NOMBRE : ""}
                                                id={this.state.op_info.ID_OPORTUNIDAD!}
                                                colaboradores={[
                                                    ...this.state.op_info.COLABORADORES,
                                                    {
                                                        ID: 0,
                                                        MAIL: this.state.op_info.RESPONSABLE_LOGIN,
                                                        DISPLAYNAME: this.state.op_info.RESPONSABLE_DISPLAY,
                                                        ACTIVO: true,
                                                    },
                                                    {
                                                        ID: 0,
                                                        MAIL: this.state.op_info.RESPONSABLE_PROYECTO_LOGIN,
                                                        DISPLAYNAME: this.state.op_info.RESPONSABLE_PROYECTO_DISPLAY,
                                                        ACTIVO: true,
                                                    },
                                                ]}
                                            />
                                        ) : (
                                            ""
                                        )}
                                    </div>

                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg2">
                                        {this.state.opoID === 0 ? (
                                            <ComboBox
                                                label="Proyectos"
                                                allowFreeform={true}
                                                disabled={false}
                                                autoComplete="on"
                                                placeholder="Seleccionar..."
                                                selectedKey={this.state.id_proyecto}
                                                onChange={this._onChangeProyecto}
                                                options={this.state.opciones_proyectos}></ComboBox>
                                        ) : (
                                            <TextField
                                                label="N° Oportunidad"
                                                value={this.state.numero_extension}
                                                id={"oportunidad"}
                                                disabled={true}></TextField>
                                        )}
                                    </div>
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg2">
                                        <TextField
                                            label="N° Proyecto"
                                            value={this.state.id_proyecto != null ? this.state.id_proyecto.toString() : ""}
                                            id={"oportunidad"}
                                            disabled={true}></TextField>
                                    </div>

                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg2">
                                        <ComboBox
                                            label="Estado"
                                            allowFreeform={true}
                                            disabled={true}
                                            autoComplete="on"
                                            placeholder="Seleccionar..."
                                            selectedKey={this.state.op_info.ID_ESTADO}
                                            onChange={this._onChangeEstadoProy}
                                            options={this.state.opciones_estado_proy}></ComboBox>
                                    </div>
                                </div>
                            </div>

                            <div className="acordeon">
                                <Accordion
                                    title="Datos Generales de la Oportunidad"
                                    defaultCollapsed={this.state.collapsed}
                                    inicio={true}
                                    expandAll={this.state.collapsed}
                                    className="fondoTitAcordion"
                                    key={1}
                                    classFondo="fondoSeccion">
                                    <div className="itemContent">{this.seccionDatosGenerales()}</div>
                                </Accordion>
                            </div>

                            <div className="acordeon">
                                <Accordion
                                    title="Datos del Cliente"
                                    defaultCollapsed={this.state.collapsed}
                                    inicio={true}
                                    expandAll={this.state.collapsed}
                                    className="fondoTitAcordion"
                                    key={1}
                                    classFondo="fondoSeccion">
                                    <div className="itemContent">{this.seccionDatosCliente()}</div>
                                </Accordion>
                            </div>

                            <div className="acordeon">
                                <Accordion
                                    title="Propuesta"
                                    defaultCollapsed={this.state.collapsed}
                                    inicio={true}
                                    expandAll={this.state.collapsed}
                                    className="fondoTitAcordion"
                                    key={1}
                                    classFondo="fondoSeccion">
                                    <div className="itemContent">{this.seccionPropuesta()}</div>
                                </Accordion>
                            </div>

                            <div className="acordeon">
                                <Accordion
                                    title="Resultados de la Licitación / Cotización"
                                    defaultCollapsed={this.state.collapsed}
                                    inicio={true}
                                    expandAll={this.state.collapsed}
                                    className="fondoTitAcordion"
                                    key={1}
                                    classFondo="fondoSeccion">
                                    <div className="itemContent">{this.seccionResultadosLicitacion()}</div>
                                </Accordion>
                            </div>

                            <div className="acordeon">
                                <Accordion
                                    title="Lecciones de la Oportunidad"
                                    defaultCollapsed={this.state.collapsed}
                                    inicio={true}
                                    expandAll={this.state.collapsed}
                                    className="fondoTitAcordion"
                                    key={1}
                                    classFondo="fondoSeccion">
                                    <div className="itemContent">{this.seccionLeccionesOportunidad()}</div>
                                </Accordion>
                            </div>
                            <div>
                                <div className="acordeon">
                                    <Accordion
                                        title="Administradores del proyecto"
                                        defaultCollapsed={this.state.collapsed}
                                        inicio={true}
                                        expandAll={this.state.collapsed}
                                        className="fondoTitAcordion"
                                        key={1}
                                        classFondo="fondoSeccion">
                                        <div className="itemContent">{this.seccionAdminProyecto()}</div>
                                    </Accordion>
                                </div>

                                <div className="acordeon">
                                    <Accordion
                                        title="Quals"
                                        defaultCollapsed={this.state.collapsed}
                                        inicio={true}
                                        expandAll={this.state.collapsed}
                                        className="fondoTitAcordion"
                                        key={1}
                                        classFondo="fondoSeccion">
                                        <div className="itemContent">{this.seccionCualificaciones()}</div>
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                        {this.dialogoCopiar()}
                        {this.dialogoSalir()}
                        {this.dialogoGuardar()}
                        {this.dialogoGuardar2()}
                        {this.dialogoValidaciones()}
                        {this.dialogoEstInicioMayor()}
                        {this.dialogoEstFinMenor()}
                        {this.dialogoCopiado()}
                        {this.dialogoExtendida()}
                    </div>
                </React.Fragment>
            );
        } else if (this.state.esUsuario) {
            return (
                <React.Fragment>
                    <div className="mt-4 card sinAcceso text-primary">
                        <div className="card-header">Atención</div>
                        <div className="card-body">
                            <h6>
                                <Glyphicon glyph="info-sign" className="mr-1" /> Usted no posee los permisos para visualizar este formulario, si desea
                                obtener los permisos, contactese con el administrador del sistema.
                            </h6>
                        </div>
                    </div>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <div className="mt-4 card sinAcceso text-primary"></div>
                </React.Fragment>
            );
        }
    }

    //DIALOGOS

    public dialogoCopiar() {
        return (
            <Dialog
                hidden={this.state.dialogoCopiar}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                }}
                minWidth={300}
                maxWidth={500}
                modalProps={{
                    isBlocking: true,
                }}>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="tituloCopia">
                            <h2>Copiar Oportunidad</h2>
                        </div>
                        <div className="copiaoportunidad">
                            <ChoiceGroup
                                id="tipoPersona"
                                disabled={false}
                                className="defaultChoiceGroup"
                                defaultSelectedKey={"0"}
                                options={[
                                    { key: "1", text: "Nueva oportunidad" },
                                    {
                                        key: "2",
                                        text: "Extensión de oportunidad",
                                    },
                                ]}
                                onChange={(e, i) => this.seleccionCopia(e, i)}
                                required={false}
                            />
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12"></div>
                    </div>
                </div>
                <DialogFooter>
                    <DefaultButton
                        text="Cerrar"
                        onClick={() => {
                            this.setState({ dialogoCopiar: true });
                        }}>
                        {" "}
                    </DefaultButton>
                    {this.state.opciones_copia === 1 ? (
                        <PrimaryButton
                            text="Copiar"
                            id="aceptarbtn"
                            onClick={(e) => {
                                this.setState({ dialogoCopiar: true });
                                this.copiarOportunidad(this.state.op_info.ID!);
                            }}></PrimaryButton>
                    ) : this.state.opciones_copia === 2 ? (
                        <PrimaryButton
                            text="Extender"
                            id="aceptarbtn"
                            onClick={(e) => {
                                this.setState({ dialogoCopiar: true });
                                this.extenderOportunidad(this.state.op_info.ID!);
                            }}></PrimaryButton>
                    ) : (
                        ""
                    )}
                </DialogFooter>
            </Dialog>
        );
    }

    public dialogoValidaciones() {
        let validacionMotivo: any = [];
        this.state.op_info.MOTIVOS.map((a: any) => {
            if (a.selected === true) {
                validacionMotivo.push({
                    key: a.ID_ELEMENTO,
                });
            }
        });
        return (
            <Dialog
                hidden={this.state.dialogoValidar}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                }}
                minWidth={300}
                maxWidth={1000}
                modalProps={{
                    isBlocking: true,
                }}>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="tituloCopia">
                            <h2>Datos obligatorios</h2>
                        </div>
                        {this.state.op_info.NOMBRE === undefined || this.state.op_info.NOMBRE === "" || this.state.op_info.NOMBRE === null ? (
                            <div className="textosValidar">
                                <label>Debe ingresar un Nombre de Oportunidad</label>
                            </div>
                        ) : (
                            ""
                        )}
                        {this.state.op_info.ID_UNIDAD === undefined || this.state.op_info.ID_UNIDAD === 0 || this.state.op_info.ID_UNIDAD === null ? (
                            <div className="textosValidar">
                                <label>Debe seleccionar Unidad de Negocio</label>
                            </div>
                        ) : (
                            ""
                        )}
                        {this.state.op_info.RESPONSABLE_PROYECTO_DISPLAY === undefined ||
                        this.state.op_info.RESPONSABLE_PROYECTO_DISPLAY === "" ||
                        this.state.op_info.RESPONSABLE_PROYECTO_DISPLAY === null ? (
                            <div className="textosValidar">
                                <label>Debe seleccionar Responsable del proyecto</label>
                            </div>
                        ) : (
                            ""
                        )}
                        {this.state.op_info.DESCRIPCION === undefined ||
                        this.state.op_info.DESCRIPCION === "" ||
                        this.state.op_info.DESCRIPCION === null ? (
                            <div className="textosValidar">
                                <label>Debe ingresar una Descripción</label>
                            </div>
                        ) : (
                            ""
                        )}
                        {this.state.op_info.FECHA_APERTURA === undefined || this.state.op_info.FECHA_APERTURA === null ? (
                            <div className="textosValidar">
                                <label>Debe seleccionar una Fecha de Cierre</label>
                            </div>
                        ) : (
                            ""
                        )}
                        {this.state.op_info.ID_LINEA === undefined || this.state.op_info.ID_LINEA === 0 || this.state.op_info.ID_LINEA === null ? (
                            <div className="textosValidar">
                                <label>Debe seleccionar el Área de negocio</label>
                            </div>
                        ) : (
                            ""
                        )}
                        {this.state.op_info.ID_SUBLINEA === undefined ||
                        this.state.op_info.ID_SUBLINEA === 0 ||
                        this.state.op_info.ID_SUBLINEA === null ? (
                            <div className="textosValidar">
                                <label>Debe seleccionar Línea</label>
                            </div>
                        ) : (
                            ""
                        )}
                        {this.state.op_info.ID_CONTRATO_MARCO === undefined ||
                        this.state.op_info.ID_CONTRATO_MARCO === 0 ||
                        this.state.op_info.ID_CONTRATO_MARCO === null ? (
                            <div className="textosValidar">
                                <label>Debe seleccionar Contrato Marco</label>
                            </div>
                        ) : (
                            ""
                        )}
                        {this.state.op_info.FECHA_INICIO === undefined || this.state.op_info.FECHA_INICIO === null ? (
                            <div className="textosValidar">
                                <label>Debe seleccionar una Fecha de Inicio</label>
                            </div>
                        ) : (
                            ""
                        )}
                        {this.state.op_info.FECHA_TERMINO === undefined || this.state.op_info.FECHA_TERMINO === null ? (
                            <div className="textosValidar">
                                <label>Debe seleccionar una Fecha de Término</label>
                            </div>
                        ) : (
                            ""
                        )}
                        {this.state.op_info.PROBABILIDAD === undefined ||
                        this.state.op_info.PROBABILIDAD === 0 ||
                        this.state.op_info.PROBABILIDAD === null ? (
                            <div className="textosValidar">
                                <label>Debe seleccionar la Probabilidad</label>
                            </div>
                        ) : (
                            ""
                        )}
                        {/* PRINCIPALES ELEMENTOS DEL SERVICIO */}
                        {this.state.op_info.ID_CLIENTE === undefined ||
                        this.state.op_info.ID_CLIENTE === 0 ||
                        this.state.op_info.ID_CLIENTE === null ? (
                            <div className="textosValidar">
                                <label>Debe seleccionar un Cliente</label>
                            </div>
                        ) : (
                            ""
                        )}
                        {this.state.op_info.ID_PAIS === undefined || 
                        this.state.op_info.ID_PAIS === 0 || 
                        this.state.op_info.ID_PAIS === null ? (
                            <div className="textosValidar">
                                <label>Debe seleccionar un País</label>
                            </div>
                        ) : (
                            ""
                        )}
                        {this.state.op_info.ID_OFICINA === undefined ||
                        this.state.op_info.ID_OFICINA === 0 ||
                        this.state.op_info.ID_OFICINA === null ? (
                            <div className="textosValidar">
                                <label>Debe seleccionar Oficina</label>
                            </div>
                        ) : (
                            ""
                        )}
                        {this.state.op_info.AREA_CLIENTE === undefined ||
                        this.state.op_info.AREA_CLIENTE.length === 0 ||
                        this.state.op_info.AREA_CLIENTE === null ? (
                            <div className="textosValidar">
                                <label>Debe ingresar un Área de cliente</label>
                            </div>
                        ) : (
                            ""
                        )}
                        {this.state.op_info.COMPRADORES === undefined ||
                        this.state.op_info.COMPRADORES.length === 0 ||
                        this.state.op_info.COMPRADORES === null ? (
                            <div className="textosValidar">
                                <label>Debe ingresar un Comprador</label>
                            </div>
                        ) : (
                            ""
                        )}
                        {this.state.op_info.NOMBRE_CONTACTO === undefined ||
                        this.state.op_info.NOMBRE_CONTACTO.length === 0 ||
                        this.state.op_info.NOMBRE_CONTACTO === null ? (
                            <div className="textosValidar">
                                <label>Debe ingresar un Nombre de Contacto</label>
                            </div>
                        ) : (
                            ""
                        )}
                        {this.state.op_info.MAIL === undefined || 
                        this.state.op_info.MAIL.length === 0 || 
                        this.state.op_info.MAIL === null ? (
                            <div className="textosValidar">
                                <label>Debe ingresar un Mail</label>
                            </div>
                        ) : (
                            ""
                        )}
                        {this.state.op_info.TELEFONO === undefined ||
                        this.state.op_info.TELEFONO.length === 0 ||
                        this.state.op_info.TELEFONO === null ? (
                            <div className="textosValidar">
                                <label>Debe ingresar un Teléfono</label>
                            </div>
                        ) : (
                            ""
                        )}
                        {this.state.op_info.COLABORADORES.length === 0 || this.state.op_info.COLABORADORES === null ? (
                            <div className="textosValidar">
                                <label>Debe ingresar al menos un colaborador</label>
                            </div>
                        ) : (
                            ""
                        )}
                        {(this.state.op_info.MAIL != undefined || this.state.op_info.MAIL != "") && this.state.validarCorreo === false ? (
                            <div className="textosValidar">
                                <label>Formato de mail incorrecto</label>
                            </div>
                        ) : (
                            ""
                        )}

                        {this.state.op_info.FECHA_ESTIMADA_INICIO === undefined || this.state.op_info.FECHA_ESTIMADA_INICIO === null ? (
                            <div className="textosValidar">
                                <label>Debe seleccionar una Fecha Estimada de Inicio</label>
                            </div>
                        ) : (
                            ""
                        )}
                        {this.state.op_info.FECHA_ESTIMADA_FIN === undefined || this.state.op_info.FECHA_ESTIMADA_FIN === null ? (
                            <div className="textosValidar">
                                <label>Debe seleccionar una Fecha Estimada de Fin</label>
                            </div>
                        ) : (
                            ""
                        )}
                        {this.state.op_info.FECHA_ENVIO_PROPUESTA === undefined || this.state.op_info.FECHA_ENVIO_PROPUESTA === null ? (
                            <div className="textosValidar">
                                <label>Debe seleccionar una Fecha de Envío de Propuesta</label>
                            </div>
                        ) : (
                            ""
                        )}
                        {validacionMotivo.length === 0 ? (
                            <div className="textosValidar">
                                <label>Debe seleccionar al menos un Motivo</label>
                            </div>
                        ) : (
                            ""
                        )}
                        {this.state.op_info.JEFE_PROYECTO_DISPLAY === undefined ||
                        this.state.op_info.JEFE_PROYECTO_DISPLAY === "" ||
                        this.state.op_info.JEFE_PROYECTO_DISPLAY === null ? (
                            <div className="textosValidar">
                                <label>Debe seleccionar Jefe de Proyecto</label>
                            </div>
                        ) : (
                            ""
                        )}
                        {this.state.op_info.RESPONSABLE_GASTOS_DISPLAY === undefined ||
                        this.state.op_info.RESPONSABLE_GASTOS_DISPLAY === "" ||
                        this.state.op_info.RESPONSABLE_GASTOS_DISPLAY === null ? (
                            <div className="textosValidar">
                                <label>Debe seleccionar Responsable aprueba gastos</label>
                            </div>
                        ) : (
                            ""
                        )}
                        {this.state.op_info.RESPONSABLE_HORAS_DISPLAY === undefined ||
                        this.state.op_info.RESPONSABLE_HORAS_DISPLAY === "" ||
                        this.state.op_info.RESPONSABLE_HORAS_DISPLAY === null ? (
                            <div className="textosValidar">
                                <label>Debe seleccionar Responsable aprueba horas</label>
                            </div>
                        ) : (
                            ""
                        )}
                        {this.state.op_info.OPORTUNIDAD_NEGOCIO === undefined ||
                        this.state.op_info.OPORTUNIDAD_NEGOCIO === "" ||
                        this.state.op_info.OPORTUNIDAD_NEGOCIO === null ? (
                            <div className="textosValidar">
                                <label>Debe ingresar una Oportunidad del negocio</label>
                            </div>
                        ) : (
                            ""
                        )}
                        {this.state.op_info.OBJETIVO_PROYECTO === undefined ||
                        this.state.op_info.OBJETIVO_PROYECTO === "" ||
                        this.state.op_info.OBJETIVO_PROYECTO === null ? (
                            <div className="textosValidar">
                                <label>Debe ingresar un Objetivo del Proyecto</label>
                            </div>
                        ) : (
                            ""
                        )}
                        {this.state.op_info.ENTREGABLES === undefined ||
                        this.state.op_info.ENTREGABLES === "" ||
                        this.state.op_info.ENTREGABLES === null ? (
                            <div className="textosValidar">
                                <label>Debe ingresar un Entregable</label>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
                <DialogFooter>
                    <PrimaryButton
                        text="Cerrar"
                        onClick={() => {
                            this.setState({
                                dialogoValidar: true,
                                dialogoValidarSim: true,
                                dialogoValidarGanada: true,
                                dialogoValidarPreparando: true,
                                dialogoValidarPropuesta: true,
                            });
                        }}>
                        {" "}
                    </PrimaryButton>
                </DialogFooter>
            </Dialog>
        );
    }

    public dialogoSalir() {
        return (
            <Dialog
                hidden={this.state.dialogoSalir}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Salir",
                    subText: "¿Está seguro que desea salir? Los cambios efectuados no serán guardados",
                }}
                modalProps={{
                    styles: { main: { maxWidth: 450 } },
                }}>
                <DialogFooter>
                    <PrimaryButton
                        text="Salir"
                        onClick={() => {
                            this.setState({ dialogoSalir: true });
                            this.salirPT();
                        }}></PrimaryButton>
                    <PrimaryButton text="Guardar" onClick={this.guardarOP}></PrimaryButton>
                    <DefaultButton
                        text="Cancelar"
                        onClick={() => {
                            this.setState({ dialogoSalir: true });
                        }}></DefaultButton>
                </DialogFooter>
            </Dialog>
        );
    }

    public dialogoEstInicioMayor() {
        return (
            <Dialog
                hidden={this.state.estimada_inicio_mayor}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Fecha incorrecta",
                    subText: "La fecha de inicio no puede ser superior a la de término",
                }}
                modalProps={{
                    styles: { main: { maxWidth: 450 } },
                }}>
                <DialogFooter>
                    <PrimaryButton
                        text="Aceptar"
                        onClick={() => {
                            this.setState({ estimada_inicio_mayor: true });
                        }}></PrimaryButton>
                </DialogFooter>
            </Dialog>
        );
    }

    public dialogoEstFinMenor() {
        return (
            <Dialog
                hidden={this.state.estimada_fin_menor}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Fecha incorrecta",
                    subText: "La fecha de fin no puede ser inferior a la de inicio",
                }}
                modalProps={{
                    styles: { main: { maxWidth: 450 } },
                }}>
                <DialogFooter>
                    <PrimaryButton
                        text="Aceptar"
                        onClick={() => {
                            this.setState({ estimada_fin_menor: true });
                        }}></PrimaryButton>
                </DialogFooter>
            </Dialog>
        );
    }

    public dialogoCopiado() {
        return (
            <Dialog
                hidden={this.state.dialogo_copia}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Proyecto copiada con éxito",
                    subText: "¿Quiere dirigirse al nuevo Proyecto?",
                }}
                modalProps={{
                    styles: { main: { maxWidth: 450 } },
                }}>
                <DialogFooter>
                    <PrimaryButton
                        text="Si"
                        onClick={() => {
                            window.location.href = `/gestionproyecto/${this.state.id_copia}`;
                            this.setState({ dialogo_copia: true });
                        }}></PrimaryButton>
                    <DefaultButton
                        text="No"
                        onClick={() => {
                            this.setState({ dialogo_copia: true });
                        }}></DefaultButton>
                </DialogFooter>
            </Dialog>
        );
    }

    public dialogoExtendida() {
        return (
            <Dialog
                hidden={this.state.dialogo_extencion}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Proyecto extendido con éxito",
                    subText: "¿Quiere dirigirse a la extención creada?",
                }}
                modalProps={{
                    styles: { main: { maxWidth: 450 } },
                }}>
                <DialogFooter>
                    <PrimaryButton
                        text="Si"
                        onClick={() => {
                            window.location.href = `/gestionproyecto/${this.state.id_copia}`;
                            this.setState({ dialogo_extencion: true });
                        }}></PrimaryButton>
                    <DefaultButton
                        text="No"
                        onClick={() => {
                            this.setState({ dialogo_extencion: true });
                        }}></DefaultButton>
                </DialogFooter>
            </Dialog>
        );
    }

    public dialogoGuardar() {
        return (
            <Dialog
                hidden={this.state.dialogoGuardar}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Guardando",
                    subText: "Espere mientra se guardan los cambios..",
                }}
                modalProps={{
                    isBlocking: true,
                    styles: { main: { maxWidth: 450 } },
                }}></Dialog>
        );
    }

    public dialogoGuardar2() {
        return (
            <Dialog
                hidden={this.state.dialogoGuardar2}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Proyecto guardado",
                    subText: "Proyecto guardado con éxito",
                }}
                modalProps={{
                    styles: { main: { maxWidth: 450 } },
                }}>
                <DialogFooter>
                    <PrimaryButton
                        text="Cerrar"
                        onClick={() => {
                            this.setState({ dialogoGuardar2: true });
                        }}></PrimaryButton>
                </DialogFooter>
            </Dialog>
        );
    }

    public dialogoGuardarError() {
        return (
            <Dialog
                hidden={this.state.dialogoGuardarError}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Error al guardar",
                    subText: "Error al guardar Proyecto",
                }}
                modalProps={{
                    styles: { main: { maxWidth: 450 } },
                }}>
                <DialogFooter>
                    <PrimaryButton
                        text="Cerrar"
                        onClick={() => {
                            this.setState({ dialogoGuardarError: true });
                        }}></PrimaryButton>
                </DialogFooter>
            </Dialog>
        );
    }
}

export default GestionProyecto;
